import { ButtonV2, Dropdown, CustomIcon } from "contentoh-components-library";
import { isArrayEmpty, isObject } from "../../utils/validations";
import { Container } from "./styles";
import {
  faEllipsisV as IconMenu,
  faSave as IconSave,
} from "@fortawesome/free-solid-svg-icons";
import useScreenSizes from "../../hooks/useScreenSizes";

export default function OptionMenu(props) {
  const {
    menuItems /* [
      {
        label: string
        icon: FontAwesomeIcon
        disabled: boolean
        isLoading: boolean
        onClick: (event) => {}
      }
    ] */,
    saveButton /* {
      loading: boolean
      disabled: boolean
      onClick: (event) => {}
    } */,
    positionDropdownMenu, // (string) => RIGHT | LEFT
  } = props;
  const {isDesktop} = useScreenSizes();
  return (
    <Container>
      {/* boton guardar */}
      {isObject(saveButton) && saveButton.onClick && (
        <ButtonV2
          className={"button-save"}
          type={"pink"}
          size={11}
          borderType={"circle"}
          icon={IconSave}
          isLoading={saveButton.loading}
          disabled={saveButton.disabled}
          onClick={(event) => {
            event.stopPropagation();
            saveButton.onClick(event);
          }}
        />
      )}
      {/* menu de opciones */}
      {!isArrayEmpty(menuItems) && (
        <Dropdown
          items={menuItems}
          triggerType={!isDesktop ? "click" : "hover"}
          maxWidthDropdown={"400px"}
          maxHeightDropdown={"50vh"}
          className={"dropdownMenu"}
          classNameDropdown={"menuPopUp"}
          onClick={!isDesktop ? "menuPopUp" : undefined}
          positionDropdown={
            positionDropdownMenu === "RIGHT" ? "rightStart" : "leftStart"
          }
        >
          <CustomIcon
            className={"button-dropdownMenu"}
            type={"gray"}
            transparent
            size={14}
            icon={IconMenu}
          />
        </Dropdown>
      )}
    </Container>
  );
}
