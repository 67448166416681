import { ContainerLoader, Loader } from "./styles";

export const Loading = () => {
  return (
    <ContainerLoader>
      <Loader>
        <span className="first"></span>
        <span className="second"></span>
        <span className="third"></span>
      </Loader>
    </ContainerLoader>
  );
};
