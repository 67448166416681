import styled from "styled-components";

export const Container = styled.div`
  background-color: ${({ read }) => (read ? "#FFF" : "#F7F7F7")};
  display: flex;
  border-bottom: 1px solid #f0f0f0;
  padding: 10px;
  width: 300px;
  align-items: center;
  border-radius: 5px;

  &:hover {
    background-color: #f0f0f0;
  }

  img {
    width: 18px;
    height: 18px;

    & + * {
      margin-left: 15px;
    }
  }

  .creation-date {
    margin: 0;
    color: #b3b3b3;
    font-size: 10px;
  }

  .notification-info {
    .notification-title {
      font-size: 12px;
      color: #262626;
      font-family: Roboto;
      font-weight: 400;
      text-align: left;

      & + * {
        margin-top: 5px;
      }
    }
  }

  & + * {
    margin-top: 10px;
  }
`;
