import styled from "styled-components";

export const Container = styled.button`
  border: 1px solid #f0f0f0;
  border-radius: 30px;
  background-color: white;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 105px;
  max-width: 150px;
  height: 35px;
  font: normal normal bold 12px/14px sans-serif;
  letter-spacing: 0.24px;
  color: #707070;
  cursor: pointer;
  position: relative;
  p {
    margin: 0;
  }
  .upload-image-menu {
    height: 35px;
    width: 100%;
    border: 1px solid #f0f0f0;
    box-shadow: 0px 2px 4px #00000040;
    border-radius: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3px 10px;
    position: absolute;
    align-items: center;
    left: -1%;
    top: 40px;
    z-index: 1;
    &:hover {
      background: #f4f4f4;
    }
    p {
      margin: 5px 0px;
      text-align: start;
      font: normal normal 600 12px/16px sans-serif;
      letter-spacing: 0.24px;
      color: #3b1366;
      &:last-child {
        color: #f62424;
      }
    }
  }
  .container-imgProfile {
    width: 25px;
    height: 25px;
    margin-right: 5px;
    cursor: pointer;

    .icon-imgProfile {
      width: 100%;
      height: 100%;
      padding: 0px;
    }

    > * {
      cursor: inherit;
    }
  }
  @media (max-width: 1024px) { 
    font: normal normal bold 9px/12px sans-serif;
  }
`;
