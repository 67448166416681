import {
    faBars as IconBars,
  } from "@fortawesome/free-solid-svg-icons";
  import { ButtonV2 } from "contentoh-components-library/dist/components/atoms/ButtonV2";
export const MenuBar = (props) => {
    const { loadingButton, onClick } = props;
    return (
      <>
        {/* Botón de hamburguesa con ícono */}
        <ButtonV2
          key={"buttonHamburguer"}
          className={"hamburger-button"}
          size={18}
          type={"white"}
          icon={IconBars}
          transparent
          disabled={(loadingButton)}
          isLoading={loadingButton === "START"}
          borderType="rectangle"
          onClick={(event) => {
            onClick && onClick();
          }}
        />
      </>
    );
  };