import { Modal } from "contentoh-components-library";
import styled from "styled-components";
import logoContentoh from "../../assets/images/VerticalSideMenuMainPage/iconLogoContentoh.svg";

export const Container = styled(Modal)`
  &.modalSignIn {
    background-image: linear-gradient(
      45deg,
      #c70b83 0%,
      #1f004e 25%,
      #1f004e 75%,
      #c70b83 100%
    );
  }
  .contentModal {
    width: 330px;
    position: relative;

    header {
      margin: unset;

      .label-title {
        text-align: left;
      }
    }

    .container-customComponent {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      gap: 10px;

      input {
        ::placeholder {
          font: normal normal medium 12px/14px sans-serif;
          letter-spacing: 0.24px;
          color: #8a8a8a;
        }
        &:focus {
          outline: none;
        }
        width: 100%;
        height: 38px;
        margin: 0;
        padding-left: 32px;
        box-sizing: border-box;
        border: none;
        border-radius: 5px;
        font-family: sans-serif;
        font-size: 13px;
        line-height: normal;
      }

      .email {
        height: 40px;
        width: 100%;
        position: relative;
        border-radius: 5px;
        border: 1px solid #eaeaea;
        &:hover {
          border: 1px solid #e33aa9;
        }
        img {
          position: absolute;
          top: 40%;
          left: 4%;
          width: 12px;
        }
      }

      .password {
        height: 40px;
        width: 100%;
        border: 1px solid #eaeaea;
        border-radius: 5px;
        padding: 0px 8px 0px 0px;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;

        &:hover {
          border: 1px solid #e33aa9;
        }

        input {
          width: unset;
          flex-grow: 2;
          padding-right: 5px;
        }

        > .icon {
          position: absolute;
          top: 40%;
          left: 4%;
          height: 12px;
        }

        > .icon:hover {
          opacity: 0.8;
        }

        .buttonShowPass {
          opacity: 0.2;
          .button {
            padding: 0px;
            opacity: unset;
          }
        }
      }

      label {
        color: red;
        margin-left: 15px;
        font-family: Arial, Helvetica, sans-serif;
        letter-spacing: 0.75px;
        font-size: 11px;
      }

      p {
        font: normal normal 600 15px/24px sans-serif;
        letter-spacing: 0.75px;
        color: #4b4b4b;
      }

      a {
        margin-top: 20px;
        cursor: pointer;
        text-align: right;
        text-decoration: underline;
        font: normal normal 600 11px/12px sans-serif;
        letter-spacing: 0.2px;
        color: #3b1366;
        & + * {
          margin-top: 20px;
        }
      }
    }

    .container-buttons {
      justify-content: flex-end;
    }

    &:before {
      content: "";
      background-image: url(${logoContentoh});
      background-repeat: no-repeat;
      background-size: cover;
      width: 328px;
      height: 44px;
      position: absolute;
      top: -80px;
    }
  }

  &.modalLoading .container-buttons {
    display: none;
  }
`;
