import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 15px 15px 15px 20px;
  border-radius: 10px;
  border: 1px solid #f0f0f0;
  width: 100%;
  transition: background 0.25s;
  background-color: ${({ isSelected }) => (isSelected ? "#eeeef7" : "white")};
  cursor: pointer;
  * {
    cursor: inherit !important;
  }
  &:hover {
    background-color: ${({ isSelected }) =>
      isSelected ? "#eeeef7" : "#f6f5f5"};
  }

  .containerHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: flex-start;

    .container-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
      flex-grow: 2;

      // title
      > .label-title {
        max-width: 100%;
        display: -webkit-box;
        font-family: "RobotoMedium", sans-serif;
        font-size: 15px;
        color: #262626;
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: default;
        line-height: 1.1;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      // tooltip title
      * > .tooltip-labelTitle {
        display: flex;
        flex-direction: column;
        gap: 3px;
        max-width: 400px;

        .label-title {
          font-family: "RobotoMedium", sans-serif;
          font-size: 12px;
          color: #262626;
          text-align: left;
          line-height: 1.1;
          font-weight: 600;
        }
        .label-info {
          font-family: "RobotoRegular", sans-serif;
          font-size: 11px;
          color: #262626;
          text-align: left;
          font-weight: 400;
          line-height: 1.1;
        }
      }

      // date
      > .label-date {
        max-width: 100%;
        font-family: "RobotoRegular", sans-serif;
        font-size: 11px;
        color: #b3b3b3;
        text-align: left;
        white-space: pre-line;
        cursor: default;
        line-height: 1.1;
      }
      // tooltip dates
      * > .tooltip-labelDate {
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-width: 400px;

        .containerDate {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 3px;

          .label-title {
            font-family: "RobotoMedium", sans-serif;
            font-size: 12px;
            color: #262626;
            text-align: left;
            line-height: 1.1;
            font-weight: 600;
          }
          .label-date {
            font-family: "RobotoRegular", sans-serif;
            font-size: 11px;
            color: #262626;
            text-align: left;
            font-weight: 400;
            line-height: 1.1;

            &.visibleDate {
              color: #8a6caa;
              font-family: "RobotoMedium", sans-serif;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  // descripcion
  .label-descripcion {
    display: -webkit-box;
    width: 100%;
    margin-top: 10px;
    font-family: "RobotoRegular", sans-serif;
    font-size: 12px;
    color: #808080;
    text-align: left;
    cursor: default;
    line-height: 1.1;
    white-space: pre-wrap;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // legends
  .containerLegends {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;

    // legendas de texto
    .itemLegend {
      padding: 4px 10px 5px 10px;
      border-radius: 3px;
      font-family: "RobotoRegular", sans-serif;
      font-size: 10px;
      text-align: center;
      cursor: default;
      line-height: 1.1;

      // LEGEND STATUS
      &.statusInProcess {
        background-color: #8a6caa;
        color: white;
      }
      &.statusNew {
        background-color: #85bc5b;
        color: white;
        font-weight: 600;
      }
      &.statusCompleted {
        background-color: rgba(0, 0, 0, 0.05);
        color: #707070;
      }

      // LEGEND URGENCY
      &.urgencyLow {
        background-color: rgba(0, 0, 0, 0.05);
        color: #707070;
      }
      &.urgencyMedium {
        background-color: #c5b06b;
        color: white;
        font-weight: 600;
      }
      &.urgencyHigh {
        background-color: #e54646;
        color: white;
      }

      // LEGEND COMPANY NAME
      &.companyName {
        background-color: rgba(0, 0, 0, 0.05);
        color: #707070;
      }

      // LEGEND GENERIC
      &.genericLegend {
        background-color: rgba(0, 0, 0, 0.05);
        color: #707070;
      }
    }

    // TOOLTIPS DE LEYENDAS
    > * .tooltip-legendStatus,
    > * .tooltip-legendUrgency {
      max-width: 300px;
    }
    > * .tooltip-legendCompanyName {
      max-width: 300px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .containerItem {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 3px;

        .label-title {
          font-family: "RobotoMedium", sans-serif;
          font-size: 12px;
          color: #262626;
          text-align: left;
          line-height: 1.1;
          font-weight: 600;
        }
        .label-info {
          font-family: "RobotoRegular", sans-serif;
          font-size: 11px;
          color: #262626;
          text-align: left;
          font-weight: 400;
          line-height: 1.1;
        }
        .label-user {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap: 4px;

          .imgUser {
            width: 20px;
            height: 20px;
            .icon-imgUser {
              width: 100%;
              height: 100%;
              padding: 0px;
            }
          }
        }
      }
    }
    > * .tooltip-genericLegends {
      max-width: 300px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 3px;

      .label-title {
        font-family: "RobotoMedium", sans-serif;
        font-size: 12px;
        color: #262626;
        text-align: left;
        line-height: 1.1;
        font-weight: 600;
      }
      .label-info {
        font-family: "RobotoRegular", sans-serif;
        font-size: 11px;
        color: #262626;
        text-align: left;
        font-weight: 400;
        line-height: 1.1;
      }
    }

    // ASSIGNED USER
    .imgAssignedUser {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      img {
        border-radius: inherit;
      }
    }
    > * .tooltip-imgAssignedUser {
      max-width: 300px;
      display: flex;
      flex-direction: column;
      gap: 3px;

      .label-title {
        font-family: "RobotoMedium", sans-serif;
        font-size: 12px;
        color: #262626;
        text-align: left;
        line-height: 1.1;
        font-weight: 600;
      }
      .label-info {
        font-family: "RobotoRegular", sans-serif;
        font-size: 11px;
        color: #262626;
        text-align: left;
        font-weight: 400;
        line-height: 1.1;
      }
    }
  }
`;
