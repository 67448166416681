import { Container } from "./styles";
import ticketIcon from "../../../assets/images/tickets.svg";
import releasesIcon from "../../../assets/images/releases.svg";
import { getCustomDate } from "../../../utils/dates";
import { useHistory, useLocation } from "react-router-dom";
import { fetchPUT } from "../../../utils/handle_http";

export const NotificationItem = ({
  title,
  notificationType = "ticket",
  date,
  read,
  notificationTypeId,
  notificationId,
  arrayNotifications,
  setArrayNotifications,
  onClickNotification,
  status,
  page, // "Tickets" | "Planning" | "Projects"
}) => {
  const history = useHistory();
  const location = useLocation();
  const customDate = getCustomDate(date);

  const notificationIcon = { release: releasesIcon, ticket: ticketIcon };

  const onClickItem = async () => {
    let section = notificationType === "ticket" ? "Tickets" : "Projects";
    if (section === "Projects" && status === "IN_PLANNING")
      section = "Planning";

    const body = { notificationId };
    const arrayCopy = arrayNotifications.slice();
    try {
      if (!read) {
        fetchPUT(process.env.REACT_APP_UPDATE_NOTIFICATION, body, {
          Authorization: sessionStorage.getItem("jwt"),
        });
      }
      arrayCopy.forEach((item) => {
        if (item.notificationId === notificationId) item.readDate = true;
      });
      setArrayNotifications(arrayCopy);
      const newUrl = `/${section}/${notificationTypeId}`;
      history.replace({ pathname: newUrl, state: location.state });
      if (page === section && onClickNotification) {
        onClickNotification(notificationTypeId);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Container read={read} onClick={() => onClickItem()}>
      <img src={notificationIcon[notificationType]} alt="" />
      <div className="notification-info">
        <h2 className="notification-title">{title}</h2>
        <p className="creation-date">{customDate}</p>
      </div>
    </Container>
  );
};
