import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0px 20px 0px 30px;

  // contenedor de los filters
  .filters {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 15px 10px;
    margin-top: 20px;
    > * {
      align-self: stretch;

      .container-buttonSelect {
        height: 100%;
        .buttonSelect {
          height: 100%;
          .button {
            height: 100%;
          }
        }
      }

      &.inputSearcher {
        align-self: center;
        input {
          flex-grow: 2;
        }
      }
      @media (max-width: 600px) { 
        &.inputSearcher {
         max-width:100%;
        }
      }
    }
  }

  .contentPage {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
    flex-grow: 2;
    overflow: hidden;
    padding: 0px 0px 15px 0px;

    .ticketList {
      width: 30%;
      max-width: 400px;
      height: 100%;
    }
    .container-mob{
      width:100%;
      display:inline-flex;
      height:100%;
    }
    @media (max-width: 600px) { 
      .ticketListmobile {
        width: 100%;
        max-width: 400px;
        height: 100%;
      }
      .container-mob{
        width:100%;
        display:flex;
        flex-direction:column;
        gap:10px;
        height:100%;
        padding:10px;
      }
      .contentPage {
        margin-top: 0px;
      }
    }
  }

  // modalAlert
  + .container-backgroundModal .ticketsPage-modal .contentModal {
    max-width: 400px;
  }
  @media (max-width: 1024px) { 
    padding: 0px 20px 0px 20px;
  }
`;

export const SelectTickets = styled.div`
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  width: 60%;
  display: flex;
  align-items: ${({ showMenu }) => (showMenu ? "start" : "center")};
  justify-content: ${({ showMenu }) => (showMenu ? "start" : "center")};
  flex-direction: ${({ showMenu }) => (showMenu ? "row" : "column")};
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 95%;
  .view-ticket {
    display: flex;
    flex-direction: column;
    padding: 15px 20px;
    width: 40%;
    height: 100%;
    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 4px 10px;
      width: auto;
      white-space: nowrap;
      height: 20px;
      background: #0000000d;
      border: none;
      border-radius: 3px;
      text-align: center;
      letter-spacing: 0.16px;
      font-size: 10px;
      font-family: sans-serif;
      color: #707070;
    }
  }
  .empty-hi-img {
    & + * {
      margin-top: 40px;
    }
  }
  .empty-hi {
    color: #808080;
    font-size: 17px;
  }
  .date {
    color: #b3b3b3;
    font-size: 11.8px;
    font-family: sans-serif;
  }

  hr {
    width: 100%;
    margin: 8px 0;
  }
  .responsible-play {
    display: flex;
    button {
      background: transparent;
      & + * {
        margin-left: 10px;
      }
    }
    .play,
    .done {
      padding: 0;
    }
    .status {
      background: #8a6caa;
      color: #ffffff;
    }
  }
  .buttons {
    display: inline;
    align-items: center;
    button {
      display: inline;
      //display: flex;
      // flex-direction: row;
      // justify-content: center;
      // align-items: center;
      padding: 4px 10px;
      width: auto;
      white-space: nowrap;
      height: 20px;
      background: #0000000d;
      border: none;
      border-radius: 3px;
      text-align: center;
      letter-spacing: 0.16px;
      font-size: 10px;
      font-family: sans-serif;
      color: #707070;
      & + * {
        margin-left: 10px;
      }
    }
    .status {
      background: #8a6caa;
      color: #ffffff;
    }
  }
  .description-show-release {
    border: none;
    background: none;
    width: 100%;
    height: 100%;
    font: normal normal normal 12px/14px sans-serif;
    letter-spacing: 0px;
    color: #808080;
  }
`;

export const CloseTicket = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(59, 59, 59, 0.53);
  backdrop-filter: blur(1px);
  z-index: 100;
  margin: 0;
  .modal-add-release {
    box-shadow: 0px 2px 4px #00000040;
    border-radius: 10px;
    flex-direction: column;
    width: 330px;
    height: auto;
    background-color: white;
    padding: 15px 20px;
    position: relative;
    top: 23%;
    left: 39%;
    .title-add {
      color: #262626;
      font: normal normal 800 17px/17px sans-serif;
      & + * {
        margin-top: 10px;
      }
    }
    .add-picture-input {
      display: none;
    }
    .add-picture {
      height: auto;
      width: 70px;
      white-space: pre;
      padding: 12px 5px;
      background-color: #ffffff;
      text-align: center;
      font: normal normal bold 9px/9px sans-serif;
      letter-spacing: 0.2px;
      color: #e33aa9;
      border: 1.5px solid #e33aa9;
      border-radius: 5px;
      display: inline-grid;
      justify-items: center;
      cursor: pointer;
      label {
        cursor: pointer;
      }
      & + * {
        margin-left: 15px;
      }
    }
    #show-panel-img {
      margin-top: 10px;
      background-color: #f0f0f0;
      width: 100%;
      padding: 20px;
      border-radius: 5px;
      height: 110px;
      text-align: center;
      display: flex;
      #myList {
        display: flex;
        list-style-type: none;
        padding: 10px;
        margin: 0;
        overflow-x: auto;
        li {
          img {
            max-height: 80px;
          }
          & + * {
            margin-left: 5px;
          }
        }
      }
    }
    .name {
      width: 100%;
      position: relative;
      border-radius: 5px;
      border: 1px solid #eaeaea;
      font: normal normal normal 11px/11px sans-serif;
      &:hover {
        border: 1px solid #e33aa9;
      }
      img {
        position: absolute;
        top: 30%;
        left: 3%;
        height: 10px;
      }
      input,
      textarea {
        border: none;
        padding: 8px 8px 8px 28px;
        border-radius: 5px;
        width: 100%;
        &::placeholder {
          color: #9f9f9f;
        }
      }
      .descrip-textarea {
        padding: 8px;
        height: 60px;
      }
      & + * {
        margin-top: 10px;
      }
    }
    .filters {
      display: flex;

      button {
        font: normal normal normal 10px/10px sans-serif;
        letter-spacing: 0.2px;
        color: #808080;
        width: auto;
        min-width: auto;
        border: none;
        white-space: nowrap;
        background-color: #ffffff;
        height: auto;
        padding: 3px 8px;
        & + * {
          margin-left: 10px;
        }
        label {
          margin: 0;
          & + * {
            //margin-left: 25%;
          }
        }
      }
    }
    .title-new-release {
      ::placeholder {
        font: normal normal bold 17px/20px sans-serif;
        letter-spacing: 0.85px;
        color: #b3b3b3;
      }
    }
    .description-new-release {
      ::placeholder {
        font: normal normal medium 12px/14px sans-serif;
        letter-spacing: 0.6px;
        color: #b3b3b3;
      }
    }
    .error-label {
      color: red;
      margin: 0;
      height: 25px;
      margin-left: 15px;
      font-family: sans-serif;
      letter-spacing: 0.75px;
      font-size: 12px;
    }
    .buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      button {
        width: auto;
        height: 25px;
        font: normal normal normal 12px/12px sans-serif;
        letter-spacing: 0.24px;
        color: #e33aa9;
        padding: 5px 15px;
        background: white;
        border: 1px solid #e33aa9;
        border-radius: 17px;
        & + * {
          margin-left: 10px;
        }
      }
      .sign-button {
        background-color: #e33aa9;
        color: white;
      }
    }
  }
`;
