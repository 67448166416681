import styled from "styled-components";

export const Slider = styled.div`
  width: 60%;
  height: 100%;
  border-radius: 10px;
  background: white;
  padding: 15px 16px 17px 20px;
  //border: 1px solid blue;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0px;
  flex-grow: 2;
  min-width: 250px;
  height: 100%;
  border-radius: 10px;
  overflow: auto;
  border: 1px solid #f0f0f0;

  // contenedor de la parte izquierda
  .containerData {
    width: 40%;
    min-width: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 15px 20px;
    border-right: 1px solid;
    border-color: inherit;

    .containerHeader {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 12px;
      align-items: flex-start;
      //border: 1px solid blue;

      .container-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 3px;
        flex-grow: 2;

        // title
        > .label-title {
          font-family: "RobotoMedium", sans-serif;
          font-size: 15px;
          color: #262626;
          text-align: left;
          cursor: default;
          white-space: pre-line;
          line-height: 1.1;
          padding: 3px 0px;
          border: 1px solid transparent;
        }

        // input title
        .inputTitle {
          background-color: transparent;
          width: 100%;
          min-width: 40px;
          font-family: "RobotoMedium", sans-serif;
          font-size: 15px;
          color: #262626;
          text-align: left;
          line-height: 1.1;
          padding: 3px 3px;
          border-radius: 3px;
          border: 1px solid #f0f0f0;
          outline: none;
          transition: border 0.3s;
          &:focus {
            border: 1px solid #b3b3b3;
          }
          &::placeholder {
            color: #9f9f9f;
          }
        }

        // date
        > .label-date {
          max-width: 100%;
          font-family: "RobotoRegular", sans-serif;
          font-size: 11px;
          color: #b3b3b3;
          text-align: left;
          white-space: pre-line;
          cursor: default;
          line-height: 1.1;
        }
        // tooltip dates
        * > .tooltip-labelDate {
          display: flex;
          flex-direction: column;
          gap: 10px;
          max-width: 400px;

          .containerDate {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 3px;

            .label-title {
              font-family: "RobotoMedium", sans-serif;
              font-size: 12px;
              color: #262626;
              text-align: left;
              line-height: 1.1;
              font-weight: 600;
            }
            .label-date {
              font-family: "RobotoRegular", sans-serif;
              font-size: 11px;
              color: #262626;
              text-align: left;
              font-weight: 400;
              line-height: 1.1;

              &.visibleDate {
                color: #8a6caa;
                font-family: "RobotoMedium", sans-serif;
                font-weight: 600;
              }
            }
          }
        }
      }

      // menu de opciones (edit | delete)
      .menuPopUp {
        min-width: 150px;
      }
    }

    .buttonsSection1 {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: flex-start;
      align-items: center;
      padding: 10px 0px;
      //border: 1px solid red;

      // usuario asignado
      .label-assignedUser {
        max-width: 120px;
        padding: 4px 10px 5px 10px;
        border-radius: 3px;
        font-family: "RobotoRegular", sans-serif;
        font-size: 10px;
        text-align: center;
        cursor: default;
        line-height: 1.1;
        background-color: #8a6caa;
        color: white;
      }
      > * .tooltip-assignedUser {
        max-width: 250px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 3px;

        .label-title,
        .label-info {
          text-align: left;
          line-height: 1.1;
          color: #262626;
        }
        .label-title {
          font-family: "RobotoMedium", sans-serif;
          font-size: 12px;
          font-weight: 600;
        }
        .label-info {
          font-family: "RobotoRegular", sans-serif;
          font-size: 11px;
          font-weight: 400;
        }
      }

      // SELECT USER ASIGNADO
      .selectResponsible {
        border-radius: 3px;

        .btnSelectResponsible {
          .button {
            padding: 4px 10px 5px 10px;
            font-size: 10px;
            text-align: center;
            line-height: 1.1;
            background-color: rgba(0, 0, 0, 0.05);
            color: #707070;
            border: none;
          }
        }
      }
    }

    .buttonsSection2 {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: flex-start;
      align-items: center;
      padding: 10px 0px;
      //border: 1px solid red;

      // etiquetas de vista
      .label-status,
      .label-urgency,
      .label-companyName {
        padding: 4px 10px 5px 10px;
        border-radius: 3px;
        font-family: "RobotoRegular", sans-serif;
        font-size: 10px;
        text-align: center;
        cursor: default;
        line-height: 1.1;
        background-color: rgba(0, 0, 0, 0.05);
        color: #707070;
      }
      // LEGEND STATUS
      .label-status {
        &.statusInProcess {
          background-color: #8a6caa;
          color: white;
        }
        &.statusNew {
          background-color: #85bc5b;
          color: white;
          font-weight: 600;
        }
      }
      // LEGEND URGENCY
      .label-urgency {
        &.urgencyMedium {
          background-color: #c5b06b;
          color: white;
          font-weight: 600;
        }
        &.urgencyHigh {
          background-color: #e54646;
          color: white;
        }
      }

      // SELECT URGENCY
      .selectUrgency {
        border-radius: 3px;

        .btnSelectUrgency {
          .button {
            padding: 4px 10px 5px 10px;
            font-size: 10px;
            text-align: center;
            line-height: 1.1;
            background-color: rgba(0, 0, 0, 0.05);
            color: #707070;
            border: none;
          }
          &.urgencyMedium {
            .button {
              background-color: #c5b06b;
              color: white;
              font-weight: 600;
            }
          }
          &.urgencyHigh {
            .button {
              background-color: #e54646;
              color: white;
            }
          }
        }
      }

      // TOOLTIPS DE LEYENDAS
      > * .tooltip-status,
      > * .tooltip-urgency {
        max-width: 300px;
      }
      > * .tooltip-companyName {
        max-width: 300px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .containerItem {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 3px;

          .label-title,
          .label-info {
            color: #262626;
            text-align: left;
            line-height: 1.1;
          }
          .label-title {
            font-family: "RobotoMedium", sans-serif;
            font-size: 12px;
            font-weight: 600;
          }
          .label-info {
            font-family: "RobotoRegular", sans-serif;
            font-size: 11px;
            font-weight: 400;
          }
        }
      }
    }

    hr {
      width: 100%;
      margin: 0px;
    }

    .inputDescription {
      background-color: transparent;
      width: 100%;
      height:20vh;
      min-width: 40px;
      margin-top: 10px;
      font-family: "RobotoRegular", sans-serif;
      font-size: 12px;
      color: #808080;
      text-align: left;
      line-height: 1.1;
      padding: 3px 3px;
      border-radius: 3px;
      border: 1px solid #f0f0f0;
      outline: none;
      flex-grow: 2;
      resize: none;
      transition: border 0.3s;
      &:disabled {
        border-color: transparent;
      }
      &:focus {
        border: 1px solid #b3b3b3;
      }
      &::placeholder {
        color: #9f9f9f;
      }
    }
  }

  // container del chat fixed
  .chatTicket {
    border: none;
    z-index: 9;
  }
  @media (max-width: 600px){
    .chatTicket #containerItems,
    .chatTicket .sc-jMakVo.kWJHt {
      width:100%!important;
    }
  }
  

  // modal warning
  .modalWarning .contentModal {
    max-width: 70%;
    min-width: 300px;
    padding: 15px 20px;

    header {
      margin-bottom: 10px;
    }

    .label-title,
    .label-message {
      text-align: left;
    }
    .label-message {
      white-space: pre-line;
    }

    .container-buttons {
      justify-content: flex-end;
    }
  }

  // modal delete
  .modalDelete .contentModal {
    max-width: 70%;
    min-width: 300px;
    padding: 15px 20px;

    header {
      margin-bottom: 10px;
    }

    .label-title,
    .label-message {
      text-align: left;
    }

    .container-customComponent {
      margin: 0px;

      .inputCommentDelete {
        background-color: transparent;
        width: 100%;
        min-width: 40px;
        font-family: "RobotoRegular", sans-serif;
        font-size: 12px;
        color: #808080;
        text-align: left;
        line-height: 1.1;
        padding: 8px 10px;
        border-radius: 5px;
        border: 1px solid #f0f0f0;
        outline: none;
        resize: none;
        transition: border 0.3s;
        &:focus {
          border: 1px solid #b3b3b3;
        }
        &::placeholder {
          color: #9f9f9f;
        }
      }
    }

    .container-buttons {
      justify-content: flex-end;
    }
  }

  // modal finish
  .modalFinish .contentModal {
    width: fit-content;
    max-width: 450px; //620px;
    min-width: 300px;
    max-height: 85%;
    padding: 15px 20px;

    header {
      margin-bottom: 10px;
    }

    .label-title,
    .label-message {
      text-align: left;
    }

    .container-customComponent {
      margin: 0px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      max-height: unset;
      overflow: hidden;

      .inputCommentFinish {
        background-color: transparent;
        width: 100%;
        min-width: 40px;
        font-family: "RobotoRegular", sans-serif;
        font-size: 12px;
        color: #808080;
        text-align: left;
        line-height: 1.1;
        padding: 8px 10px;
        border-radius: 5px;
        border: 1px solid #f0f0f0;
        outline: none;
        resize: none;
        transition: border 0.3s;
        &:focus {
          border: 1px solid #b3b3b3;
        }
        &::placeholder {
          color: #9f9f9f;
        }
      }

      .container-imgPreview {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-items: flex-start;
        align-items: stretch;
        padding: 20px;
        column-gap: 18px;
        row-gap: 10px;
        border-radius: 5px;
        background-color: #f4f4f7;
        overflow: auto;

        .buttonChooser {
          height: 75px;
          width: 75px;
          .button {
            height: 100%;
            font-size: 9px;
            padding: 5px 12px;
          }
          .icon {
            font-size: 22px;
          }
        }

        .containerItem-img {
          position: relative;
          height: 75px;
          width: 75px;
          display: grid;
          justify-items: flex-start;
          border-radius: inherit;
          background-color: inherit;

          .containerImg {
            border-radius: inherit;
            width: 100%;
            height: 100%;
            background-color: white;
            z-index: 10;
          }

          .container-delete {
            position: absolute;
            border-radius: inherit;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            display: grid;
            place-items: center;
            background-color: inherit;
            z-index: 11;
            opacity: 0;
            transition: all 0.3s;
            background-color: rgba(0, 0, 0, 0.35);
            overflow: hidden;

            .btn-delete .button {
              border: none;
              padding: 4px 8px;
              color: #262626 !important;
              .icon {
                font-size: 8px;
              }
            }
          }

          &:hover {
            .container-delete {
              opacity: 1;
            }
          }
        }

        > * .tooltip-containerItemImg {
          max-width: 150px;
        }
      }
    }

    .container-buttons {
      justify-content: flex-end;
    }
    #sub-title {
      font-size: 13px;
      margin-top: 5px;
    }
  }

  .container-emptyPage {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-radius: inherit;
  }

  @media (max-width: 1024px) { 
    display: flex;
    flex-direction: column;
    .containerData {
      width: 100%;
      height: 30vh;
    }

  }
  @media (max-width: 600px) { 
    width:100%;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    .containerData {
      width: 100%;
      height: 40vh;
      border:none;
    }
    

  }
`;
