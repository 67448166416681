import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { VerticalSideMenuMainPage } from "./components/VerticalSideMenuMainPage";
import { Container, ContainerPage } from "./styles";
import ProtectedPage from "./pages/ProtectedPage/App";
import { NotFound } from "./pages/NotFound";

function App() {
  return (
    <Container>
      <Router>
        <VerticalSideMenuMainPage />
        <ContainerPage>
          <Switch>
            <Route
              exact
              path={[
                "/Tickets/:id",
                "/MyTickets/:id",
                "/Tickets",
                "/MyTickets",
                "/",
              ]}
            >
              <ProtectedPage />
            </Route>
            <Route component={NotFound} />
          </Switch>
        </ContainerPage>
      </Router>
    </Container>
  );
}

export default App;
