import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  background-color: #fff;
  max-height: 500px;
  overflow: hidden;
  text-align: left;
  padding: 15px;
  box-shadow: 0px 2px 4px #00000040;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  z-index: 100;

  h2 {
    font-family: "RobotoMedium", sans-serif;
    font-size: 16px;
    color: #262626;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .notifications-container {
    overflow: auto;

    .load-notifications {
      background-color: #f7f7fc;
      border: none;
      border-radius: 5px;
      width: 100%;
      padding: 15px;
      font-family: Roboto;
      font-size: 12px;
      font-weight: 400;
      color: #8a6caa;
      display: flex;
      justify-content: center;
      align-items: center;

      .load-icon {
        width: 18px;
        height: 18px;
        & + * {
          margin-left: 15px;
        }
      }

      p {
        margin: 0;
      }
    }
  }

  .no-notification {
    width: 300px;
    .title-notification {
      font-family: "RobotoRegular", sans-serif;
      font-size: 14px;
      line-height: 1.2;
      margin: 0;
    }
  }
`;
