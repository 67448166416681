import styled from "styled-components";

export const Loader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: rgba(59, 59, 59, 0.53);
  backdrop-filter: blur(4px);
  top: 0;
  left: 0;
  z-index: 100;
  .modal-loader {
    background-color: #ffffff;
    box-shadow: 0px 2px 4px #00000040;
    height: auto;
    width: 330px;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    p {
      font-family: sans-serif;
      font-size: 20px;
      font-weight: bold;
      color: #262626;
    }
  }
`;
