import { Container } from "./styles";
import ModalAddTicket from "../ModalAddTicket";
import { Notifications } from "../Notifications";
import { Profile } from "../Profile";
import { useEffect, useState } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import { ButtonV2 } from "contentoh-components-library";
import ModalSignIn from "../ModalSignIn";
import { getSession } from "../../utils/session";
import { faPlus as IconAdd } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "contentoh-components-library";
import ModalAddRelease from "../ModalAddRelease";
import { isValidNaturalNumber } from "../../utils/validations";
import { ModalTask } from "../ModalTask";
import { fetchGET } from "../../utils/handle_http";

export const HeaderPage = (props) => {
  const {
    assignableUsers, // []
    companies, // []
    title, // string
    selectedRelease /* {
      id: number
      status: string
      createdByUser: { id , name }
    } */,
    onNewTicket, // (ticket, subscription, users, company) => {}
    onNewRelease, // (dataNewTicket) => {}
    onNewTask, // (task, subscription, users, maxMinDates) => {}
    onClickNotification, // (newId) => {}
  } = props;
  const [arrayNotifications, setArrayNotifications] = useState([]);
  const [showModalSignIn, setShowModalSignIn] = useState(false);
  const [showModalAddTicket, setShowModalAddTicket] = useState(false);
  const [showModalAddRelease, setShowModalAddRelease] = useState(false);
  const [showModalAddTask, setShowModalAddTask] = useState(false);
  const [modalAlert, setModalAlert] = useState({
    show: false,
    title: "",
    message: "",
    icon: "info", // "info" | "success"
  });
  const [startUpdate, setStartUpdate] = useState(0);
  const [processUpdateID, setProcessUpdateID] = useState();

  const location = useLocation();

  useEffect(() => {
    return () => {
      if (processUpdateID !== undefined) clearTimeout(processUpdateID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // if (startUpdate === 0) return;
    const processID = setTimeout(() => {
      getNotificationUser();
      setStartUpdate((prev) => prev + 1);
    }, 15000);
    setProcessUpdateID(processID);
    return () => {
      clearTimeout(processID);
    };
  }, [startUpdate]);

  useEffect(() => {
    if (location.state?.Auth === true) {
      getNotificationUser();
    }
  }, [location.state?.Auth]);

  const getNotificationUser = async () => {
    const paramsHeaders = {
      Authorization: sessionStorage.getItem("jwt"),
    };
    const response = await fetchGET(
      process.env.REACT_APP_GET_NOTIFICATIONS,
      {},
      paramsHeaders
    );
    // cuando hay error
    if (!response.body) {
      //onNewAlert("No fue posible obtener tus notificaciones", "", "info");
      return;
    }
    // success
    setArrayNotifications(response.body.data ?? []);
  };

  const onNewAlert = (title, message, icon) => {
    setModalAlert({
      show: true,
      title,
      message,
      icon: icon === "success" ? "success" : "info",
    });
  };

  /*--------------------------------
  RENDER
  ----------------------------------*/
  const session = getSession();
  const Auth = location.state?.Auth === true;

  // ver boton add proyecto ??
  let showButtonAddRelease = false;
  if (
    useRouteMatch({
      path: ["/Projects/:id", "/Planning/:id", "/Projects", "/Planning", "/"],
      exact: true,
    }) &&
    Auth &&
    session?.isUserAdminTech
  ) {
    showButtonAddRelease = true;
  }

  // ver boton add ticket ??
  let showButtonAddTicket = false;
  if (
    useRouteMatch({
      path: ["/Tickets/:id", "/Tickets"],
      exact: true,
    }) &&
    Auth &&
    (!session?.isUserTech || session?.isUserAdminTech)
  ) {
    showButtonAddTicket = true;
  }
  // ver boton add task ??
  let showButtonAddTask = false;
  if (
    useRouteMatch({
      path: ["/Projects/:id/Tasks", "/Planning/:id/Tasks"],
      exact: true,
    }) &&
    Auth &&
    isValidNaturalNumber(session?.id) &&
    session.isUserAdminTech &&
    ["IN_PLANNING", "IN_PROGRESS"].includes(selectedRelease.status)
  ) {
    showButtonAddTask = true;
  }

  return (
    <>
      <Container>
        <div className="contentHeader">
          <p className="labelTitle">{title}</p>
          {location.state?.Auth ? (
            <div className="container-userMenu">
              {/* boton add proyecto */}
              {showButtonAddRelease && (
                <ButtonV2
                  className="buttonAddRelease"
                  type="purpleS2"
                  size={18}
                  icon={IconAdd}
                  borderType="circle"
                  onClick={(event) => {
                    setShowModalAddRelease(true);
                  }}
                />
              )}

              {/* boton add tarea */}
              {showButtonAddTask && (
                <ButtonV2
                  className="buttonAddTask"
                  type="purpleS2"
                  size={18}
                  icon={IconAdd}
                  borderType="circle"
                  onClick={(event) => {
                    setShowModalAddTask(true);
                  }}
                />
              )}

              {/* boton add ticket */}
              {showButtonAddTicket && (
                <ButtonV2
                  className="buttonAddTicket"
                  type="purpleS2"
                  size={18}
                  icon={IconAdd}
                  borderType="circle"
                  onClick={(event) => {
                    setShowModalAddTicket(true);
                  }}
                />
              )}
              <Notifications
                arrayNotifications={arrayNotifications}
                setArrayNotifications={setArrayNotifications}
                onClickNotification={onClickNotification}
              />
              <Profile />
            </div>
          ) : props.showButtonSignIn ? (
            <ButtonV2
              className="buttonSignIn"
              type="pink"
              label="Iniciar sesión"
              borderType="oval"
              onClick={(event) => {
                setShowModalSignIn(true);
              }}
            />
          ) : null}
        </div>
      </Container>

      {/* modal iniciar sesion */}
      {showModalSignIn && (
        <ModalSignIn
          show={showModalSignIn}
          onClickCancel={(event) => setShowModalSignIn(false)}
        />
      )}

      {/* modal add ticket */}
      {showModalAddTicket && (
        <ModalAddTicket
          show={showModalAddTicket}
          assignableUsers={assignableUsers}
          companies={companies}
          onNewAlert={onNewAlert}
          onClickCancel={(event) => setShowModalAddTicket(false)}
          onNewTicket={(ticket, subscription, users, company) => {
            setShowModalAddTicket(false);
            if (onNewTicket) onNewTicket(ticket, subscription, users, company);
          }}
        />
      )}

      {/* modal add proyecto */}
      {showModalAddRelease && (
        <ModalAddRelease
          assignableUsers={assignableUsers}
          onNewAlert={onNewAlert}
          onClickCancel={(event) => setShowModalAddRelease(false)}
          onNewRelease={(release, subscription, users) => {
            setShowModalAddRelease(false);
            if (onNewRelease) onNewRelease(release, subscription, users);
          }}
        />
      )}

      {/* modal add tarea */}
      {showModalAddTask && (
        <ModalTask
          minDate={new Date()}
          selectedRelease={selectedRelease}
          assignableUsers={assignableUsers}
          onNewAlert={onNewAlert}
          onClickCancel={(event) => setShowModalAddTask(false)}
          onNewTask={(task, subscription, users, maxMinDates) => {
            setShowModalAddTask(false);
            if (onNewTask) onNewTask(task, subscription, users, maxMinDates);
          }}
        />
      )}

      {/* modal de alertas */}
      {modalAlert.show && (
        <Modal
          className="headerPage-modalAlert"
          show={modalAlert.show}
          title={modalAlert.title}
          message={modalAlert.message}
          icon={modalAlert.icon}
          onClickBtnDefault={(event) => {
            setModalAlert({ show: false });
          }}
        />
      )}
    </>
  );
};
