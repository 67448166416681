import styled from "styled-components";

export const Container = styled.div`
  flex-grow: 2;
  display: flex;
  flex-direction: row;
  gap: 7px;
  justify-content: flex-start;
  align-items: center;

  .buttonReturn{
    width: 30px;
    height: 30px;
    min-width: 20px;
    .button {
      width: 100%;
      height: 100%;
      padding: 0px;
    }
  }
  .buttonReturn {
    .icon {
      padding: 0px 0px 0px 2px;
    }
  }
`;
