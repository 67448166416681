import { Container, Loader } from "./styles";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Calendar } from "../Calendar";
import DatePicker from "react-datepicker";
import moment from "moment";
import { fetchGET, fetchPOST } from "../../utils/handle_http";
import {
  SelectV2,
  ButtonV2,
  CustomIcon,
  RangeCalendar,
} from "contentoh-components-library";
import {
  diffObjectValues,
  isObject,
  isStringEmpty,
} from "../../utils/validations";
import { faRocket as IconProject } from "@fortawesome/free-solid-svg-icons";

export default function ModalAddRelease(props) {
  const {
    assignableUsers, // []
    onNewAlert, // (title , message, icon) => {}
    onClickCancel, // (event) => {}
    onNewRelease /* (
      release: { ... }
      subscription: { ... }
      users: { ... }
    ) => {}
    */,
  } = props;
  const [inputTitle, setInputTitle] = useState("");
  const [inputDescription, setInputDescription] = useState("");
  const [focusInputTitle, setFocusInputTitle] = useState(false);
  const [itemsResponsibleSelect, setItemsResponsibleSelect] = useState([]);
  const [itemsPlatformSelect, setItemsPlatformSelect] = useState([
    { label: "Colaboradores", value: "CREATOR" },
    { label: "Retailers", value: "RETAILER" },
    { label: "Proveedores", value: "PROVIDER" },
  ]);

  const [selectedResponsible, setSelectedResponsible] = useState({});
  const [selectedPlatform, setSelectedPlatform] = useState({});
  const [deliveryDate, setDeliveryDate] = useState(new Date());

  const [rangeDates, setRangeDates] = useState({});
  const [currentDates, setCurrentDates] = useState();
  const [label, setLabel] = useState("Seleccionar Fechas");

  const [disabledSaveButton, setDisabledSaveButton] = useState(true);
  const [loadingSaveButton, setLoadingSaveButton] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setDeliveryDate(getMinDate());
  }, []);

  useEffect(() => {
    if (Array.isArray(assignableUsers)) {
      let items = assignableUsers.map((user) => {
        return { label: user.name, value: user.id };
      });
      setItemsResponsibleSelect(items);
    }
  }, [assignableUsers]);

  useEffect(() => {
    let disabled = false;
    // validar titulo
    if (isStringEmpty(inputTitle)) disabled = true;
    // validar descripcion
    else if (isStringEmpty(inputDescription)) disabled = true;
    // validar plataforma
    else if (!Object.keys(selectedPlatform || {}).length) disabled = true;
    // validar user asignado
    else if (!Object.keys(selectedResponsible || {}).length) disabled = true;
    // validar delivery date
    //else if (deliveryDate.getTime() < getMinDate().getTime()) disabled = true;
    else if (
      isStringEmpty(rangeDates?.estimatedStartDate) ||
      isStringEmpty(rangeDates?.estimatedDate)
    ) {
      disabled = true;
    }
    setDisabledSaveButton(disabled);
  }, [
    inputTitle,
    inputDescription,
    selectedPlatform,
    selectedResponsible,
    rangeDates,
  ]);

  const getMinDate = () => {
    const today = new Date();
    const minDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 1
    );
    return minDate;
  };

  const onClickSaveButton = async () => {
    setLoadingSaveButton(true);

    let estimatedDate = { start: undefined, end: undefined };
    if (!isStringEmpty(rangeDates?.estimatedStartDate)) {
      estimatedDate.start = new Date(rangeDates.estimatedStartDate).getTime();
    }
    if (!isStringEmpty(rangeDates?.estimatedDate)) {
      estimatedDate.end = new Date(rangeDates.estimatedDate).getTime();
    }
    //add el nuevo proyecto en la BD
    let forProvider = selectedPlatform["PROVIDER"] !== undefined ? true : false;
    let forRetailer = selectedPlatform["RETAILER"] !== undefined ? true : false;
    let forCreator = selectedPlatform["CREATOR"] !== undefined ? true : false;
    let assignedUserId = undefined;
    const selectedRes = Object.keys(selectedResponsible || {});
    if (selectedRes.length) assignedUserId = Number(selectedRes[0]);

    const paramsBody = {
      title: inputTitle.trim(),
      description: inputDescription.trim(),
      assignedUserId: JSON.stringify(assignedUserId),
      forProvider: JSON.stringify(forProvider),
      forRetailer: JSON.stringify(forRetailer),
      forCreator: JSON.stringify(forCreator),
      // -deliveryDate: JSON.stringify(deliveryDate.getTime()),
      estimatedStartDate: JSON.stringify(estimatedDate.start),
      estimatedEndDate: JSON.stringify(estimatedDate.end),
    };
    const paramsHeaders = {
      Authorization: sessionStorage.getItem("jwt"),
    };
    const response = await fetchPOST(
      process.env.REACT_APP_NEW_RELEASE_ENDPOINT,
      paramsBody,
      paramsHeaders
    );
    // cuando hay error
    if (!response.body) {
      onNewAlert(response.message, response.errorDetail, "info");
      setLoadingSaveButton(false);
      return;
    }
    // reflejar cambios en local
    onNewAlert("Proyecto en planeación agregado", "", "success");
    onNewRelease(
      response.body.release,
      response.body.subscription,
      response.body.users
    );
  };

  useEffect(() => {
    if (rangeDates.estimatedStartDate) {
      setLabel(
        `${new Date(rangeDates.estimatedStartDate)
          .toISOString()
          .substring(0, 10)} - ${new Date(rangeDates.estimatedDate)
          .toISOString()
          .substring(0, 10)}`
      );
    }
  }, [rangeDates]);

  return (
    <>
      <Container
        className={"modalAddRelease"}
        show={true}
        title={"Añadir proyecto"}
        customComponent={
          <>
            {/* input titulo */}
            <div
              className={
                "container-inputTitle" + (focusInputTitle ? " active" : "")
              }
            >
              <CustomIcon
                className="icon-title"
                type={"whiteS2"}
                size={13}
                transparent
                icon={IconProject}
              />
              <input
                className="inputTitle"
                placeholder="Nombre del proyecto"
                value={inputTitle}
                onChange={(event) => {
                  setInputTitle(event.target.value);
                }}
                onFocus={(event) => setFocusInputTitle(true)}
                onBlur={(event) => setFocusInputTitle(false)}
              />
            </div>

            {/* input descripcion */}
            <textarea
              className="inputDescription"
              placeholder="Describe el proyecto"
              rows={3}
              value={inputDescription}
              onChange={(event) => {
                setInputDescription(event.target.value);
              }}
            />

            {/* container grid */}
            <div className="container-dataGrid">
              {/* select de plataformas */}
              <label className="label-title">Plataformas</label>
              <SelectV2
                items={itemsPlatformSelect}
                triggerType="click"
                selectButton={{
                  className: "btnSelectPlatform",
                  type: "whiteS3",
                  label: "Elige las plataformas",
                  size: 10,
                }}
                multiple
                typeSelectItems={"checkbox"}
                maxHeightDropdown={"50vh"}
                alignmentItemsOverflow={"rows"}
                classNameSelect="selectPlatform"
                classNameDropdown="dropdownSelectPlatform"
                onChange={(selectedItems) => {
                  if (diffObjectValues(selectedItems, selectedPlatform)) {
                    setSelectedPlatform(selectedItems);
                  }
                }}
              />

              {/* select de responsable */}
              <label className="label-title">Responsable</label>
              <SelectV2
                items={itemsResponsibleSelect}
                triggerType="click"
                selectButton={{
                  className: "btnSelectResponsible",
                  type: "whiteS3",
                  label: "Elige un responsable",
                  size: 10,
                }}
                inputSearch={{
                  className: "inputSearchResponsible",
                  defaultText: "Buscar responsable",
                }}
                typeSelectItems={"marginCheckbox"}
                maxHeightDropdown={"50vh"}
                alignmentItemsOverflow={"rows"}
                classNameSelect="selectResponsible"
                classNameDropdown="dropdownSelectResponsible"
                onChange={(selectedItems) => {
                  if (diffObjectValues(selectedItems, selectedResponsible)) {
                    setSelectedResponsible(selectedItems);
                  }
                }}
              />

              {/* select fecha de entrega */}
              <label className="label-title">Fecha estimada</label>
              <div className="container-rangeCalendar">
                <RangeCalendar
                  setParameterArray={setRangeDates}
                  minDate={new Date()}
                  currentDates={currentDates}
                  label={label}
                  calendarId="range-selector-id"
                />
              </div>
              {/* <Calendar
                startDate={deliveryDate}
                onChange={(newDate) => setDeliveryDate(newDate)}
              /> */}
            </div>
          </>
        }
        buttons={[
          <ButtonV2
            key={"modal-buttonCancel"}
            className="modal-buttonCancel"
            type="white"
            label="Cancelar"
            borderType="oval"
            onClick={(event) => {
              onClickCancel(event);
            }}
          />,
          <ButtonV2
            key={"modal-buttonAdd"}
            className="modal-buttonAdd"
            type="pink"
            label="Añadir"
            borderType="oval"
            disabled={disabledSaveButton}
            isLoading={loadingSaveButton}
            onClick={onClickSaveButton}
          />,
        ]}
      />
    </>
  );
}
