import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  box-sizing: border-box;
  padding: 23px 0 70px 0;
  flex-direction: column;
  justify-content: space-between;
  overflow: none;
  min-width: ${({ bar }) => (bar ? 240 : 66)}px;
  background: linear-gradient(180deg, #e33aa9 0%, #3b1366 100%);
  scrollbar-width: none;
  display: flex;
  text-align: center;
  transition: width 0.18s linear;
  margin: 0;

  .navbar-top {
    .logo {
      height: 22px;
    }
    .option {
      div {
        width: 100%;
      }
      .option-div {
        width: 100%;
        white-space: nowrap;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 50px;
      .active {
        text-decoration: none;
        background: rgba(255, 255, 255, 0.1);
      }
      & + div {
        margin-top: 5px;
      }
      a {
        height: 42px;
        display: flex;
        justify-content: ${({ bar }) => (bar ? "flex-start" : "center")};
        align-items: center;
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 21px;
        letter-spacing: -0.015em;
        transition: color 0.3s linear;
        color: ${({ bar }) => (bar ? "#fafafa" : "transparent")};
        text-decoration: none;
        padding: ${({ bar }) => (bar ? "0 22" : "0 8")}px;
        &.option-link {
          cursor: pointer;
          transition: background 0.2s;
        }
        p {
          cursor: pointer;
          margin: 0%;
        }
        img {
          height: 22px;
          position: absolute;
          left: 17px;
          & + * {
            margin-left: 30px;
          }
        }
      }
      .option-link:hover {
        background: rgba(255, 255, 255, 0.2);
      }
      .sub-option {
        display: flex;
        flex-direction: column;
        padding-left: 28px;
        width: auto;
        height: 42px;
        &:hover {
          background: rgba(255, 255, 255, 0.2);
        }
        a {
          display: flex;
          justify-content: flex-start;
          text-decoration: none;
          position: relative;
          border-left: 1px solid rgba(255, 255, 255, 0.2);
          border-radius: 0;
          transition: color 0.3s linear;
          color: #f0eef2;
          padding-top: 19px;
          padding-left: 24px;
          height: 25px;
          &.active {
            background-color: transparent;
            &::before {
              background-color: #e33aa9;
            }
          }
          &::before {
            border-left: 1px solid #e33aa9;
            width: 10px;
            content: "";
            border-radius: 50%;
            height: 10px;
            background-color: white;
            position: absolute;
            bottom: 0;
            left: -6px;
          }
        }
      }
    }
  }
  @media (max-width: 1024px) { 
    padding: 15px 0 15px 0;
  }
  .header-container {
    display: flex;
    flex-direction:row-reverse;
    justify-content: space-between;
    align-items: center;
    padding:0 20px;
  }
  
  .logo-container {
    flex: 1;
  }
  
  .menu-container {
    display: flex;
    align-items: center;
  }
  .upload-image-menu {
    height: 35px;
    width: 100%;
    border: 1px solid #f0f0f0;
    box-shadow: 0px 2px 4px #00000040;
    border-radius: 10px;
    background-color: white;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 3px 10px;
    position: absolute;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    left: -1%;
    top: 40px;
    z-index: 1;
}
.menu-options {
  height: auto;
  width: auto;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 2px 4px #00000040;
  border-radius: 10px;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.015em;
  color:#f0f0f0;
  background-color: white;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 3px 10px;
  position: absolute;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: start;
  left: 8%;
  top: 40px;
  z-index: 1;
  list-style:none;
  a {
    text-decoration:none;
    color:#707070;
    font-size: 12px;
  }
  .menu-options::active {
    background:purple;
  }
}
`;
