import { Modal } from "contentoh-components-library";
import styled from "styled-components";

export const Container = styled(Modal)`
  .contentModal {
    min-width: 330px;
    max-width: 450px;

    header {
      margin: unset;

      .label-title {
        text-align: left;
      }
    }

    .container-customComponent {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      gap: 10px;
      max-height: 60vh;
      overflow-y: scroll;

      // input comment
      .container-inputTitle {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 10px;
        border-radius: 5px;
        padding: 8px 10px;
        transition: border 0.3s;
        border: 1px solid #f0f0f0;
        &.active {
          border: 1px solid #b3b3b3;
        }

        .inputTitle {
          background-color: transparent;
          flex-grow: 2;
          min-width: 40px;
          font-family: "RobotoRegular", sans-serif;
          font-size: 12px;
          color: #808080;
          text-align: left;
          line-height: 1.1;
          outline: none;
          border: none;
          &::placeholder {
            color: #9f9f9f;
          }
        }
      }
      @media only screen and (max-width: 768px) {
        &.container-customComponent {
          overflow: scroll;
        }
      }

      // descripcion
      .inputDescription {
        background-color: transparent;
        min-height: 100px;
        width: 100%;
        min-width: 40px;
        font-family: "RobotoRegular", sans-serif;
        font-size: 12px;
        color: #808080;
        text-align: left;
        line-height: 1.1;
        padding: 8px 10px;
        border-radius: 5px;
        border: 1px solid #f0f0f0;
        outline: none;
        resize: none;
        transition: border 0.3s;
        &:focus {
          border: 1px solid #b3b3b3;
        }
        &::placeholder {
          color: #9f9f9f;
        }
      }

      .container-selects{
        display:flex;
        justify-content: space-between;
        gap:10px;
      }

      .container-col{
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .container-data .buttonSelect .button {
        padding: 10px 5px;
        gap: 10px;
        
    }

      // container datos extra para user TECH
      .container-dataTech,
      .container-data {
        width: 100%;
        display: grid;
        grid-template-columns: minmax(min-content, auto) minmax(60%, auto);
        grid-column-gap: 4px;
        justify-items: flex-start;
        align-items: center;

        .label-title {
          font-family: "RobotoRegular", sans-serif;
          font-size: 12px;
          color: #262626;
          text-align: left;
          line-height: 1.1;
        }

        .btnSelectResponsible .button,
        .btnSelectUrgency .button,
        .btnSelectCompany .button,
        .btnSelectUser .button,
        .btnSelectType .button,
        .btnSelectArea .button {
          border: none;
          flex-direction: row-reverse;
          font-family: "RobotoRegular", sans-serif;
          color: #808080;
          text-align: left;
        }
        .selectType div + .MuiTooltip-popper .dropdownSelect,
        .selectResponsible div + .MuiTooltip-popper .dropdownSelect {
          max-height: 200px !important;
      }
      .selectArea div + .MuiTooltip-popper .dropdownSelect {
        max-height: 150px !important;
    }
      

        .buttonCompanyUsers {
          margin: 0px 0px 0px 16px;
        }
      }

      // container preview de imagenes
      .container-imgPreview {
        min-height: 120px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-items: flex-start;
        align-items: stretch;
        column-gap: 18px;
        row-gap: 10px;
        border-radius: 5px;
        background-color: #f4f4f7;

        .buttonChooser {
          height: 75px;
          width: 75px;
          .button {
            height: 100%;
            font-size: 9px;
            padding: 5px 12px;
          }
          .icon {
            font-size: 22px;
          }
        }

        .containerItem-img {
          position: relative;
          height: 75px;
          width: 75px;
          display: grid;
          justify-items: flex-start;
          border-radius: inherit;
          background-color: inherit;

          .containerImg {
            border-radius: inherit;
            width: 100%;
            height: 100%;
            background-color: white;
            z-index: 10;
          }

          .container-delete {
            position: absolute;
            border-radius: inherit;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            display: grid;
            place-items: center;
            background-color: inherit;
            z-index: 11;
            opacity: 0;
            transition: all 0.3s;
            background-color: rgba(0, 0, 0, 0.35);
            overflow: hidden;

            .btn-delete .button {
              border: none;
              padding: 4px 8px;
              color: #262626 !important;
              .icon {
                font-size: 8px;
              }
            }
          }

          &:hover {
            .container-delete {
              opacity: 1;
            }
          }
        }

        > * .tooltip-containerItemImg {
          max-width: 150px;
        }
      }
    }

    .container-buttons {
      justify-content: flex-end;
    }
  }
`;
