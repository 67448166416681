import { Loader } from "./styles";
import { Loading } from "../Loading";

export const LoaderPopup = (props) => {
  return (
    <Loader>
      <div className={"modal-loader"}>
        <p>{props.titleLoader}</p>
        <Loading />
      </div>
    </Loader>
  );
};
