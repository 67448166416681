import { Container } from "./styles";
import { useState, useEffect } from "react";
import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";
import { fetchGET, fetchPOST } from "../../utils/handle_http";
import {
  ButtonV2,
  CustomIcon,
  SelectV2,
  Image as CustomImage,
  Tooltip,
} from "contentoh-components-library";
import {
  faNoteSticky as IconTitle,
  faImage as IconImage,
} from "@fortawesome/free-regular-svg-icons";
import {
  faTrash as IconDelete,
  faImage as IconImageFill,
} from "@fortawesome/free-solid-svg-icons";
import { getSession } from "../../utils/session";
import {
  diffObjectValues,
  isArrayEmpty,
  isStringEmpty,
  isValidNaturalNumber,
} from "../../utils/validations";
import {
  getBase64,
  getDataBase64,
  getDataBuffer,
  getFileExtension,
  getImageSize,
} from "../../utils/files";
import useScreenSizes from "../../hooks/useScreenSizes";

const S3_BUCKET = process.env.REACT_APP_IMAGES_BUCKET;
const REGION = "us-east-1";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_KUTS3,
  secretAccessKey: process.env.REACT_APP_AKUTS3,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

export default function ModalAddTicket(props) {
  const {
    assignableUsers, // []
    companies, // []
    onNewAlert, // (title , message) => {}
    onClickCancel, // (event) => {}
    onNewTicket /* (
      ticket: { ... }
      subscription: { ... }
      users: { ... }
      company: { .. }
    ) => {}
    */,
  } = props;
  const [inputTitle, setInputTitle] = useState("");
  const [inputDescription, setInputDescription] = useState("");
  const [focusInputTitle, setFocusInputTitle] = useState(false);
  const [itemsResponsibleSelect, setItemsResponsibleSelect] = useState([]);
  const {isDesktop} = useScreenSizes();
  const [itemsUrgencySelect] = useState([
    { label: "Urgencia alta", value: "HIGH" },
    { label: "Urgencia media", value: "MEDIUM" },
    { label: "Urgencia baja", value: "LOW" },
  ]);
  const [itemsTypeSelect] = useState([
    { label: "Plataforma Content-oh", value: "ERROR" },
    { label: "Soporte/Mantenimiento", value: "SUPPORT" },
  ]);
  const [itemsOriginSelect, setItemsOriginSelect] = useState([
   ]);

  const [itemsAreaSelect] = useState([
    { label: "Administración y finanzas", value: "Administración y finanza" },
    { label: "Corporate affairs & innovation", value: "Corporate affairs & innovation" },
    { label: "Cuentas corporativas", value: "Cuentas corporativas" },
    { label: "Desarrollo del negocio", value: "Desarrollo del negocio" },
    { label: "Growth and operations", value: "Growth and operations" },
    { label: "People management", value: "People management" },
    { label: "Producción - Contenidos Premium", value:"Producción - Contenidos Premium"},
    { label: "Producción e Inteligencia de contenidos", value: "Producción e Inteligencia de contenidos" },
    { label: "SACS y soporte a operación", value: "SACS y soporte a operación"},
    { label: "Tecnologías de la Información", value: "Tecnologías de la Información" },
  ]);

  const [itemsCompanySelect, setItemsCompanySelect] = useState([]);
  const [itemsUserSelect, setItemsUserSelect] = useState([]);

  const [selectedResponsible, setSelectedResponsible] = useState({});
  const [selectedUrgency, setSelectedUrgency] = useState({});
  const [selectedCompany, setSelectedCompany] = useState({});
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedType, setSelectedType] = useState({});
  const [selectedOrigin, setSelectedOrigin] = useState({});
  const [selectedArea, setSelectedArea] = useState({});

  const [companyUsersList, setCompanyUsersList] = useState({});
  const [loadingCompanyUsers, setLoadingCompanyUsers] = useState(false);
  const [disabledSaveButton, setDisabledSaveButton] = useState(true);
  const [loadingSaveButton, setLoadingSaveButton] = useState(false);

  const [imgsAddTicket, setImgsAddTicket] = useState([]);
  const [jsxImgsAddTicket, setJsxImgsAddTicket] = useState([]);
  const [session] = useState(getSession());

  useEffect(() => {
    if (Array.isArray(companies)) {
      let items = companies.map((company) => ({
        label: company.name,
        value: company.id,
      }));
      setItemsCompanySelect(items);
    }
  }, [companies]);

  useEffect(() => {
    if (Array.isArray(assignableUsers)) {
      let items = assignableUsers.map((user) => ({
        label: user.name,
        value: user.id,
      }));
      setItemsResponsibleSelect(items);
    }
  }, [assignableUsers]);

  useEffect(() => {
    const arrayCompany = Object.keys(selectedCompany || {});
    if (!arrayCompany.length) {
      setItemsUserSelect([]);
      return;
    }
    // cuando ya se tienen los users de la company
    let companyUsers = companyUsersList[arrayCompany[0]];
    if (companyUsers) {
      setItemsUserSelect(companyUsers);
      return;
    }
    // cuando los users de la company no existen
    setLoadingCompanyUsers(true);
    getCompanyUsers(arrayCompany[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany]);

  useEffect(() => {
    let disabled = false;
    // validar titulo
    if (isStringEmpty(inputTitle)) disabled = true;
    // validar descripcion
    if (isStringEmpty(inputDescription)) disabled = true;
    if (!Object.keys(selectedType).length) disabled = true;
    if (!Object.keys(selectedOrigin).length) disabled = true;
    if (!Object.keys(selectedArea).length) disabled = true;
    if (session?.isUserAdminTech) {
      // validar usuario asignado
      if (!Object.keys(selectedResponsible).length) disabled = true;
      // validar urgencia
      if (!Object.keys(selectedUrgency).length) disabled = true;
      // validar compañia
      if (!Object.keys(selectedCompany).length) disabled = true;
      // validar usuario
      if (!Object.keys(selectedUser).length) disabled = true;
    }
    setDisabledSaveButton(disabled);
  }, [
    inputTitle,
    inputDescription,
    selectedResponsible,
    selectedUrgency,
    selectedCompany,
    selectedUser,
    selectedType,
    session,
    selectedOrigin,
    selectedArea,
  ]);

  // cada que cambien las imgs seleccionadas del modal add ticket
  useEffect(() => {
    //console.log("imgsFinishTicket: ", imgsFinishTicket.slice());
    renderImgsAddTicket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgsAddTicket]);

  const getCompanyUsers = async (companyId) => {
    const paramsQuery = {
      id: JSON.stringify(companyId),
    };
    const paramsHeaders = {
      Authorization: sessionStorage.getItem("jwt"),
    };
    const response = await fetchGET(
      process.env.REACT_APP_COMPANY_USERS_ENDPOINT,
      paramsQuery,
      paramsHeaders
    );
    // cuando hay error
    if (!response.body) {
      if (onNewAlert) {
        onNewAlert(response.message, response.errorDetail, "info");
      }
      setItemsUserSelect([]);
      setLoadingCompanyUsers(false);
      return;
    }
    let items = [];
    if (!isArrayEmpty(response.body.users)) {
      items = response.body.users.map((user) => ({
        label: user.name,
        value: user.id,
      }));
    }
    items.sort((itemA, itemB) => {
      return itemA.label.toLowerCase() < itemB.label.toLowerCase() ? -1 : 1;
    });
    //console.log("items:", items);
    setCompanyUsersList((prev) => ({
      ...prev,
      [companyId]: items,
    }));
    setItemsUserSelect(items);
    setLoadingCompanyUsers(false);
  };

  const deleteImgAddTicket = async (index) => {
    let newImgs = imgsAddTicket.slice();
    newImgs.splice(index, 1);
    setImgsAddTicket(newImgs);
  };

  const sendImagesAWS = async (id) => {
    const images = imgsAddTicket.slice();
    // subir archivos a AWS
    const customImages = [];
    try {
      const imgUploadRequests = [];
      for (const img of images) {
        let errorMessage;
        // obtener base64
        const fullBase64 = await getBase64(img);
        if (!fullBase64) {
          errorMessage = "No se pudo decodificar la imagen";
        }
        // obtener buffer
        let dataBuffer;
        if (!errorMessage)
          dataBuffer = getDataBuffer(getDataBase64(fullBase64));
        if (!dataBuffer) {
          errorMessage = "No se pudo decodificar la imagen";
        }
        // obtener la key
        let imgKey = "chat/ticket/";
        if (isValidNaturalNumber(id)) imgKey += `${id}/`;
        else errorMessage = "El ID del ticket no es valido";
        if (!errorMessage) {
          const today = new Date();
          imgKey += `${uuidv4()}-${today.getTime()}.${getFileExtension(
            img.name
          )}`;
        } else {
          imgKey = undefined;
        }
        //console.log("imgKey: ", imgKey);
        // enviar file a AWS
        customImages.push({ key: imgKey, errorOwn: errorMessage });
        const paramsCreate = {
          ACL: "public-read",
          Body: dataBuffer,
          Bucket: S3_BUCKET,
          Key: imgKey,
        };
        imgUploadRequests.push(myBucket.putObject(paramsCreate).promise());
      }
      const responseAWS = await Promise.allSettled(imgUploadRequests);
      //console.log("responseAWS:", responseAWS);
      responseAWS.forEach((responseImg, index) => {
        if (responseImg.status === "rejected") {
          customImages[index].errorAWS = responseImg.reason.message;
          customImages[index].uploaded = false;
        } else {
          customImages[index].uploaded = true;
        }
      });
      //console.log("customImages:", customImages);
    } catch (err) {
      return {
        error: true,
        message: "hubo un problema al guardar las imagenes",
        errorDetail: `${err.message}. Reporta esto a TI`,
      };
    }
    // obtener las images que se van a subir a la BD
    const items = [];
    const failedImages = [];
    let errorDetailImgs = "";
    for (let index = 0; index < customImages.length; index++) {
      const img = customImages[index];
      // imagen subida a AWS
      if (img.uploaded) {
        const imgSize = await getImageSize(images[index]);
        if (imgSize) {
          const item = {
            key: img.key,
            name: images[index].name,
            width: imgSize.width,
            height: imgSize.height,
          };
          items.push({ type: "img", value: JSON.stringify(item) });
        }
        // cuando fallo la obtencion de dimension image
        else {
          if (!failedImages.length) {
            errorDetailImgs = `${images[index].name}:
            Error: No se pudieron obtener las dimensiones`;
          } else {
            errorDetailImgs += `${"\n\n"}${images[index].name}:
            Error: No se pudieron obtener las dimensiones`;
          }
          failedImages.push(images[index]);
        }
      }
      // imagen NO subida a AWS
      else {
        if (!failedImages.length) {
          errorDetailImgs = `${images[index].name}:
          Error: ${img.errorOwn ? img.errorOwn : img.errorAWS}`;
        } else {
          errorDetailImgs += `${"\n\n"}${images[index].name}:
          Error: ${img.errorOwn ? img.errorOwn : img.errorAWS}`;
        }
        failedImages.push(images[index]);
      }
    }
    return {
      error: false,
      items,
      failedImages,
      errorDetailImgs,
    };
  };

  const onClickSaveButton = async () => {
    setLoadingSaveButton(true);
    // add el nuevo ticket en la BD
    const arrayUrgency = Object.keys(selectedUrgency || {});
    const arrayType = Object.keys(selectedType || {});
    const arrayUser = Object.keys(selectedUser || {});
    const arrayOrigin = Object.keys(selectedOrigin || {});
    const arrayArea = Object.keys(selectedArea || {});

    const arrayResponsible = Object.keys(selectedResponsible || {});
    const urgency = arrayUrgency.length ? arrayUrgency[0] : undefined;
    const type = arrayType.length ? arrayType[0] : undefined;
    const origin = arrayOrigin.length ? arrayOrigin[0] : undefined;
    const area = arrayArea.length ? arrayArea[0] : undefined;

    let ownerUserId = arrayUser.length ? Number(arrayUser[0]) : undefined;
    let assignedUserId = arrayResponsible.length
      ? Number(arrayResponsible[0])
      : undefined;

    const paramsBody = {
      title: inputTitle.trim(),
      description: inputDescription.trim(),
      urgency,
      ownerUserId: JSON.stringify(ownerUserId),
      assignedUserId: JSON.stringify(assignedUserId),
      type: type,
      origin: origin,
      area: area,
    };
    const paramsHeaders = {
      Authorization: sessionStorage.getItem("jwt"),
    };
    const response = await fetchPOST(
      process.env.REACT_APP_TICKET_ENDPOINT,
      paramsBody,
      paramsHeaders
    );
    // cuando hay error
    if (!response.body) {
      onNewAlert(response.message, response.errorDetail, "info");
      setLoadingSaveButton(false);
      return;
    }
    // success

    // add imgs al AWS
    let imgs = [];
    let failedImages = { images: [], errorDetail: "" };
    if (imgsAddTicket.length) {
      const responseAWS = await sendImagesAWS(response.body.ticket?.id);
      if (responseAWS.error) {
        onNewAlert(responseAWS.message, responseAWS.errorDetail, "info");
      } else {
        imgs = responseAWS.items;
        failedImages = {
          images: responseAWS.failedImages,
          errorDetail: responseAWS.errorDetailImgs,
        };
        if (failedImages.images.length) {
          onNewAlert(
            "No fue posible subir las imagenes al chat. Por favor intente " +
              "agregarlas desde el chat del ticket",
            failedImages.errorDetail,
            "info"
          );
        } else {
          // add imgs al chat
          const paramsBody = {
            id: JSON.stringify(response.body.ticket?.id),
            userId: JSON.stringify(response.body.ticket?.createdByUserId),
            items: JSON.stringify(imgs),
          };
          const responseImgs = await fetchPOST(
            process.env.REACT_APP_TICKETS_CHAT_ENDPOINT,
            paramsBody
          );
          // cuando hay error
          if (!responseImgs.body) {
            onNewAlert(responseImgs.message, responseImgs.errorDetail, "info");
          } else {
            onNewAlert("Ticket agregado", "", "success");
          }
        }
      }
    }
    // cuando no hay imgs que agregar
    else {
      onNewAlert("Ticket agregado", "", "success");
    }
    onNewTicket(
      response.body.ticket,
      response.body.subscription,
      response.body.users,
      response.body.company
    );
  };

  // selector de imagenes del modal finish ticket
  const openChooserAddTicket = () => {
    const selectedImgs = [];
    let rejectedFiles = "";
    const chooser = document.createElement("input");
    chooser.type = "file";
    chooser.style.display = "none";
    chooser.multiple = true;
    chooser.accept = "image/*";
    chooser.onchange = (event) => {
      //console.log("files selected:", chooser.files);
      for (const file of chooser.files) {
        //console.log("type file:", file.type);
        if (file.type.split("/")[0] === "image") {
          selectedImgs.push(file);
        } else {
          rejectedFiles += "\n" + file.name;
        }
      }
      // archivos rechazados ??
      if (!isStringEmpty(rejectedFiles)) {
        onNewAlert(
          "Archivos incorrectos",
          "Los siguientes archivos no fueron agregados al preview " +
            "porque no son un tipo de imagen valido:" +
            rejectedFiles
        );
      }
      // archivos por add ??
      if (selectedImgs.length) {
        let newImgs = imgsAddTicket.slice();
        newImgs = newImgs.concat(selectedImgs);
        setImgsAddTicket(newImgs);
      }
    };
    document.body.appendChild(chooser);
    chooser.click();
    document.body.removeChild(chooser);
  };

  // renderizar las imagenes del preview en el modal add ticket
  const renderImgsAddTicket = async () => {
    const jsxImgs = [];
    for (let index = 0; index < imgsAddTicket.length; index++) {
      const img = imgsAddTicket[index];
      const base64 = await getBase64(img);
      jsxImgs.push(
        <Tooltip
          key={"imgAdd-" + index}
          className={"containerItem-img"}
          classNameTooltip="tooltip-containerItemImg"
          position={"rightCenter"}
          addArrow={false}
          transitionType={"zoom"}
          followCursor={false}
          componentTooltip={img.name}
        >
          {/* imagen preview */}
          <CustomImage
            className={"containerImg"}
            sizeLoading={30}
            colorLoading={undefined}
            src={base64}
            componentDefault={
              <CustomIcon
                className="icon-img"
                size={50}
                icon={IconImageFill}
                type={"pink"}
                transparent
              />
            }
          />
          {/* container boton delete */}
          <div className="container-delete">
            <ButtonV2
              className="btn-delete"
              type={"white"}
              label={"Eliminar"}
              size={8}
              borderType={"oval"}
              icon={IconDelete}
              isLoading={undefined}
              onClick={async (event) => deleteImgAddTicket(index)}
            />
          </div>
        </Tooltip>
      );
    }
    setJsxImgsAddTicket(jsxImgs);
  };

  const handleSelectChange = (event) => {
    const selectedValue = event;
    if (selectedValue === "ERROR") {
      setItemsOriginSelect([
        { label: "1. Cambio de estatus.", value: "Cambio de estatus" },
        { label: "2. Eliminar productos. ", value: "Eliminar productos" },
        { label: "3. Cambio de plan. ", value: "Cambio de plan" },
        {
          label: "4. Problemas en la edición de productos. ",
          value: "Problemas en la edición de productos",
        },
        { label: "5. Cambio de cadena. ", value: "Cambio de cadena" },
        { label: "6. Cambio de SKU.", value: "Cambio de SKU" },
        {
          label: "7. Problemas con exportables.",
          value: "Problemas con exportables",
        },
        { label: "8. Cambio de categoría.", value: "Cambio de categoría" },
        {
          label: "9. Problemas al iniciar sesión.",
          value: "Problemas al iniciar sesión",
        },
        { label: "10. Creación de Ots.", value: "reación de Ots" },
        {
          label: "11. Problemas con los reportes.",
          value: "Problemas con los reportes",
        },
        {
          label: "12. Errores/solicitudes en sábana de datos.",
          value: "Errores/solicitudes en sábana de datos",
        },
        {
          label: "13. Cambio Productos.", value: "Cambio Productos"
        },
        { label: "14. Registro de cuentas financiadas.", value: "Registro de cuentas financiadas" },
        { label: "15. Aumento de plan.", value: "Aumento de plan" },
        { label: "16. Vinculación de cuenta.", value: "Vinculación de cuenta" },
        { label: "17. Otro.", value: " Otro" }
      ]);
    } else if (selectedValue === "SUPPORT") {
      setItemsOriginSelect([
        { label: "1. Problemas de equipo.", value: "Problemas de equipo" },
        { label: "2. Paquetería Office.", value: "Paquetería Office" },
        { label: "3. Paquetería Adobe.", value: "Paquetería Adobe" },
        {
          label: "4. Correos y cuentas Gmail.",
          value: "Correos y cuentas Gmail",
        },
        {
          label: "5. Infraestructura de oficina.",
          value: "Infraestructura de oficina",
        },
      ]);
    } else {
      setItemsOriginSelect([]);
    }
  };

  return (
    <>
      <Container
        className="modalAddTicket"
        show={props.show === true}
        title="Añadir ticket"
        customComponent={
          <>
            {/* input titulo */}
            <div
              className={
                "container-inputTitle" + (focusInputTitle ? " active" : "")
              }
            >
              <CustomIcon
                className="icon-title"
                type={"whiteS2"}
                size={13}
                transparent
                icon={IconTitle}
              />
              <input
                className="inputTitle"
                placeholder="Título de ticket"
                value={inputTitle}
                onChange={(event) => {
                  setInputTitle(event.target.value);
                }}
                onFocus={(event) => setFocusInputTitle(true)}
                onBlur={(event) => setFocusInputTitle(false)}
              />
            </div>

            {/* input descripcion */}
            <textarea
              className="inputDescription"
              placeholder="Describe tu problema"
              rows={30}
              value={inputDescription}
              onChange={(event) => {
                setInputDescription(event.target.value);
              }}
            />
           
           <div className="container-selects" style={{ flexDirection: isDesktop ? "row" : "column" }}>
            <div className="container-col" style={{ minWidth: session?.isUserTech ? "45%" : "100%" }}>
              <div className="container-data">
                {/* select de tipo */}
                <label className="label-title">Tipo &nbsp; </label>
                <SelectV2
                  items={itemsTypeSelect}
                  triggerType="click"
                  selectButton={{
                    className: "btnSelectType",
                    type: "whiteS3",
                    label: "Elige un tipo de ticket",
                    size: 8,
                  }}
                  typeSelectItems="marginCheckbox"
                  maxHeightDropdown="40vh"
                  alignmentItemsOverflow="rows"
                  classNameSelect="selectType"
                  classNameDropdown="dropdownSelectType"
                  onChange={(selectedItems) => {
                    const originOptions = Object.keys(selectedItems);
                    handleSelectChange(originOptions[0]);
                    if (diffObjectValues(selectedItems, selectedType)) {
                      setSelectedType(selectedItems);
                    }
                  }}
                />
              </div>

              <div className="container-data">
                {/* select origen problema */}
                <label className="label-title">Origen</label>
                <SelectV2
                  items={itemsOriginSelect}
                  triggerType="click"
                  selectButton={{
                    className: "btnSelectType",
                    type: "whiteS3",
                    label: "Elige origen del problema",
                    size: 8,
                  }}
                  typeSelectItems="marginCheckbox"
                  maxHeightDropdown="40vh"
                  alignmentItemsOverflow="rows"
                  classNameSelect="selectType"
                  classNameDropdown="dropdownSelectType"
                  onChange={(selectedItems) => {
                    if (diffObjectValues(selectedItems, selectedOrigin)) {
                      setSelectedOrigin(selectedItems);
                    }
                  }}
                />
              </div>
              <div className="container-data">
                {/* select de tipo */}
                <label className="label-title">Área:</label>
                <SelectV2
                  items={itemsAreaSelect}
                  triggerType="click"
                  selectButton={{
                    className: "btnSelectArea",
                    type: "whiteS3",
                    label: "Selecciona tu area",
                    size: 8,
                  }}
                  typeSelectItems="marginCheckbox"
                  maxHeightDropdown="50vh"
                  alignmentItemsOverflow="rows"
                  classNameSelect="selectArea"
                  classNameDropdown="dropdownSelectType"
                  onChange={(selectedItems) => {
                    if (diffObjectValues(selectedItems, selectedArea)) {
                      setSelectedArea(selectedItems);
                    }
                  }}
                />
              </div>
            </div>
            {/* data extra para TECH */}
            {session?.isUserTech && (
              <div className="container-col" style={{ minWidth: session?.isUserTech ? "50%" : "100%" }}>
                {/* select de responsable */}
                <div className="container-data">
                <label className="label-title">Responsable</label>
                <SelectV2
                  items={itemsResponsibleSelect}
                  triggerType="click"
                  selectButton={{
                    className: "btnSelectResponsible",
                    type: "whiteS3",
                    label: "Elige un responsable",
                    size: 8,
                  }}
                  inputSearch={{
                    className: "inputSearchResponsible",
                    defaultText: "Buscar responsable",
                  }}
                  typeSelectItems={"marginCheckbox"}
                  maxHeightDropdown={"50vh"}
                  alignmentItemsOverflow={"rows"}
                  classNameSelect="selectResponsible"
                  classNameDropdown="dropdownSelectResponsible"
                  onChange={(selectedItems) => {
                    if (diffObjectValues(selectedItems, selectedResponsible)) {
                      setSelectedResponsible(selectedItems);
                    }
                  }}
                />
                </div>

                {/* select de urgencia */}
                <div className="container-data">
                <label className="label-title">Urgencia</label>
                <SelectV2
                  items={itemsUrgencySelect}
                  triggerType="click"
                  selectButton={{
                    className: "btnSelectUrgency",
                    type: "whiteS3",
                    label: "Elige una urgencia",
                    size: 8,
                  }}
                  typeSelectItems={"marginCheckbox"}
                  maxHeightDropdown={"50vh"}
                  alignmentItemsOverflow={"rows"}
                  classNameSelect="selectUrgency"
                  classNameDropdown="dropdownSelectUrgency"
                  onChange={(selectedItems) => {
                    if (diffObjectValues(selectedItems, selectedUrgency)) {
                      setSelectedUrgency(selectedItems);
                    }
                  }}
                />
                </div>

                {/* select de empresa */}
                {/* <div className="container-data">
                <label className="label-title">Empresa</label>
                <SelectV2
                  items={itemsCompanySelect}
                  triggerType="click"
                  selectButton={{
                    className: "btnSelectCompany",
                    type: "whiteS3",
                    label: "Elige una empresa",
                    size: 8,
                  }}
                  inputSearch={{
                    className: "inputSearchCompany",
                    defaultText: "Buscar empresa",
                  }}
                  typeSelectItems={"marginCheckbox"}
                  maxHeightDropdown={"50vh"}
                  alignmentItemsOverflow={"rows"}
                  classNameSelect="selectCompany"
                  classNameDropdown="dropdownSelectCompany"
                  onChange={(selectedItems) => {
                    if (diffObjectValues(selectedItems, selectedCompany)) {
                      setSelectedCompany(selectedItems);
                    }
                  }}
                />
                </div> */}

                {/* select de user */}
                {/* <div className="container-data">
                <label className="label-title">Solicitante</label>
                {loadingCompanyUsers ? (
                  <ButtonV2
                    className="buttonCompanyUsers"
                    type="pink"
                    transparent
                    size={14}
                    isLoading={loadingCompanyUsers}
                    borderType="circle"
                  />
                ) : (
                  <SelectV2
                    items={itemsUserSelect}
                    triggerType="click"
                    selectButton={{
                      className: "btnSelectUser",
                      type: "whiteS3",
                      label: "Elige un usuario",
                      size: 8,
                    }}
                    inputSearch={{
                      className: "inputSearchUser",
                      defaultText: "Buscar usuario",
                    }}
                    typeSelectItems={"marginCheckbox"}
                    maxHeightDropdown={"50vh"}
                    alignmentItemsOverflow={"rows"}
                    classNameSelect="selectUser"
                    classNameDropdown="dropdownSelectUser"
                    onChange={(selectedItems) => {
                      if (diffObjectValues(selectedItems, selectedUser)) {
                        setSelectedUser(selectedItems);
                      }
                    }}
                  />
                )}
                </div> */}
              </div>
            )}
          
          </div> 

            {/* contenedor de imagenes */}
            <div className="container-imgPreview" style={{ padding: isDesktop ? "20px" : "10px" }}>
              {/* boton selector */}
              <ButtonV2
                className="buttonChooser"
                type="white"
                label="Añadir foto"
                borderType="rectangle"
                icon={IconImage}
                iconPosition="top"
                onClick={(event) => openChooserAddTicket()}
              />
              {/* lista de imagenes preview */}
              {jsxImgsAddTicket}
            </div>
          </>
        }
        buttons={[
          <ButtonV2
            key={"modal-buttonCancel"}
            className="modal-buttonCancel"
            type="white"
            label="Cancelar"
            borderType="oval"
            onClick={(event) => {
              onClickCancel(event);
            }}
          />,
          <ButtonV2
            key={"modal-buttonSignIn"}
            className="modal-buttonSignIn"
            type="pink"
            label="Guardar"
            borderType="oval"
            disabled={disabledSaveButton}
            isLoading={loadingSaveButton}
            onClick={onClickSaveButton}
          />,
        ]}
      />
    </>
  );
}
