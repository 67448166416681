import { Auth } from "aws-amplify";
import { isUserAdminTech, isUserTech as is_user_tech } from "./handle_userTech";
import {
  decodeJSON,
  isObject,
  isStringEmpty,
  isValidNaturalNumber,
} from "./validations";

export const getSession = () => {
  const user = decodeJSON(sessionStorage.getItem("user"));
  const token = sessionStorage.getItem("jwt");
  if (isObject(user)) {
    const {
      companyId,
      isUserTech,
      name,
      userId,
    } = user;
    return {
      id: isValidNaturalNumber(userId) ? userId : undefined,
      companyId: isValidNaturalNumber(companyId) ? companyId : undefined,
      name: !isStringEmpty(name) ? name : undefined,
      isUserAdminTech: isUserAdminTech(isUserTech),
      isUserTech: is_user_tech(isUserTech),
      tokenJWT: !isStringEmpty(token) ? token : undefined,
    };
  } else {
    return undefined;
  }
};

export const isAuth = async () => {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch (err) {
    return false;
  }
};
