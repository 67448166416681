import { Base64 } from "js-base64";

/*====================================================================
            UTILERIAS PARA EL MANEJO DE ARCHIVOS
======================================================================*/
/*------------------------
devolver el base64 
completo de un file
--------------------------*/
export const getBase64 = async (file) => {
  return new Promise((success) => {
    const reader = new FileReader();
    reader.addEventListener("loadend", () => {
      success(reader.result);
    });
    reader.readAsDataURL(file);
  });
};

/*------------------------
devolver la data de un string base64
--------------------------*/
export const getDataBase64 = (base64Full) => {
  //const base64 = reader.result.replace(/^data:\w+\/\w+.*;base64,/, "");
  try {
    const base64 = base64Full.split("base64,");
    return base64.length ? base64[1] : undefined;
  } catch (err) {
    return undefined;
  }
};

/*------------------------
devolver un data Buffer a partir
de un data base64
--------------------------*/
export const getDataBuffer = (dataBase64) => {
  try {
    const dataBuffer = Buffer.from(dataBase64, "base64");
    return dataBuffer;
  } catch (err) {
    return undefined;
  }
};

/*------------------------
devolver la extension de un file
a partir de un string: "name.ext"
--------------------------*/
export const getFileExtension = (fileName = "") => {
  const nameParts = fileName.split(".");
  let ext = "";
  nameParts.forEach((part, index) => {
    if (index !== 0) {
      if (index + 1 === nameParts.length) ext += part;
      else ext += part + ".";
    }
  });
  return ext === "" ? undefined : ext;
};

/*------------------------
devolver el nombre de un file
a partir de un string: "name.ext"
--------------------------*/
export const getFileName = (fileName = "") => {
  const nameParts = fileName.split(".");
  if (nameParts.length) return nameParts[0];
  return undefined;
};

/*------------------------
obtener la URL de una imagen
con un peso mas pequeño al 
peso original pasando como
parametros:
=> bucket: <string> bucket en donde se ecuentra la img 
=> key: <string> ruta y nombre completa de la img (keyS3)
=> width: <number> largo de la img en px
=> height: <number> altura de la img en px
--------------------------*/
export const getImageURL = (bucket, key, width, height) => {
  try {
    const configAWS = {
      bucket: bucket,
      key: key,
      edits: {
        resize: {
          width: width,
          height: height,
          fit: "contain",
          background: { r: 255, g: 255, b: 255, alpha: 0 },
        },
      },
    };
    let url = "https://d24s337exbt5zr.cloudfront.net/";
    url += Base64.encode(JSON.stringify(configAWS));
    return url;
  } catch (err) {
    console.log(`ERROR al obtener la URL de la imagen (${key})`, err.message);
    return undefined;
  }
};

/*------------------------
obtener la dimension de una imagen 
a partir de un objeto <File>
retorno: {
=> width: <number> largo de la img en px
=> height: <number> altura de la img en px
} 
NOTA regresa <undefined> si no se pudo 
obtener la dimension de la imagen
--------------------------*/
export const getImageSize = (file) => {
  return new Promise(async (response) => {
    const img = new Image();
    img.onload = () => response({ width: img.width, height: img.height });
    img.onerror = () => response(undefined);
    const base64 = await getBase64(file);
    img.src = base64;
  });
};

/*------------------------
obtener una redimension (height) proporcional 
a la dimension original (height) a partir de
un width deseado
retorno:
=> newHeight: <number> altura de la img en px
NOTA regresa <undefined> si no se pudo 
obtener el nuevo <height> de la imagen
--------------------------*/
export const getResizeImgHeight = (originalWidth, originalHeight, newWidth) => {
  /*
  console.log(`==================
  original width: ${originalWidth}
  original height: ${originalHeight}
  newWidth: ${newWidth}`);
  */
  const scale = (newWidth * 100) / originalWidth / 100;
  //console.log("scale:", scale);
  return Math.ceil(originalHeight * scale);
};
