import { Container, NewNotifications } from "./styles";
import { useState, useEffect } from "react";
import { NotificationList } from "./NotificationList";
import { ButtonV2 } from "contentoh-components-library";
import { faBell as notification } from "@fortawesome/free-regular-svg-icons";
import useScreenSizes from "../../hooks/useScreenSizes";

export const Notifications = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const [lastNotificationUnread, setLastNotificationUnread] = useState(-1);
  const [showNewNotificationAlert, setShowNewNotificationAlert] = useState(-1);
  const {isDesktop} = useScreenSizes();
  const closeMenu = (e) => {
    if (!e.target.closest("#select-option") && showMenu) {
      setShowMenu(false);
      document.removeEventListener("click", closeMenu);
    }
  };
  useEffect(() => {
    if (showMenu) {
      document.addEventListener("click", closeMenu);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMenu]);

  useEffect(() => {
    const search = props.arrayNotifications.find(
      (s) => !s.readDate
    )?.notificationId;
    if (search && lastNotificationUnread !== search) {
      if (search > lastNotificationUnread) {
        setLastNotificationUnread(search);
        setShowNewNotificationAlert(search);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.arrayNotifications]);

  return (
    isDesktop && <Container
      newNotifications={props.arrayNotifications.some((s) => !s.readDate)}
    >
      {showNewNotificationAlert > 0 && <NewNotifications />}
      <ButtonV2
        key="notifications-button"
        className="notification-button"
        icon={notification}
        size={16}
        type="whiteS2"
        onClick={(e) => {
          e.stopPropagation();
          setShowMenu(!showMenu);
          setShowNewNotificationAlert(0);
          if (showMenu) {
            document.removeEventListener("click", closeMenu, false);
            setShowMenu(false);
          }
        }}
      />

      {showMenu && (
        <NotificationList
          arrayNotifications={props.arrayNotifications}
          setArrayNotifications={props.setArrayNotifications}
          onClickNotification={props.onClickNotification}
        />
      )}
    </Container>
  );
};
