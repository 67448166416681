import { Container } from "./styles";
import { ButtonV2 } from "contentoh-components-library";
import {
  faPause as IconPause,
  faPlay as IconPlay,
  faCheck as IconFinish,
} from "@fortawesome/free-solid-svg-icons";

export const ButtonPlay = (props) => {
  const {
    status, // (string) "NEW" | "IN_PROCESS" | "COMPLETED"
    statusPlay, // (boolean) true | false | null-undefined
    allDisabled, // boolean
    loadingButton, // "START" | "RESTART" | "PAUSE" | "FINISH" | undefined
    onClick, // (action) => {}   <-> action => "START" | "RESTART" | "PAUSE" | "FINISH"
  } = props;

  return (
    <Container>
      {/* boton iniciar */}
      {status === "NEW" && (
        <ButtonV2
          key={"buttonStart"}
          className="buttonStart"
          size={11}
          type={"purple"}
          transparent
          icon={IconPlay}
          disabled={allDisabled || (loadingButton && loadingButton !== "START")}
          isLoading={loadingButton === "START"}
          borderType="circle"
          onClick={(event) => {
            onClick && onClick("START");
          }}
        />
      )}
      {/* boton pausar */}
      {status === "IN_PROCESS" && statusPlay === true && (
        <ButtonV2
          key={"buttonPause"}
          className="buttonPause"
          size={11}
          type={"purple"}
          transparent
          icon={IconPause}
          disabled={allDisabled || (loadingButton && loadingButton !== "PAUSE")}
          isLoading={loadingButton === "PAUSE"}
          borderType="circle"
          onClick={(event) => {
            onClick && onClick("PAUSE");
          }}
        />
      )}
      {/* boton reinciar */}
      {status === "IN_PROCESS" && statusPlay === false && (
        <ButtonV2
          key={"buttonRestart"}
          className="buttonRestart"
          size={10}
          type={"purple"}
          transparent
          icon={IconPlay}
          disabled={
            allDisabled || (loadingButton && loadingButton !== "RESTART")
          }
          isLoading={loadingButton === "RESTART"}
          borderType="circle"
          onClick={(event) => {
            onClick && onClick("RESTART");
          }}
        />
      )}
      {/* boton finalizar */}
      {status === "IN_PROCESS" && statusPlay === true && (
        <ButtonV2
          key={"buttonFinish"}
          className="buttonFinish"
          size={11}
          type={"purple"}
          transparent
          icon={IconFinish}
          disabled={
            allDisabled || (loadingButton && loadingButton !== "FINISH")
          }
          isLoading={loadingButton === "FINISH"}
          borderType="circle"
          onClick={(event) => {
            onClick && onClick("FINISH");
          }}
        />
      )}
    </Container>
  );
};
