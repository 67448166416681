import styled from "styled-components";

export const Container = styled.div`
  flex-grow: 2;
  display: flex;
  flex-direction: row;
  gap: 7px;
  justify-content: flex-start;
  align-items: center;

  .buttonStart,
  .buttonRestart,
  .buttonPause,
  .buttonFinish {
    width: 20px;
    height: 20px;
    min-width: 20px;
    .button {
      width: 100%;
      height: 100%;
      padding: 0px;
    }
  }
  .buttonFinish .button {
    background-color: #8a6caa;
    color: white !important;
    &:hover {
      background-color: #61467e;
      border-color: #61467e;
      color: white;
    }
  }
  .buttonStart,
  .buttonRestart {
    .icon {
      padding: 0px 0px 0px 2px;
    }
  }
`;
