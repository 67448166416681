/*====================================================================
            UTILERIAS PARA EL MANEJO DE VALIDACIONES
======================================================================*/
/*------------------------
recibe un number o string
y determina si es numero natural
0,1,2,...
--------------------------*/
export const isValidNaturalNumber = (number) => {
  if ([undefined, null, ""].includes(number)) return false;
  if (!["string", "number"].includes(typeof number)) return false;
  try {
    const numberString = number + "";
    for (let i = 0; i < numberString.length; i++) {
      if ("0123456789".indexOf(numberString.charAt(i)) === -1) return false;
    }
    return true;
  } catch (err) {
    return false;
  }
};

/*------------------------
recibe un array y determina si 
el array esta vacio []
--------------------------*/
export const isArrayEmpty = (array) => {
  if (Array.isArray(array) && array.length) return false;
  return true;
};

/*------------------------
Son objetos con diferentes keys ??
--------------------------*/
export const diffObjectValues = (newValue, currentValue) => {
  if (!isObject(currentValue) || !isObject(newValue)) return false;
  const cv = Object.keys(currentValue);
  const nv = Object.keys(newValue);
  if (cv.length !== nv.length) return true;
  else {
    for (const value of nv) {
      if (currentValue[value] === undefined) return true;
    }
    return false;
  }
};

/*------------------------
recibe un string y determina si 
esta vacio ""
--------------------------*/
export const isStringEmpty = (cad) => {
  if (cad && typeof cad === "string" && cad.trim().length !== 0) return false;
  return true;
};

/*------------------------
recibe un string JSON y lo
decodifica a su valor original
--------------------------*/
export const decodeJSON = (valueJSON) => {
  try {
    const decodeValue = JSON.parse(valueJSON);
    return decodeValue;
  } catch (err) {
    return undefined;
  }
};

/*------------------------
recibe un value y lo codifica 
primero a JSON y luego a URI
--------------------------*/
export const encodeUriJson = (value) => {
  try {
    const encodeValue = encodeURIComponent(JSON.stringify(value));
    return encodeValue;
  } catch (err) {
    return undefined;
  }
};

/*------------------------
recibe un dato y determina si 
es objeto JS
--------------------------*/
export const isObject = (obj) => {
  if (obj && typeof obj === "object" && !Array.isArray(obj)) return true;
  return false;
};
