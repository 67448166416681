import ModalSignIn from "../../components/ModalSignIn";
import { useRouteMatch } from "react-router-dom";

export default function Login() {
  const propsLogin = {
    labelPage: "Por favor inicie sesión para entrar a la plataforma",
    showHeaderButtonSignIn: false,
    title: "",
  };
  if (
    useRouteMatch({
      path: ["/Tickets/:id", "/Tickets"],
      exact: true,
    })
  ) {
    propsLogin.labelPage = "Inicia sesión para ver tickets";
    propsLogin.showHeaderButtonSignIn = false;
    propsLogin.title = "Tickets";
  }
  if (
    useRouteMatch({
      path: ["/Planning/:id", "/Planning"],
      exact: true,
    })
  ) {
    propsLogin.labelPage = "Inicia sesión para ver proyectos en planeación";
    propsLogin.showHeaderButtonSignIn = false;
    propsLogin.title = "Planeación de proyectos";
  }
  if (
    useRouteMatch({
      path: ["/Dashboard"],
      exact: true,
    })
  ) {
    propsLogin.labelPage = "Inicia sesión para ver tu dashboard";
    propsLogin.showHeaderButtonSignIn = false;
    propsLogin.title = "Dashboard";
  }

  return <ModalSignIn show={true} onClickCancel={() => {}} />;
}
