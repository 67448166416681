import axios from "axios";

//============================================================
/**
 *	funcion que realiza un axios GET
 *	
 *	Si <body> es undefined significa que hubo error

 * @returns { Promise<{
 * 	body: {} | undefined , 
 * 	message: string, 
 * 	errorDetail: string
 * }> }
 *  
 */
//============================================================
export const fetchGET = (
  endpoint = "",
  paramsQuery = {},
  paramsHeaders = {}
) => {
  return new Promise(async (response) => {
    //console.log("======= fetchGET =========");
    try {
      let URL = endpoint;
      const keysParamsQuery = Object.keys(paramsQuery);
      // agregar cada param query a la URL
      keysParamsQuery.forEach((key, index) => {
        if (index === 0) {
          URL += `?${key}=${paramsQuery[key]}`;
        } else {
          URL += `&${key}=${paramsQuery[key]}`;
        }
      });
      //console.log("URL:", URL);
      const respHTTPjson = await axios.get(URL, { headers: paramsHeaders });
      // verificar si existe un error en la peticion realizada
      if (respHTTPjson.status !== 200) {
        return response({
          message: "No fue posible llevar a cabo la operación",
          errorDetail: respHTTPjson.statusText,
        });
      }
      // obtener body backend
      const body = JSON.parse(respHTTPjson.data.body);

      // verificar si existe error desde el backend
      if (respHTTPjson.data.statusCode !== 200) {
        return response({
          message: body.message ?? "",
          errorDetail: body.errorDetail ?? "",
        });
      }
      // success
      return response({ body });
    } catch (err) {
      return response({
        message: "Error al obtener los datos",
        errorDetail: err.message,
      });
    }
  });
};

//============================================================
/**
 *	funcion que realiza un axios POST
 *	
 *	Si <body> es undefined significa que hubo error

 * @returns { Promise<{
 * 	body: {} | undefined , 
 * 	message: string, 
 * 	errorDetail: string
 * }> }
 *  
 */
//============================================================
export const fetchPOST = (
  endpoint = "",
  paramsBody = {},
  paramsHeaders = {}
) => {
  return new Promise(async (response) => {
    //console.log("======= fetchPOST =========");
    try {
      const respHTTPjson = await axios.post(endpoint, paramsBody, {
        headers: paramsHeaders,
      });

      // verificar si existe un error en la peticion realizada
      if (respHTTPjson.status !== 200) {
        return response({
          message: "No fue posible llevar a cabo la operación",
          errorDetail: respHTTPjson.statusText,
        });
      }
      // obtener body backend
      const body = JSON.parse(respHTTPjson.data.body);

      // verificar si existe error desde el backend
      if (respHTTPjson.data.statusCode !== 200) {
        return response({
          message: body.message ?? "",
          errorDetail: body.errorDetail ?? "",
        });
      }
      // success
      return response({ body });
    } catch (err) {
      return response({
        message: "Error al guardar los datos",
        errorDetail: err.message,
      });
    }
  });
};

//============================================================
/**
 *	funcion que realiza un axios GET
 *	
 *	Si <body> es undefined significa que hubo error

 * @returns { Promise<{
 * 	body: {} | undefined , 
 * 	message: string, 
 * 	errorDetail: string
 * }> }
 *  
 */
//============================================================
export const fetchPUT = (
  endpoint = "",
  paramsBody = {},
  paramsHeaders = {}
) => {
  return new Promise(async (response) => {
    //console.log("======= fetchPUT =========");
    try {
      const respHTTPjson = await axios.put(endpoint, paramsBody, {
        headers: paramsHeaders,
      });

      // verificar si existe un error en la peticion realizada
      if (respHTTPjson.status !== 200) {
        return response({
          message: "No fue posible llevar a cabo la operación",
          errorDetail: respHTTPjson.statusText,
        });
      }

      // obtener body backend
      const body = JSON.parse(respHTTPjson.data.body);

      // verificar si existe error desde el backend
      if (respHTTPjson.data.statusCode !== 200) {
        return response({
          message: body.message ?? "",
          errorDetail: body.errorDetail ?? "",
        });
      }

      // success
      return response({ body });
    } catch (err) {
      return response({
        message: "Error al actualizar los datos",
        errorDetail: err.message,
      });
    }
  });
};

//============================================================
/**
 *	funcion que realiza un axios GET
 *	
 *	Si <body> es undefined significa que hubo error

 * @returns { Promise<{
 * 	body: {} | undefined , 
 * 	message: string, 
 * 	errorDetail: string
 * }> }
 *  
 */
//============================================================
export const fetchDELETE = (
  endpoint = "",
  paramsBody = {},
  paramsHeaders = {}
) => {
  return new Promise(async (response) => {
    //console.log("======= fetchDELETE =========");
    try {
      const respHTTPjson = await axios.delete(endpoint, {
        data: paramsBody,
        headers: paramsHeaders,
      });

      // verificar si existe un error en la peticion realizada
      if (respHTTPjson.status !== 200) {
        return response({
          message: "No fue posible llevar a cabo la operación",
          errorDetail: respHTTPjson.statusText,
        });
      }

      // obtener body backend
      const body = JSON.parse(respHTTPjson.data.body);

      // verificar si existe error desde el backend
      if (respHTTPjson.data.statusCode !== 200) {
        return response({
          message: body.message ?? "",
          errorDetail: body.errorDetail ?? "",
        });
      }

      // success
      return response({ body });
    } catch (err) {
      return response({
        message: "Error al actualizar los datos",
        errorDetail: err.message,
      });
    }
  });
};
