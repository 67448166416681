import { Modal } from "contentoh-components-library";
import { useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { fetchGET } from "../../utils/handle_http";
import { getSession, isAuth } from "../../utils/session";
import Login from "../Login/App";
import Tickets from "../Tickets/App";
import MyTickets from "../MyTickets/App";
import { NotFound } from "../../pages/NotFound";
// import Dashboard from "../Dashboard/App";
// import { NotPermission } from "../NotPermission";
// import Planning from "../Planning/App";
// import Lanzamientos from "../Lanzamientos/App";

export default function ProtectedPage(props) {
  const [data, setData] = useState({
    assignableUsers: undefined,
    companies: undefined,
  });
  const [modalAlert, setModalAlert] = useState({
    show: false,
    title: "",
    message: "",
  });
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    verifyAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.Auth]);

  const verifyAuth = async () => {
    const isAuthUser = await isAuth();
    // logueado ??
    if (isAuthUser) {
      if (!location.state?.Auth) {
        history.replace({
          pathname: location.pathname,
          state: { Auth: true },
        });
      }
      if (data.assignableUsers === undefined) await getData();
    } else {
      history.replace({
        pathname: location.pathname,
        state: { Auth: false },
      });
    }
  };

  /*-------------------------------------- 
  1- obtiene la lista de todas las compañias (PROVEEDORES, CADENAS)
  2- obtiene la lista de usuarios AS de TECH
  ----------------------------------------*/
  const getData = async () => {
    const paramsHeaders = {
      Authorization: getSession().tokenJWT,
    };
    const response = await fetchGET(
      process.env.REACT_APP_GET_COMPANY_USER_ASSIGN_ENDPOINT,
      {},
      paramsHeaders
    );
    // cuando hay error
    if (!response.body) {
      setModalAlert({
        show: true,
        title:
          "No fue posible obtener la lista de usuarios de tecnología " +
          "y la lista de compañias",
        message: response.errorDetail,
      });
      return;
    }
    // sucess
    const userList =
      response.body.userList?.sort((userA, userB) => {
        return userA.name.toLowerCase() < userB.name.toLowerCase() ? -1 : 1;
      }) ?? [];
    const companyList =
      response.body.companyList?.sort((companyA, companyB) => {
        return companyA.name.toLowerCase() < companyB.name.toLowerCase()
          ? -1
          : 1;
      }) ?? [];
    setData({
      assignableUsers: userList,
      companies: companyList,
    });
  };

  return location.state?.Auth === false ? (
    <Login />
  ) : location.state?.Auth === true ? (
    <>
      <Switch>
        {/* pagina de tickets */}
        <Route exact path={["/Tickets/:id", "/Tickets"]}>
          <Tickets
            assignableUsers={data.assignableUsers}
            companies={data.companies}
          />
        </Route>

        {/* pagina de Mis tickets */}
        <Route exact path={["/MyTickets/:id", "/MyTickets"]}>
          <MyTickets
            assignableUsers={data.assignableUsers}
            companies={data.companies}
          />
        </Route>
        <Route component={NotFound} />
      </Switch>

      {/* modal de alertas */}
      {modalAlert.show && (
        <Modal
          className="modalAlertMain"
          show={modalAlert.show}
          title={modalAlert.title}
          message={modalAlert.message}
          icon={"info"}
          onClickBtnDefault={(event) => {
            setModalAlert({ show: false, title: "", message: "" });
          }}
        />
      )}
    </>
  ) : (
    <Loading />
  );
}
