import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-width: 200px;
  height: 100%;
  padding: 0px 5px 0px 0px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow: auto;

  > .container-fixedCardList,
  > .container-cardList {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 7px;

    > .label-subtitle {
      font-family: "RobotoMedium", sans-serif;
      font-size: 15px;
      color: #808080;
      text-align: left;
      cursor: default;
      line-height: 1.1;
    }

    > .containerList {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    > .button-loadMore {
      align-self: center;
      margin-top: 5px;

      .button {
        font-family: "RobotoRegular", sans-serif;
        padding: 10px 15px 10px 15px;
        border: none;

        .icon {
          font-size: calc(var(--size) + 8px);
        }
      }
    }
  }

  > .container-emptyList {
    width: 100%;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;

    > label {
      font-family: "RobotoMedium", sans-serif;
      font-size: 20px;
      color: #808080;
      text-align: center;
      cursor: default;
      line-height: 1.1;
    }
  }

`;
