import SelectedTicket from "../../components/SelectedTicket";
import { Container } from "./styles";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Searcher } from "../../components/Searcher";
import {
  encodeUriJson,
  isArrayEmpty,
  isObject,
  isStringEmpty,
  isValidNaturalNumber,
} from "../../utils/validations";
import { SelectV2, Modal, Loading } from "contentoh-components-library";
import { fetchGET } from "../../utils/handle_http";
import SubscriptionCardList from "../../components/SubscriptionCardContainer";
import { HeaderPage } from "../../components/HeaderPage";
import { getSession } from "../../utils/session";
import { ButtonReturn } from "../../components/ButtonReturn";
import useScreenSizes from "../../hooks/useScreenSizes";

export default function MyTickets(props) {
  const {
    assignableUsers, // [ {..} ]
    companies, // [ {..} ]
  } = props;
  // data para el manejo del play
  const [dataPlay, setDataPlay] = useState({ isTicket: false, isTask: false });
  const [playTicket, setPlayTicket] = useState();

  // data completa de la lista de tickets
  const [initialTicket, setInitialTicket] = useState();
  const [ticketList, setTicketList] = useState([]);
  const [ticketSubscriptions, setTicketSubscriptions] = useState({});
  const [ticketUserList, setTicketUserList] = useState({});
  const [ticketCompanyList, setTicketCompanyList] = useState({});
  const [loadCount, setLoadCount] = useState();
  const [selectedTicketId, setSelectedTicketId] = useState();
  const {isMobile, isDesktop} = useScreenSizes();
  const [showTicket, setShowTicket] = useState(true);
  const [showChat, setShowChat] = useState(false);

  // opciones de los select de los filtros
  const [statusSelectList] = useState([
    { label: "Pendientes", value: "PENDING" },
    { label: "En proceso", value: "IN_PROGRESS" },
    { label: "Cerrados", value: "COMPLETED" },
  ]);
  const [urgencySelectList] = useState([
    { label: "Urgencia alta", value: "HIGH" },
    { label: "Urgencia media", value: "MEDIUM" },
    { label: "Urgencia baja", value: "LOW" },
  ]);
  const [responsibleSelectList, setResponsibleSelectList] = useState([]);
  const [companySelectList, setCompanySelectList] = useState([]);
  const [subscriptionSelectList] = useState([
    { label: "Suscripciones activas", value: "ACTIVE" },
    { label: "Sin suscripción", value: "NO_ACTIVE" },
  ]);
  // filtro actual
  const [filters, setFilters] = useState({
    name: undefined,
    time: undefined,
    status: undefined,
    urgency: undefined,
    responsible: undefined,
    company: undefined,
    subscription: undefined,
    initialTicketId: undefined,
  });
  const [modalAlert, setModalAlert] = useState({
    show: false,
    title: "",
    message: "",
    icon: undefined,
  });
  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingSelectedTicket] = useState(false);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);
  const [inputSearch, setInputSearch] = useState("");
  const paramsURL = useParams();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (isValidNaturalNumber(getSession()?.id)) {
      const initialFilters = {
        name: "",
        time: {},
        status: {},
        urgency: {},
        responsible: {},
        company: {},
        subscription: {},
        initialTicketId: isValidNaturalNumber(paramsURL.id)
          ? Number(paramsURL.id)
          : null,
      };
      // establecer filtros de busqueda inicial de tickets
      setFilters(initialFilters);
    }
    // cuando no se encontro la info del user conectado
    else {
      setModalAlert({
        show: true,
        title: "No fue posible leer tus datos",
        message:
          "Por favor intente recargar la página o iniciar sesion " +
          "nuevamente. Si el problema persiste informa acerca de este " +
          "error a TI.",
        icon: "info",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Array.isArray(assignableUsers)) {
      setResponsibleSelectList(
        assignableUsers.map((user) => {
          return { label: user.name, value: user.id };
        })
      );
    }
  }, [assignableUsers]);

  useEffect(() => {
    if (Array.isArray(companies)) {
      setCompanySelectList(
        companies.map((company) => {
          return { label: company.name, value: company.id };
        })
      );
    }
  }, [companies]);

  useEffect(() => {
    if (Object.values(filters).includes(undefined)) return;
    setLoadCount(0);
    setLoadingPage(true);
    getTickets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  // cada que cambie el ticket seleccionado
  useEffect(() => {
    if (selectedTicketId === undefined) return;
  }, [selectedTicketId]);

  const updateSubscriptions = (subscription) => {
    let copy = {};
    if (isObject(ticketSubscriptions)) copy = { ...ticketSubscriptions };
    if (Object.keys(subscription || {}).length) {
      copy = { ...copy, ...subscription };
    } else {
      delete copy[selectedTicketId];
    }
    setTicketSubscriptions(copy);
  };

  const getTickets = async () => {
    let users = {};
    let companies = {};
    let subscriptions = {};
    const newTicketList = [];

    // obtener el ticket play
    const responsePlay = await getDataPlay();
    if (responsePlay) {
      users = responsePlay.users;
      companies = responsePlay.company;
      subscriptions = responsePlay.subscription;
    }

    // obtener el ticket inicial
    if (isValidNaturalNumber(filters.initialTicketId)) {
      setSelectedTicketId(filters.initialTicketId);
      if (!responsePlay || responsePlay.id !== filters.initialTicketId) {
        const initialTicket = await getInitialTicket();
        if (initialTicket) {
          newTicketList.push(initialTicket.data);
          users = { ...users, ...initialTicket.users };
          companies = { ...companies, ...initialTicket.company };
          subscriptions = { ...subscriptions, ...initialTicket.subscription };
        }
      }
    } else {
      setInitialTicket();
    }

    // obtener la lista de tickets actuales
    const currentTickets = {};
    const currentTicketsId = {};
    ticketList.forEach((ticket) => {
      currentTickets[ticket.id] = ticket;
      currentTicketsId[ticket.id] = true;
    });

    // obtener la lista de tickets a excluir
    const excludeTicketsId = [];
    if (isValidNaturalNumber(responsePlay?.id)) {
      excludeTicketsId.push(responsePlay.id);
    }
    if (isValidNaturalNumber(filters.initialTicketId)) {
      excludeTicketsId.push(filters.initialTicketId);
    }
    // hacer peticion HTTP
    const paramsQuery = {
      nameFilter: encodeURIComponent(filters.name),
      timeFilter: encodeUriJson(""),
      statusFilter: encodeUriJson(Object.keys(filters.status || {})),
      urgencyFilter: encodeUriJson(Object.keys(filters.urgency || {})),
      responsibleFilter: encodeUriJson(Object.keys(filters.responsible || {})),
      companyFilter: encodeUriJson(Object.keys(filters.company || {})),
      subscriptionFilter: encodeUriJson(
        Object.keys(filters.subscription || {})
      ),
      excludeTicketsId: encodeUriJson(excludeTicketsId),
      loadCount: JSON.stringify(0),
      currentTicketsId: encodeUriJson(currentTicketsId),
      colaboratorFilter: "1",
    };
    const paramsHeaders = {
      Authorization: sessionStorage.getItem("jwt"),
    };

    const response = await fetchGET(
      process.env.REACT_APP_GET_DATA_ALL_TICKETS,
      paramsQuery,
      paramsHeaders
    );
    // cuando hay error
    if (!response.body) {
      setShowLoadMoreButton(false);
      setModalAlert({
        show: true,
        title: response.message,
        message: response.errorDetail,
        icon: "info",
      });
    }
    // cuando obtuve la lista de tickets filtrados
    else {
      // obtener la nueva lista
      if (!isArrayEmpty(response.body.tickets)) {
        response.body.tickets.forEach((ticket) => {
          if (ticket.isNew) newTicketList.push(ticket.data);
          else {
            if (isObject(currentTickets[ticket.data?.id])) {
              newTicketList.push(currentTickets[ticket.data.id]);
            }
          }
        });
      }
      users = { ...users, ...response.body.users };
      companies = { ...companies, ...response.body.companies };
      subscriptions = { ...subscriptions, ...response.body.subscriptions };
      setShowLoadMoreButton(response.body.showLoadMoreButton === true);
    }
    setTicketList(newTicketList);
    setTicketSubscriptions(subscriptions);
    setTicketUserList(users);
    setTicketCompanyList(companies);
    setLoadingPage(false);
  };

  const getMoreTickets = async (loadCount = 0) => {
    const newTicketList = [];
    if (isValidNaturalNumber(initialTicket?.id)) {
      newTicketList.push({ ...initialTicket });
    }

    // obtener la lista de tickets actuales
    const currentTickets = {};
    const currentTicketsId = {};
    ticketList.forEach((ticket) => {
      currentTickets[ticket.id] = ticket;
      currentTicketsId[ticket.id] = true;
    });

    // obtener la lista de tickets a excluir
    const excludeTicketsId = [];
    if (isValidNaturalNumber(playTicket?.id)) {
      excludeTicketsId.push(playTicket.id);
    }
    if (isValidNaturalNumber(filters.initialTicketId)) {
      excludeTicketsId.push(filters.initialTicketId);
    }

    // hacer peticion HTTP
    const paramsQuery = {
      nameFilter: encodeURIComponent(filters.name),
      timeFilter: encodeUriJson(""),
      statusFilter: encodeUriJson(Object.keys(filters.status || {})),
      urgencyFilter: encodeUriJson(Object.keys(filters.urgency || {})),
      responsibleFilter: encodeUriJson(Object.keys(filters.responsible || {})),
      companyFilter: encodeUriJson(Object.keys(filters.company || {})),
      subscriptionFilter: encodeUriJson(
        Object.keys(filters.subscription || {})
      ),
      excludeTicketsId: encodeUriJson(excludeTicketsId),
      loadCount: JSON.stringify(loadCount),
      currentTicketsId: encodeUriJson(currentTicketsId),
      colaboratorFilter: "1",
    };
    const paramsHeaders = {
      Authorization: sessionStorage.getItem("jwt"),
    };
    const response = await fetchGET(
      process.env.REACT_APP_GET_DATA_ALL_TICKETS,
      paramsQuery,
      paramsHeaders
    );
    // cuando hay error
    if (!response.body) {
      setModalAlert({
        show: true,
        title: response.message,
        message: response.errorDetail,
        icon: "info",
      });
      return;
    }
    // cuando obtuve la lista de tickets filtrados

    // obtener la nueva lista
    if (!isArrayEmpty(response.body.tickets)) {
      response.body.tickets.forEach((ticket) => {
        if (ticket.isNew) newTicketList.push(ticket.data);
        else {
          if (isObject(currentTickets[ticket.data?.id])) {
            newTicketList.push(currentTickets[ticket.data.id]);
          }
        }
      });
    }

    setTicketList(newTicketList);
    setTicketSubscriptions({
      ...ticketSubscriptions,
      ...response.body.subscriptions,
    });
    setTicketUserList({
      ...ticketUserList,
      ...response.body.users,
    });
    setTicketCompanyList({
      ...ticketCompanyList,
      ...response.body.companies,
    });
    setShowLoadMoreButton(response.body.showLoadMoreButton === true);
  };

  const getDataPlay = async () => {
    const paramsHeaders = {
      Authorization: sessionStorage.getItem("jwt"),
    };
    const response = await fetchGET(
      process.env.REACT_APP_GET_PLAY_ENDPOINT,
      {},
      paramsHeaders
    );
    // cuando hay error al consultar ticket o tarea en play
    if (!response.body) {
      setPlayTicket();
      setDataPlay({ isTicket: false, isTask: false });
      setModalAlert({
        show: true,
        title: response.message,
        message: response.errorDetail,
        icon: "info",
      });
      return undefined;
    }
    setDataPlay({
      isTicket: isValidNaturalNumber(response.body.ticket?.data?.id),
      isTask: isValidNaturalNumber(response.body.task?.data?.id),
    });
    // cuando existe ticket en play
    if (isObject(response.body.ticket)) {
      setPlayTicket(response.body.ticket.data);
      const company = {};
      if (isValidNaturalNumber(response.body.ticket.company?.id)) {
        const companyId = response.body.ticket.company.id;
        company[companyId] = {
          ...response.body.ticket.company,
        };
        delete company[companyId]["id"];
      }
      return {
        id: response.body.ticket.data?.id,
        subscription: response.body.ticket.subscription || {},
        users: response.body.ticket.users || {},
        company,
      };
    } else {
      setPlayTicket();
      return undefined;
    }
  };

  const getInitialTicket = async () => {
    const paramsQuery = {
      id: JSON.stringify(filters.initialTicketId),
    };
    const paramsHeaders = {
      Authorization: sessionStorage.getItem("jwt"),
    };
    const response = await fetchGET(
      process.env.REACT_APP_GET_DATA_TICKET,
      paramsQuery,
      paramsHeaders
    );
    // cuando hay error
    if (!response.body) {
      history.replace({
        pathname: "/MyTickets",
        state: location.state,
      });
      setSelectedTicketId();
      setInitialTicket();
      setModalAlert({
        show: true,
        title: response.message,
        message: response.errorDetail,
        icon: "info",
      });
      return undefined;
    }
    // cuando no existe
    if (response.body.none) {
      history.replace({
        pathname: "/MyTickets",
        state: location.state,
      });
      setSelectedTicketId();
      setInitialTicket();
      return undefined;
    }
    // success

    // cuando el ticket seleccionado contiene diferente data
    if (isObject(response.body.ticket)) {
      setInitialTicket(response.body.ticket);
      return {
        data: response.body.ticket,
        users: response.body.users,
        subscription: response.body.subscription,
        company: response.body.company,
      };
    } else {
      history.replace({
        pathname: "/MyTickets",
        state: location.state,
      });
      setSelectedTicketId();
      setInitialTicket();
      return undefined;
    }
  };

  // evento que se ejecuta cada vez que cambia un filtro
  const onChangeFilter = (filterName = "", newValue) => {
    const currentValue = filters[filterName];

    let filter = false;
    // validaciones para currentValue undefined
    if (
      currentValue === undefined &&
      (Array.isArray(newValue) ||
        typeof newValue === "string" ||
        isObject(newValue))
    ) {
      filter = true;
    }
    // validacion para value []
    else if (Array.isArray(currentValue) && Array.isArray(newValue)) {
      if (currentValue.length !== newValue.length) {
        filter = true;
      } else {
        for (const value of newValue) {
          if (!currentValue.some((val) => value === val)) {
            filter = true;
            break;
          }
        }
      }
    }
    // validacion para value String
    else if (typeof currentValue === "string" && typeof newValue === "string") {
      if (currentValue.trim().toLowerCase() !== newValue.trim().toLowerCase()) {
        filter = true;
        newValue = newValue.trim();
      }
    }
    // validacion de objets {}
    else if (isObject(currentValue) && isObject(newValue)) {
      const cv = Object.keys(currentValue);
      const nv = Object.keys(newValue);
      if (cv.length !== nv.length) {
        filter = true;
      } else {
        for (const value of nv) {
          if (currentValue[value] === undefined) {
            filter = true;
            break;
          }
        }
      }
    }

    if (filter) {
      history.replace({
        pathname: "/MyTickets",
        state: location.state,
      });
      setSelectedTicketId();
      setFilters((prev) => ({
        ...prev,
        [filterName]: newValue,
        initialTicketId: null,
      }));
    }
  };

  // evento click en boton subscripcion de una tarjeta o ticket seleccionado
  const onClickSubscribeButton = (
    response /* {
      action: "ADD" | "DELETE" | "ERROR"
      id: ID de la subscripcion
      typeId: ID del ticket
      message: (cuando action es "ERROR")
      errorDetail: (cuando action es "ERROR")
    } */
  ) => {
    if (response.action === "DELETE") {
      const subscriptionsCopy = { ...ticketSubscriptions };
      delete subscriptionsCopy[response.typeId];
      setTicketSubscriptions(subscriptionsCopy);
    } else if (response.action === "ADD") {
      const subscriptionsCopy = { ...ticketSubscriptions };
      subscriptionsCopy[response.typeId] = response.id;
      setTicketSubscriptions(subscriptionsCopy);
    } else {
      setModalAlert({
        show: true,
        title: response.message,
        message: response.errorDetail,
        icon: "info",
      });
    }
  };

  // evento UPDATE del ticket seleccionado
  const onUpdate = async (
    updatedTicket, // {..}
    subscription, // { ticketID: number }
    users, // { userID: {..} }
    company // { companyID: {..} }
  ) => {
    // ver si el ticket actualizado es el ticket play
    if (isObject(playTicket) && playTicket.id === selectedTicketId) {
      // cuando cambio el user asignado
      if (updatedTicket.assignedUserId !== getSession()?.id) {
        setPlayTicket();
        setDataPlay({ isTicket: false, isTask: false });
        updateSubscriptions({}); // delete subscription
        history.replace({ pathname: "/MyTickets", state: location.state });
        setSelectedTicketId();
      }
      // cuando es el mismo user asignado
      else {
        setPlayTicket(updatedTicket);
        updateSubscriptions(subscription); // actualizar subscription
      }
    }
    // ticket actualizado de la lista ... debe aparecer ??
    else if (applyFilters(updatedTicket)) {
      // update users list
      setTicketUserList({ ...ticketUserList, ...users });
      // actualizar subscription
      updateSubscriptions(subscription);
      // update tickets list
      const listCopy = ticketList.map((ticket) => {
        if (ticket.id === selectedTicketId) return updatedTicket;
        else return ticket;
      });
      setTicketList(listCopy);
    }
    // eliminar ticket de la lista
    else {
      const ticketListCopy = ticketList.filter((ticket) => {
        return ticket.id === selectedTicketId ? false : true;
      });
      setTicketList(ticketListCopy);
      updateSubscriptions({}); // delete subscription
      history.replace({
        pathname: "/MyTickets",
        state: location.state,
      });
      setSelectedTicketId();
    }
  };

  // evento UPDATE STATUS PLAY del ticket seleccionado
  const onUpdateStatusPlay = async (
    updatedTicket, // {..}
    subscription, // { ticketID: number }
    users, // { userID: {..} }
    company, // { companyID: {..} }
    action // START | RESTART | PAUSE | FINISH
  ) => {
    if (["START", "RESTART"].includes(action)) {
      onRunning(updatedTicket, subscription);
    } else if (action === "PAUSE") {
      onPause(updatedTicket, subscription);
    } else if (action === "FINISH") {
      onFinish(updatedTicket, subscription);
    }
  };

  // evento que se ejecuta cuando un ticket se inició o reanudó
  const onRunning = (
    updatedTicket, // {..}
    subscription // { ticketID: number }
  ) => {
    // hacer una copia de la lista de tickets y subscripciones
    let newTickets = ticketList.slice();
    let newSubscriptions = {};
    if (isObject(ticketSubscriptions)) {
      newSubscriptions = { ...ticketSubscriptions };
    }

    // indicar que hay un ticket en play
    if (!dataPlay?.isTicket) setDataPlay({ isTicket: true, isTask: false });

    // cuando ya teniamos un ticket en play
    if (isObject(playTicket)) {
      const playTicketCopy = { ...playTicket };
      playTicketCopy.isPlay = false;
      // add a la lista si cumple filtros
      if (applyFilters(playTicketCopy)) newTickets.unshift(playTicketCopy);
      // quitar subscripcion si no cumple filtros
      else delete newSubscriptions[playTicketCopy.id];
    }

    // establecer el nuevo ticket play
    setPlayTicket(updatedTicket);
    // actualizar subscripcion
    if (Object.keys(subscription || {}).length) {
      newSubscriptions = { ...newSubscriptions, ...subscription };
    } else {
      delete newSubscriptions[selectedTicketId];
    }
    setTicketSubscriptions(newSubscriptions);
    // quitar el ticket nuevo en play de la lista
    newTickets = newTickets.filter((ticket) => {
      return ticket.id === selectedTicketId ? false : true;
    });
    setTicketList(newTickets);
  };

  // evento que se ejecuta cuando un ticket se pausó
  const onPause = (
    updatedTicket, // {..}
    subscription // { ticketID: number }
  ) => {
    // cuando se pausó el ticket en play
    if (isObject(playTicket) && playTicket.id === selectedTicketId) {
      const playTicketCopy = updatedTicket;
      // add a la lista si cumple filtros
      if (applyFilters(playTicketCopy)) {
        let newTickets = ticketList.slice();
        newTickets.unshift(playTicketCopy);
        setTicketList(newTickets);
      }
      // cuando no cumple filtros
      else {
        // quitar subscripcion
        let newSubscriptions = {};
        if (isObject(ticketSubscriptions)) {
          newSubscriptions = { ...ticketSubscriptions };
        }
        delete newSubscriptions[playTicketCopy.id];
        setTicketSubscriptions(newSubscriptions);
        // limpiar ticket seleccionado
        history.replace({ pathname: "/MyTickets", state: location.state });
        setSelectedTicketId();
      }
      setPlayTicket();
      setDataPlay({ isTicket: false, isTask: false });
    }
    // cuando se pausó un ticket de la lista
    else {
      // actualizar la data del ticket pausado
      let newTickets = ticketList.map((ticket) => {
        if (ticket.id === selectedTicketId) return updatedTicket;
        else return ticket;
      });
      setTicketList(newTickets);
      // actualizar suscripcion
      updateSubscriptions(subscription);
    }
  };

  const applyFilters = (ticket) => {
    const is_user_tech = getSession()?.isUserTech;
    // filtro por nombre
    if (!isStringEmpty(filters.name)) {
      if (
        !ticket.title
          ?.trim()
          .toLowerCase()
          .includes(filters.name.trim().toLowerCase())
      ) {
        return false;
      }
    }

    // filtro por estatus   <-> "PENDING" | "IN_PROGRESS" | "COMPLETED"
    if (
      Object.keys(filters.status || {}).length &&
      filters.status[ticket.status] === undefined
    ) {
      return false;
    }

    // filtro por compañias para user TECH
    if (
      is_user_tech &&
      Object.keys(filters.company || {}).length &&
      filters.company[ticket.companyId] === undefined
    ) {
      return false;
    }
    // filtro por compañias para user NORMAL
    if (
      !is_user_tech &&
      (!isValidNaturalNumber(getSession()?.companyId) ||
        getSession().companyId !== ticket.companyId)
    ) {
      return false;
    }

    // filtro urgencia para user TECH <-> "LOW" | "MEDIUM" | "HIGH"
    if (
      is_user_tech &&
      Object.keys(filters.urgency || {}).length &&
      filters.urgency[ticket.urgency] === undefined
    ) {
      return false;
    }

    // filtro responsable para user TECH
    if (
      is_user_tech &&
      Object.keys(filters.responsible || {}).length &&
      filters.responsible[ticket.assignedUserId] === undefined
    ) {
      return false;
    }

    // ticket que cumple filtros
    return true;
  };

  // evento DELETE del ticket seleccionado
  const onDelete = async () => {
    if (isObject(playTicket) && playTicket.id === selectedTicketId) {
      setPlayTicket();
      setDataPlay({ isTicket: false, isTask: false });
    } else {
      const ticketListCopy = ticketList.filter((ticket) => {
        return ticket.id === selectedTicketId ? false : true;
      });
      setTicketList(ticketListCopy);
    }
    updateSubscriptions({}); // delete subscription
    history.replace({
      pathname: "/MyTickets",
      state: location.state,
    });
    setSelectedTicketId();
  };

  // evento FINISH del ticket seleccionado
  const onFinish = async (
    updatedTicket, // {..}
    subscription // { ticketID: number }
  ) => {
    // cuando se finalizó el ticket en play
    if (isObject(playTicket) && playTicket.id === selectedTicketId) {
      const playTicketCopy = updatedTicket;
      // add a la lista si cumple filtros
      if (applyFilters(playTicketCopy)) {
        let newTickets = ticketList.slice();
        newTickets.unshift(playTicketCopy);
        setTicketList(newTickets);
      }
      // cuando no cumple filtros
      else {
        // quitar subscripcion
        let newSubscriptions = {};
        if (isObject(ticketSubscriptions)) {
          newSubscriptions = { ...ticketSubscriptions };
        }
        delete newSubscriptions[playTicketCopy.id];
        setTicketSubscriptions(newSubscriptions);
        // limpiar ticket seleccionado
        history.replace({ pathname: "/MyTickets", state: location.state });
        setSelectedTicketId();
      }
      setPlayTicket();
      setDataPlay({ isTicket: false, isTask: false });
    }
    // cuando se finalizó un ticket de la lista
    else {
      // actualizar la data del ticket pausado
      let newTickets = ticketList.map((ticket) => {
        if (ticket.id === selectedTicketId) return updatedTicket;
        else return ticket;
      });
      setTicketList(newTickets);
      // actualizar suscripcion
      updateSubscriptions(subscription);
    }
  };

  // evento FINISH del ticket seleccionado
  const onNewTicket = async (
    ticket, // { .. }
    subscription, // { ticketID: number }
    users, // { userID: {..} }
    company // { companyID: {..} }
  ) => {
    // mostrar ticket en la lista ??
    if (applyFilters(ticket)) {
      // update users list
      setTicketUserList({
        ...ticketUserList,
        ...users,
      });
      // update companies list
      setTicketCompanyList({
        ...ticketCompanyList,
        ...company,
      });
      // actualizar subscription
      let subscriptionsCopy = {};
      if (isObject(ticketSubscriptions)) {
        subscriptionsCopy = { ...ticketSubscriptions };
      }
      subscriptionsCopy = {
        ...subscriptionsCopy,
        ...subscription,
      };
      setTicketSubscriptions(subscriptionsCopy);

      // update tickets list
      const listCopy = ticketList.slice();
      listCopy.unshift(ticket);
      setTicketList(listCopy);
    }
  };

  // evento CLICK NOTIFICACION (cuando se le da click a una noti)
  const onClickNotification = (ticketId) => {
    if (
      isValidNaturalNumber(ticketId) &&
      isValidNaturalNumber(getSession()?.id)
    ) {
      // establecer el ID del ticket como seleccionado
      setFilters((prev) => ({
        ...prev,
        initialTicketId: Number(ticketId),
      }));
    }
  };

  // evento click en boton loadMore de la lista de tarjetas
  const onClickLoadMoreButton = async () => {
    const load = loadCount + 1;
    setLoadCount(load);
    await getMoreTickets(load);
  };

  // evento click de una tarjeta
  const onClickCard = (id) => {
    if (selectedTicketId === id) {
      setSelectedTicketId();
      history.replace({
        pathname: "/MyTickets",
        state: location.state,
      });
    } else {
      history.replace({
        pathname: "/MyTickets/" + id,
        state: location.state,
      });
      setSelectedTicketId(id);
    }
    setShowTicket(false);
    setShowChat(true);
  };

  const onNewAlert = (title, message, icon) => {
    setModalAlert({
      show: true,
      title,
      message,
      icon: icon === "success" ? "success" : "info",
    });
  };

  //evento click para intercalar el chat y la lista de tickets en movil
  const handleBackClick = () => {
    setShowTicket(true);
    setShowChat(false);
  };

  const renderSelectedTicket = () => {
    //console.clear();
    let ticket = undefined;
    let ownerUser = undefined;
    let createdByUser = undefined;
    let assignedUser = undefined;
    let company = undefined;
    let subscriptionId = undefined;

    if (selectedTicketId !== undefined) {
      if (isObject(playTicket) && playTicket.id === selectedTicketId) {
        ticket = { ...playTicket };
      } else {
        ticket = ticketList.find((ticket) => {
          return ticket.id === selectedTicketId;
        });
      }

      if (ticket) {
        // users data
        if (isObject(ticketUserList)) {
          ownerUser = {
            id: ticket.ownerUserId,
            name: ticketUserList[ticket.ownerUserId]?.name,
          };
          createdByUser = {
            id: ticket.createdByUserId,
            name: ticketUserList[ticket.createdByUserId]?.name,
          };
          assignedUser = {
            id: ticket.assignedUserId,
            name: ticketUserList[ticket.assignedUserId]?.name,
          };
        }

        // company data
        if (isObject(ticketCompanyList)) {
          company = isObject(ticketCompanyList[ticket.companyId])
            ? {
                id: ticket.companyId,
                ...ticketCompanyList[ticket.companyId],
              }
            : undefined;
        }

        // subscription data
        if (isObject(ticketSubscriptions)) {
          subscriptionId = ticketSubscriptions[selectedTicketId];
        }
      }
    }

    return (
      <SelectedTicket
        id={selectedTicketId}
        ownerUser={ownerUser}
        createdByUser={createdByUser}
        assignedUser={assignedUser}
        company={company}
        title={ticket?.title}
        description={ticket?.description}
        status={ticket?.status}
        urgency={ticket?.urgency}
        creationDate={ticket?.creationDate}
        completedDate={ticket?.completedDate}
        startDate={ticket?.startDate}
        updateDate={ticket?.updateDate}
        isPlay={ticket?.isPlay}
        ticketCompany={ticket?.company}
        loading={loadingSelectedTicket}
        subscriptionId={subscriptionId}
        onClickSubscribeButton={onClickSubscribeButton}
        dataPlay={dataPlay}
        urgencySelectList={urgencySelectList}
        responsibleSelectList={responsibleSelectList}
        onUpdate={onUpdate}
        onUpdateStatusPlay={onUpdateStatusPlay}
        onDelete={onDelete}
        onNewAlert={onNewAlert}
        type={ticket?.type}
      />
    );
  };
  return (
    <>
      {/* Header Page */}
      <HeaderPage
        assignableUsers={assignableUsers}
        companies={companies}
        title={"Mis Tickets"}
        onNewTicket={onNewTicket}
        onClickNotification={onClickNotification}
      />

      {/* Content Page */}
      <Container>
        {/* Filtros */}
        {showTicket && <div className="filters">
          <Searcher
            value={inputSearch}
            lastSearch={filters.name}
            onChange={(newTitle) => setInputSearch(newTitle)}
            className="inputSearcher"
            placeHolder="Buscar ticket"
            onClickEnter={() => {
              onChangeFilter("name", inputSearch);
            }}
            onExitFocus={() => {
              setInputSearch("");
            }}
          />
        </div>
        }
        <div className="contentPage">
          {loadingPage ? (
            <Loading />
          ) : (
            <>
            {!isMobile && <div className="container-mob"> 
                <SubscriptionCardList
                  className="ticketList"
                  dataFullTickets={{
                    playTicket: playTicket,
                    ticketList: ticketList,
                    ticketSubscriptions: ticketSubscriptions,
                    userList: ticketUserList,
                    companyList: ticketCompanyList,
                  }}
                  loadMoreButton={{
                    show: showLoadMoreButton,
                    label: "Cargar más tickets",
                    onClick: onClickLoadMoreButton,
                  }}
                  fixedCardListTitle={{
                    single: "Ticket que estas trabajando",
                    multiple: "Tickets que estas trabajando",
                  }}
                  cardListTitle={"Tickets"}
                  emptyListTitle={"No se encontraron tickets"}
                  selectedId={selectedTicketId}
                  onClickSubscribeButton={onClickSubscribeButton}
                  onClickCard={onClickCard}
                />
                {renderSelectedTicket()}
              </div>}
            {isMobile &&  <div className="container-mob"> 
                {showChat && <ButtonReturn onClick={handleBackClick}/>}
                {showTicket && <SubscriptionCardList
                  className="ticketListmobile"
                  dataFullTickets={{
                    playTicket: playTicket,
                    ticketList: ticketList,
                    ticketSubscriptions: ticketSubscriptions,
                    userList: ticketUserList,
                    companyList: ticketCompanyList,
                  }}
                  loadMoreButton={{
                    show: showLoadMoreButton,
                    label: "Cargar más tickets",
                    onClick: onClickLoadMoreButton,
                  }}
                  fixedCardListTitle={{
                    single: "Ticket que estas trabajando",
                    multiple: "Tickets que estas trabajando",
                  }}
                  cardListTitle={"Tickets"}
                  emptyListTitle={"No se encontraron tickets"}
                  selectedId={selectedTicketId}
                  onClickSubscribeButton={onClickSubscribeButton}
                  onClickCard={onClickCard}
                />}
              {showChat && renderSelectedTicket()}
            </div>}
          </>
          )}
        </div>
      </Container>

      {/* modal de alertas */}
      {modalAlert.show && (
        <Modal
          className="ticketsPage-modal"
          show={modalAlert.show}
          title={modalAlert.title}
          message={modalAlert.message}
          icon={modalAlert.icon}
          onClickBtnDefault={(event) => {
            setModalAlert({
              show: false,
              title: "",
              message: "",
              icon: undefined,
            });
          }}
        />
      )}
    </>
  );
}
