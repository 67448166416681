import { Container } from "./styles";
import { ButtonV2 } from "contentoh-components-library";
import {
  faArrowLeft as IconArrowLeft,
} from "@fortawesome/free-solid-svg-icons";

export const ButtonReturn = (props) => {
  const {
    loadingButton, // "START" | "RESTART" | "PAUSE" | "FINISH" | undefined
    onClick, // (action) => {}   <-> action => "START" | "RESTART" | "PAUSE" | "FINISH"
  } = props;

  return (
    <Container>
      {/* boton regresar */}
        <ButtonV2
          key={"buttonReturn"}
          className="buttonReturn"
          size={14}
          type={"purple"}
          transparent
          icon={IconArrowLeft}
          disabled={(loadingButton)}
          isLoading={loadingButton === "START"}
          borderType="circle"
          onClick={(event) => {
            onClick && onClick();
          }}
        />
    </Container>
  );
};
