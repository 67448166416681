import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  border-radius: 50%;
  width: 34px;
  height: 34px;

  .notification-button {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    border: 1px solid #f0f0f0;
    .button {
      width: 100%;
      height: 100%;
      padding: 0px;
    }
  }
`;

export const NewNotifications = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;
  z-index: 5;
  border-radius: 50%;
  background: #f84f31;
`;
