//components
import { Container } from "./styles";
import { ButtonV2 } from "contentoh-components-library";

//images
import addProjectTitle from "../../assets/images/addProjectTittle.svg";
import { useEffect, useState } from "react";
import { RangeCalendar } from "contentoh-components-library";
import {
  diffObjectValues,
  isArrayEmpty,
  isObject,
  isStringEmpty,
  isValidNaturalNumber,
} from "../../utils/validations";
import { SelectV2 } from "contentoh-components-library";
import { fetchPOST, fetchPUT } from "../../utils/handle_http";
import { getSession } from "../../utils/session";

export const ModalTask = ({
  task /* {
    id: (number) => ID de la tarea
    name: (string) => Nombre de la tarea
    description: (string) => Descripcion de la tarea
    projectId: (number) => ID del proyecto relacionado a la tarea
    isPlay: (boolean) => true <> tarea en play => false <> tarea en pausa
    assignedUserId: (number)  => user asignado a la tarea
    creationDate: (string) => fecha en la que se creó la tarea
    startDate: (string) => fecha en la comenzo a realizarse la tarea
    finalDate: (string) => fecha en la que se termino de realizarse la tarea
    estimatedStartDate: (string) => fecha estimada de comienzo de la tarea
    estimatedEndDate: (string) => fecha estimada de entrega de la tarea
  } */,
  assignedUser, // { name: string }
  minDate, // Date()
  selectedRelease /* {
    id: number
    status: string
    createdByUser: { id , name }
  } */,
  assignableUsers, // [ { id , name } ]  -- lista de usuarios asignables
  onClickCancel, // evento click en el boton "cancelar"
  onNewTask /* (
    task: {..}
    subscription: { projectID: subscriptionID }
    users: { userID: {..} }
    maxMinDates: { minDate: string , maxDate: string }
  ) => {} evento que recibe la data de la nueva tarea generada
  */,
  onUpdateTask /* (
    task: {..}
    subscription: { projectID: subscriptionID }
    users: { userID: {..} }
    maxMinDates: { minDate: string , maxDate: string }
  ) => {} evento que recibe la data de la nueva tarea generada
  */,
  onNewAlert, // (title, message, icon) => {}  -- evento para mostrar un modal de alerta
}) => {
  const [responsibleList, setResponsibleList] = useState([]); // [ { label , value } ]
  const [selectedResponsible, setSelectedResponsible] = useState({});
  const [inputTitle, setInputTitle] = useState("");
  const [inputDescription, setInputDescription] = useState("");
  const [rangeDates, setRangeDates] = useState({});
  const [currentDates, setCurrentDates] = useState();
  const [label, setLabel] = useState("Seleccionar Fechas");
  const [focusInputTitle, setFocusInputTitle] = useState(false);
  const [disabledButtonAddEdit, setDisabledButtonAddEdit] = useState(true);
  const [loadingButtonAddEdit, setLoadingButtonAddEdit] = useState(false);
  const [mode, setMode] = useState("ADD");

  useEffect(() => {
    changeResponsibleList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignableUsers, task]);

  useEffect(() => {
    if (isObject(task)) validateEdit();
    else validateAdd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputTitle, inputDescription, selectedResponsible, rangeDates]);

  // cada que cambie el objeto tarea
  useEffect(() => {
    if (isObject(task)) {
      // cambiar el modo vista del modal
      if (permissionToEdit()) setMode("EDIT");
      else setMode("READ");
      // set name
      if (isStringEmpty(task.name)) setInputTitle("");
      else setInputTitle(task.name);
      // set description
      if (isStringEmpty(task.description)) setInputDescription("");
      else setInputDescription(task.description);
      // set fecha estimada de inicio y fin
      if (
        ![undefined, null, ""].includes(task.estimatedStartDate) &&
        ![undefined, null, ""].includes(task.estimatedEndDate)
      ) {
        setRangeDates({
          estimatedStartDate: task.estimatedStartDate,
          estimatedDate: task.estimatedEndDate,
        });
        setCurrentDates({
          start: `${task.estimatedStartDate}`,
          end: `${task.estimatedEndDate}`,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  useEffect(() => {
    if (rangeDates.estimatedStartDate) {
      setLabel(
        `${new Date(rangeDates.estimatedStartDate)
          .toISOString()
          .substring(0, 10)} - ${new Date(rangeDates.estimatedDate)
          .toISOString()
          .substring(0, 10)}`
      );
    }
  }, [rangeDates]);

  /*------------------------------------
  FUNCIONES
  -------------------------------------- */
  const validateAdd = () => {
    let disabled = false;
    // validar titulo
    if (isStringEmpty(inputTitle)) disabled = true;
    // validar descripcion
    else if (isStringEmpty(inputDescription)) disabled = true;
    // validar user asignado
    else if (!Object.keys(selectedResponsible || {}).length) disabled = true;
    // validar delivery date
    else if (
      isStringEmpty(rangeDates?.estimatedStartDate) ||
      isStringEmpty(rangeDates?.estimatedDate)
    ) {
      disabled = true;
    }

    setDisabledButtonAddEdit(disabled);
  };

  const validateEdit = () => {
    let disabled = true;
    // validar si title es diferente
    if (
      !isStringEmpty(inputTitle) &&
      (isStringEmpty(task.name) || task.name.trim() !== inputTitle.trim())
    ) {
      disabled = false;
    }
    // validar si description es diferente
    if (
      !isStringEmpty(inputDescription) &&
      (isStringEmpty(task.description) ||
        task.description.trim() !== inputDescription.trim())
    ) {
      disabled = false;
    }
    // validar si assigned user es diferente
    if (Object.keys(selectedResponsible || {}).length) disabled = false;
    // validar delivery date
    if (
      !isStringEmpty(rangeDates?.estimatedStartDate) &&
      !isStringEmpty(rangeDates?.estimatedDate)
    ) {
      if (
        ![undefined, null, ""].includes(task.estimatedStartDate) &&
        ![undefined, null, ""].includes(task.estimatedEndDate)
      ) {
        if (
          new Date(rangeDates.estimatedStartDate).getTime() !==
            new Date(task.estimatedStartDate).getTime() ||
          new Date(rangeDates.estimatedDate).getTime() !==
            new Date(task.estimatedEndDate).getTime()
        ) {
          disabled = false;
        }
      } else {
        disabled = false;
      }
    }

    // bloquear boton ??
    setDisabledButtonAddEdit(disabled);
  };

  const changeResponsibleList = () => {
    if (isArrayEmpty(assignableUsers)) setResponsibleList([]);
    else {
      if (isValidNaturalNumber(task?.assignedUserId)) {
        const usersList = [];
        assignableUsers.forEach((user) => {
          if (user.id !== task.assignedUserId) {
            usersList.push({ label: user.name, value: user.id });
          }
        });
        setResponsibleList(usersList);
      } else {
        setResponsibleList(
          assignableUsers.map((user) => {
            return { label: user.name, value: user.id };
          })
        );
      }
    }
  };

  const permissionToEdit = () => {
    const session = getSession();
    if (
      isValidNaturalNumber(session?.id) &&
      session.isUserAdminTech &&
      isValidNaturalNumber(task.id) &&
      ["PENDING", "IN_PROGRESS"].includes(task.status) &&
      ["IN_PLANNING", "IN_PROGRESS"].includes(selectedRelease?.status) &&
      isValidNaturalNumber(selectedRelease.createdByUser?.id) &&
      session.id === selectedRelease.createdByUser.id
    ) {
      return true;
    } else {
      return false;
    }
  };

  /*------------------------------------
  EVENTOS
  -------------------------------------- */
  const onClickButtonAdd = async () => {
    setLoadingButtonAddEdit(true);
    // add la nueva tarea en la BD
    let assignedUserId = undefined;
    const selectedRes = Object.keys(selectedResponsible || {});
    if (selectedRes.length) assignedUserId = Number(selectedRes[0]);

    let estimatedDate = { start: undefined, end: undefined };
    if (!isStringEmpty(rangeDates?.estimatedStartDate)) {
      estimatedDate.start = new Date(rangeDates.estimatedStartDate).getTime();
    }
    if (!isStringEmpty(rangeDates?.estimatedDate)) {
      estimatedDate.end = new Date(rangeDates.estimatedDate).getTime();
    }
    let projectId = undefined;
    if (isValidNaturalNumber(selectedRelease?.id)) {
      projectId = Number(selectedRelease.id);
    }

    const paramsBody = {
      projectId: JSON.stringify(projectId),
      name: inputTitle.trim(),
      description: inputDescription.trim(),
      assignedUserId: JSON.stringify(assignedUserId),
      estimatedStartDate: JSON.stringify(estimatedDate.start),
      estimatedEndDate: JSON.stringify(estimatedDate.end),
    };
    const paramsHeaders = {
      Authorization: sessionStorage.getItem("jwt"),
    };
    const response = await fetchPOST(
      process.env.REACT_APP_TASK_ENDPOINT,
      paramsBody,
      paramsHeaders
    );
    // cuando hay error
    if (!response.body) {
      onNewAlert(response.message, response.errorDetail, "info");
      setLoadingButtonAddEdit(false);
      return;
    }

    // reflejar cambios en local
    onNewAlert("Tarea agregada al proyecto", "", "success");
    onNewTask(
      response.body.task,
      response.body.subscription,
      response.body.users,
      response.body.maxMinDates
    );
  };

  const onClickButtonEdit = async () => {
    setLoadingButtonAddEdit(true);
    // datos a enviar
    let newName;
    let newDescription;
    let newAssignedUserId;
    let newEstimatedStartDate;
    let newEstimatedEndDate;

    // change name?
    if (
      !isStringEmpty(inputTitle) &&
      (isStringEmpty(task.name) || task.name.trim() !== inputTitle.trim())
    ) {
      newName = inputTitle.trim();
    }
    // change description?
    if (
      !isStringEmpty(inputDescription) &&
      (isStringEmpty(task.description) ||
        task.description.trim() !== inputDescription.trim())
    ) {
      newDescription = inputDescription.trim();
    }
    // change assigned user ?
    const selectedRes = Object.keys(selectedResponsible || {});
    if (selectedRes.length) newAssignedUserId = Number(selectedRes[0]);

    // change estimated delivery date
    let newRangeDates = false;
    if (
      !isStringEmpty(rangeDates?.estimatedStartDate) &&
      !isStringEmpty(rangeDates?.estimatedDate)
    ) {
      if (
        ![undefined, null, ""].includes(task.estimatedStartDate) &&
        ![undefined, null, ""].includes(task.estimatedEndDate)
      ) {
        if (
          new Date(rangeDates.estimatedStartDate).getTime() !==
            new Date(task.estimatedStartDate).getTime() ||
          new Date(rangeDates.estimatedDate).getTime() !==
            new Date(task.estimatedEndDate).getTime()
        ) {
          newRangeDates = true;
        }
      } else {
        newRangeDates = true;
      }
    }
    if (newRangeDates) {
      newEstimatedStartDate = new Date(rangeDates.estimatedStartDate).getTime();
      newEstimatedEndDate = new Date(rangeDates.estimatedDate).getTime();
    }

    // hacer peticion HTTP
    const paramsBody = {
      id: JSON.stringify(task.id),
      newName,
      newDescription,
      newAssignedUserId: JSON.stringify(newAssignedUserId),
      newEstimatedStartDate: JSON.stringify(newEstimatedStartDate),
      newEstimatedEndDate: JSON.stringify(newEstimatedEndDate),
    };
    const paramsHeaders = {
      Authorization: sessionStorage.getItem("jwt"),
    };
    const response = await fetchPUT(
      process.env.REACT_APP_TASK_ENDPOINT,
      paramsBody,
      paramsHeaders
    );
    // cuando hay error
    if (!response.body) {
      onNewAlert(response.message, response.errorDetail, "info");
      setLoadingButtonAddEdit(false);
      return;
    }
    // success
    onUpdateTask(
      response.body.task,
      response.body.subscription,
      response.body.users,
      response.body.maxMinDates
    );
    onNewAlert("La tarea ha sido editada", "", "success");
  };

  const renderAssignedUserSelect = () => {
    let defaultItem = undefined;
    let userName = "Ninguno";
    // user asignado ??
    if (
      isObject(task) &&
      isObject(assignedUser) &&
      isValidNaturalNumber(task.assignedUserId)
    ) {
      // get name
      userName = isStringEmpty(assignedUser.name)
        ? "Nombre no encontrado"
        : assignedUser.name;
      // set default item select
      defaultItem = {
        label: userName,
        itemType: "marginCheckbox",
        showLabelInSelect: true,
      };
    }

    return (
      <>
        <label className="label-title">Responsable</label>
        <SelectV2
          items={responsibleList}
          triggerType="click"
          selectButton={{
            className: "btnSelectResponsible",
            type: "whiteS3",
            label: "Elige un responsable",
            size: 10,
            disabled: mode === "READ",
          }}
          defaultItem={defaultItem}
          inputSearch={{
            className: "inputSearchResponsible",
            defaultText: "Buscar responsable",
          }}
          typeSelectItems={"marginCheckbox"}
          maxHeightDropdown={"50vh"}
          alignmentItemsOverflow={"rows"}
          classNameSelect="selectResponsible"
          classNameDropdown="dropdownSelectResponsible"
          onChange={(selectedItem) => {
            if (diffObjectValues(selectedItem, selectedResponsible)) {
              setSelectedResponsible(selectedItem);
            }
          }}
        />
      </>
    );
  };

  return (
    <>
      <Container
        className={"modalAddTask"}
        show={true}
        title={
          mode === "ADD"
            ? "Añadir Tarea"
            : mode === "EDIT"
            ? "Editar tarea"
            : "Tarea"
        }
        customComponent={
          <>
            {/* input titulo */}
            <div
              className={
                "container-inputTitle" + (focusInputTitle ? " active" : "")
              }
            >
              <img src={addProjectTitle} alt="add" />
              <input
                className="inputTitle"
                placeholder="Nombre de la tarea"
                disabled={mode === "READ"}
                value={inputTitle}
                onChange={(event) => {
                  setInputTitle(event.target.value);
                }}
                onFocus={(event) => setFocusInputTitle(true)}
                onBlur={(event) => setFocusInputTitle(false)}
              />
            </div>

            {/* input descripcion */}
            <textarea
              className="inputDescription"
              placeholder="Describe la tarea"
              disabled={mode === "READ"}
              rows={3}
              value={inputDescription}
              onChange={(event) => {
                setInputDescription(event.target.value);
              }}
            />

            {/* container grid */}
            <div className="container-dataGrid">
              {/* select de responsable */}
              {renderAssignedUserSelect()}

              {/* select fecha de entrega */}
              <label className="label-title">Fecha estimada</label>
              {mode === "READ" ? (
                <label className="label-read">{label}</label>
              ) : (
                <div className="container-rangeCalendar">
                  <RangeCalendar
                    setParameterArray={setRangeDates}
                    minDate={minDate}
                    currentDates={currentDates}
                    label={label}
                    calendarId="range-selector-id"
                  />
                </div>
              )}
            </div>
          </>
        }
        buttons={[
          <ButtonV2
            key={"modal-buttonCancel"}
            className="modal-buttonCancel"
            type="white"
            label={mode === "READ" ? "Cerrar" : "Cancelar"}
            borderType="oval"
            onClick={(event) => {
              onClickCancel && onClickCancel(event);
            }}
          />,
          mode !== "READ" ? (
            <ButtonV2
              key={"modal-buttonAdd"}
              className="modal-buttonAdd"
              type="pink"
              label={mode === "EDIT" ? "Editar" : "Añadir"}
              borderType="oval"
              disabled={disabledButtonAddEdit}
              isLoading={loadingButtonAddEdit}
              onClick={mode === "EDIT" ? onClickButtonEdit : onClickButtonAdd}
            />
          ) : undefined,
        ]}
      />
    </>
  );
};
