import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  isArrayEmpty,
  isObject,
  isStringEmpty,
  isValidNaturalNumber,
} from "../../utils/validations";
import SubscriptionCard from "./SubscriptionCard";
import {
  faClipboardList as IconList,
  faArrowRotateRight as IconLoadMore,
} from "@fortawesome/free-solid-svg-icons";
import { CustomIcon, ButtonV2 } from "contentoh-components-library";
import { Container } from "./styles";

export default function SubscriptionCardList(props) {
  const {
    className,
    dataFullTickets /* {
      playTicket: {
        id: number
        ownerUserId: number
        createdByUserId: number
        assignedUserId: number
        companyId: number
        title: string
        description: string
        status: (string) "COMPLETED" | "PENDING" | "IN_PROGRESS"
        urgency: "LOW" | "MEDIUM" | "HIGH"
        creationDate: string
        completedDate: string
        startDate: string
        updateDate: string
        isPlay: true | false | null
      } ,
      ticketList: [
        {
          id: number
          ownerUserId: number
          createdByUserId: number
          assignedUserId: number
          companyId: number
          title: string
          description: string
          status: (string) "COMPLETED" | "PENDING" | "IN_PROGRESS"
          urgency: "LOW" | "MEDIUM" | "HIGH"
          creationDate: string
          completedDate: string
          startDate: string
          updateDate: string
          isPlay: true | false | null
        } ,
        ...
      ],
      ticketSubscriptions: {
        [ticketId]: subscriptionId,
        ...
      }
      userList: {
        [userId]: {
          name: string
          companyId: number
        },
        ...
      }
      companyList: {
        [companyId] : {
          name: string
          isRetailer: (boolean) true | false | undefined => contento-oh!
        },
        ...
      }
    } */,
    dataFullReleases /* {
      releaseList: [
        {
          id: number
          createdByUserId: number
          assignedUserId: number
          title: string
          description: string
          status: (string) "COMPLETED" | "IN_PLANNING" | "IN_PROGRESS"
          forProvider: boolean
          forRetailer: boolean
          forCreator: boolean
          estimatedDeliveryDate: string
          creationDate: string
          completedDate: string
          startDate: string
          updateDate: string
        } ,
        ...
      ],
      releaseSubscriptions: {
        [releaseId]: subscriptionId,
        ...
      }
      userList: {
        [userId]: {
          name: string
          companyId: number
        },
        ...
      }
      components: {
        showAssignedUser: (boolean) ver etiqueta de responsable ??
        showSubscription: (boolean) ver boton suscribirse ??
      },
    } */,
    fixedCardListTitle /* {
      single: string
      multiple: string
    } */,
    cardListTitle, // (string) titulo de la lista de tarjetas
    emptyListTitle, // (string) texto que aparece cuando no hay lista de tarjetas
    loadMoreButton /* {
      show: boolean
      label: string
      onClick: () => {}
    } */,
    selectedId, // (number) ID del ticket o proyecto seleccionado
    onClickSubscribeButton /* ({
      action: "ADD" | "DELETE" | "ERROR"
      id: ID de la subscripcion (cuando action es "ADD" | "DELETE")
      typeId: ID del ticket o proyecto (cuando action es "ADD" | "DELETE") 
      message: (cuando action es "ERROR")
      errorDetail: (cuando action es "ERROR")
    }) => {} */,
    onClickCard, // (id) => {}
  } = props;
  const [loadingLoadMore, setLoadingLoadMore] = useState(false); // componente cargando??

  const renderFullTicketsList = () => {
    const listJSX = [];
    const ticketsPlay = [];

    if (
      isObject(dataFullTickets?.playTicket) &&
      isValidNaturalNumber(dataFullTickets.playTicket.id) &&
      dataFullTickets.playTicket.isPlay === true
    ) {
      ticketsPlay.push(
        createCardTicket(dataFullTickets.playTicket, "playCard")
      );
    }

    if (!isArrayEmpty(dataFullTickets?.ticketList)) {
      dataFullTickets.ticketList.forEach((ticket, index) => {
        listJSX.push(createCardTicket(ticket, "ticketCard-" + index));
      });
    }

    // render
    return renderLists(ticketsPlay, listJSX);
  };

  const renderFullReleasesList = () => {
    //console.log("dataFullReleases", dataFullReleases);
    const listJSX = [];
    if (!isArrayEmpty(dataFullReleases?.releaseList)) {
      dataFullReleases.releaseList.forEach((release, index) => {
        listJSX.push(createCardRelease(release, "releaseCard-" + index));
      });
    }

    // render
    return renderLists([], listJSX);
  };

  const createCardTicket = (ticket, key) => {
    // user conectado esta subscrito al ticket?
    let subscription = {
      id: undefined,
      type: "TICKET",
      typeId: ticket.id,
      onClick: onClickSubscribeButton,
    };
    if (isObject(dataFullTickets.ticketSubscriptions)) {
      subscription.id = dataFullTickets.ticketSubscriptions[ticket.id];
    }
    // status del ticket
    let status = undefined;
    switch (ticket.status) {
      case "PENDING":
        status = {
          type: "NEW",
          label: "Nuevo",
          tooltipLabel: "Ticket pendiente por resolver",
        };
        break;
      case "IN_PROGRESS":
        status = {
          type: "IN_PROCESS",
          label: "En proceso",
          tooltipLabel: "Este ticket se esta trabajando",
        };
        break;
      case "COMPLETED":
        status = {
          type: "COMPLETED",
          label: "Cerrado",
          tooltipLabel: "Ticket terminado",
        };
        break;
    }

    // urgencia del ticket
    let urgency = undefined;
    switch (ticket.urgency) {
      case "LOW":
        urgency = {
          type: "LOW",
          label: "Baja",
          tooltipLabel: "Ticket con poca prioridad",
        };
        break;
      case "MEDIUM":
        urgency = {
          type: "MEDIUM",
          label: "Media",
          tooltipLabel: "Ticket sin tanta urgencia",
        };
        break;
      case "HIGH":
        urgency = {
          type: "HIGH",
          label: "Alta",
          tooltipLabel: "Ticket que urge trabajar",
        };
        break;
    }

    // compañia del ticket
    let companyName = undefined;
    if (dataFullTickets.companyList) {
      const companyTicket = dataFullTickets.companyList[ticket.companyId];
      if (companyTicket) {
        // get users
        let ownerUser = undefined;
        let createdByUser = undefined;
        if (dataFullTickets.userList) {
          if (dataFullTickets.userList[ticket.ownerUserId]) {
            ownerUser = {
              id: ticket.ownerUserId,
              name: dataFullTickets.userList[ticket.ownerUserId].name,
            };
          }
          if (dataFullTickets.userList[ticket.createdByUserId]) {
            createdByUser = {
              id: ticket.createdByUserId,
              name: dataFullTickets.userList[ticket.createdByUserId].name,
            };
          }
        }
        companyName = {
          name: companyTicket.name,
          type:
            companyTicket.isRetailer === undefined
              ? "FACTORY"
              : companyTicket.isRetailer
              ? "RETAILER"
              : "PROVIDER",
          ownerUser,
          createdByUser,
        };
      }
    }

    // user responsable del ticket
    let assignedUser = undefined;
    if (dataFullTickets.userList) {
      if (dataFullTickets.userList[ticket.assignedUserId]) {
        assignedUser = {
          id: ticket.assignedUserId,
          name: dataFullTickets.userList[ticket.assignedUserId].name,
          tooltipTitle: "Responsable del ticket",
        };
      }
    }

    // diseño de la tarjeta
    return (
      <SubscriptionCard
        key={key}
        id={ticket.id}
        isSelected={selectedId !== undefined && ticket.id === selectedId}
        title={{
          label: ticket.title,
          tooltipTitle: "Título del ticket",
        }}
        dates={{
          creationDate: ticket.creationDate,
          startDate: ticket.startDate,
          updateDate: ticket.updateDate,
          endDate: ticket.completedDate,
          visibleDate: "creationDate",
        }}
        subscription={subscription}
        description={ticket.description}
        statusLegend={status}
        urgencyLegend={urgency}
        companyLegend={companyName}
        assignedUser={assignedUser}
        onClickSubscribeButton={onClickSubscribeButton}
        onClickCard={onClickCard}
      />
    );
  };

  const createCardRelease = (release, key) => {
    // user conectado esta subscrito al proyecto?
    let subscription = {
      id: undefined,
      type: "RELEASE",
      typeId: release.id,
      onClick: onClickSubscribeButton,
    };
    if (!dataFullReleases.components?.showSubscription === true) {
      subscription = undefined;
    } else {
      if (isObject(dataFullReleases.releaseSubscriptions)) {
        subscription.id = dataFullReleases.releaseSubscriptions[release.id];
      }
    }

    // status del proyecto
    let status = undefined;
    switch (release.status) {
      case "IN_PLANNING":
        status = {
          type: "NEW",
          label: "En planeación",
          tooltipLabel: "Proyecto a implementar proximamente",
        };
        break;
      case "IN_PROGRESS":
        status = {
          type: "IN_PROCESS",
          label: "En desarrollo",
          tooltipLabel: "Este proyecto se esta trabajando",
        };
        break;
      case "COMPLETED":
        status = {
          type: "COMPLETED",
          label: "Completado",
          tooltipLabel: "Proyecto concluido",
        };
        break;
    }

    // user responsable del proyecto
    let assignedUser = undefined;
    if (dataFullReleases.components?.showAssignedUser === true) {
      if (isObject(dataFullReleases.userList)) {
        if (dataFullReleases.userList[release.assignedUserId]) {
          assignedUser = {
            id: release.assignedUserId,
            name: dataFullReleases.userList[release.assignedUserId].name,
            tooltipTitle: "Responsable del proyecto",
          };
        }
      }
    }

    // leyendas simples con diseño por default
    const legends = [];
    // leyend platform provider
    if (release.forProvider === true) {
      legends.push({
        label: "Proveedores",
        tooltipTitle: "",
        tooltipLabel: "Proyecto que va a beneficiar a usuarios de proveedores",
      });
    }
    // leyend platform retailer
    if (release.forRetailer === true) {
      legends.push({
        label: "Retailers",
        tooltipTitle: "",
        tooltipLabel: "Proyecto que va a beneficiar a usuarios de cadenas",
      });
    }
    // leyend platform creator
    if (release.forCreator === true) {
      legends.push({
        label: "Colaboradores",
        tooltipTitle: "",
        tooltipLabel: "Proyecto que va a beneficiar a usuarios de Content-oh!",
      });
    }

    // diseño de la tarjeta
    return (
      <SubscriptionCard
        key={key}
        id={release.id}
        isSelected={selectedId !== undefined && release.id === selectedId}
        title={{
          label: release.title,
          tooltipTitle: "Título del proyecto",
        }}
        dates={{
          creationDate: release.creationDate,
          startDate: release.startDate,
          updateDate: release.updateDate,
          endDate: release.completedDate,
          estimatedDeliveryDate: release.estimatedDeliveryDate,
          visibleDate: "estimatedDeliveryDate",
        }}
        subscription={subscription}
        description={release.description}
        statusLegend={status}
        legends={legends}
        assignedUser={assignedUser}
        onClickSubscribeButton={onClickSubscribeButton}
        onClickCard={onClickCard}
      />
    );
  };

  const renderLists = (fixedCardList = [], cardList = []) => {
    // render
    return (
      <>
        {/* tarjetas actuales */}
        {fixedCardList.length > 0 && (
          <div className="container-fixedCardList">
            {/* title */}
            <label className="label-subtitle">
              {fixedCardList.length === 1
                ? isStringEmpty(fixedCardListTitle?.single)
                  ? "Actual"
                  : fixedCardListTitle.single
                : isStringEmpty(fixedCardListTitle?.multiple)
                ? "Actuales"
                : fixedCardListTitle.multiple}
            </label>
            {/* lista */}
            <div className="containerList">{fixedCardList}</div>
          </div>
        )}
        {/* lista de tarjetas */}
        {cardList.length > 0 ? (
          <div className="container-cardList">
            {/* title */}
            {fixedCardList.length > 0 && (
              <label className="label-subtitle">
                {isStringEmpty(cardListTitle) ? "Lista" : cardListTitle}
              </label>
            )}
            {/* lista */}
            <div className="containerList">{cardList}</div>
            {/* boton cargar mas */}
            {loadMoreButton?.show === true && (
              <ButtonV2
                className={"button-loadMore"}
                type={"purple"}
                size={12}
                borderType={"rectangle"}
                label={
                  isStringEmpty(loadMoreButton.label)
                    ? "Cargar más"
                    : loadMoreButton.label
                }
                icon={IconLoadMore}
                isLoading={loadingLoadMore}
                iconPosition={"top"}
                onClick={async (event) => {
                  if (loadMoreButton.onClick) {
                    setLoadingLoadMore(true);
                    await loadMoreButton.onClick();
                    setLoadingLoadMore(false);
                  }
                }}
              />
            )}
          </div>
        ) : (
          <>
            {/* lista vacia */}
            <div className="container-emptyList">
              <CustomIcon
                className="icon-emptyList"
                type={"whiteS2"}
                transparent
                size={50}
                icon={IconList}
              />
              <label>
                {isStringEmpty(emptyListTitle) ? "Lista vacía" : emptyListTitle}
              </label>
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <Container className={isStringEmpty(className) ? "" : className}>
      {/* lista de tickets con ticket play */}
      {isObject(dataFullTickets) && renderFullTicketsList()}

      {/* lista de releases */}
      {isObject(dataFullReleases) && renderFullReleasesList()}
    </Container>
  );
}
