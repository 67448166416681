import { Container } from "./styles";
import React, { useState, useEffect } from "react";
import iconLogoContentoh from "../../assets/images/VerticalSideMenuMainPage/iconLogoContentoh.svg";
import iconLogo from "../../assets/images/VerticalSideMenuMainPage/iconLogo.svg";
import tickets from "../../assets/images/VerticalSideMenuMainPage/IconTickets.svg";
import mytickets from "../../assets/images/VerticalSideMenuMainPage/myTicket.svg";
import { NavLink, useLocation } from "react-router-dom";
import useScreenSizes from '../../hooks/useScreenSizes.js';
import { MenuBar } from "../MenuBar";

export const VerticalSideMenuMainPage = () => {
  const [showMenuOption, setShowMenuOption] = useState([]);
  const [trueBar, setTrueBar] = useState();
  const [isUserTech, setIsUserTech] = useState();
  const location = useLocation();
  const {isDesktop} = useScreenSizes();
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    const menuOption = [
      {
        icon: tickets,
        path: "/Tickets",
        title: "Tickets",
      },
      {
        icon: mytickets,
        path: "/MyTickets",
        title: "Mis Tickets",
      },
    ];
    if (["ADMIN-AS", "ADMIN", "AS"].includes(isUserTech)) {
      menuOption.pop();
    }
    setShowMenuOption(menuOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserTech]);

  useEffect(() => {
    setIsUserTech(JSON.parse(sessionStorage.getItem("user"))?.isUserTech);
  }, [location.state?.Auth]);
  return (
    <>
    <Container
      className={trueBar}
      bar={trueBar}
    >
      <div className="navbar-top">
      {isDesktop && <img src={iconLogo} alt="Logo" className="logo" />}
        {isDesktop ? (
          <div className="option">
            {showMenuOption.map((item, index) => (
              <div key={"item-" + index}>
                <NavLink to={item.path} className="option-link">
                  <img src={item.icon} alt={item.title}></img>
                  {trueBar && <p>{item.title}</p>}
                </NavLink>
                {item.suboption !== undefined && (
                  <>
                    {item?.suboption?.map((subitem, index) => (
                      <div key={"subitem-" + index} className="sub-option">
                        <NavLink to={subitem.path}>
                          {trueBar && <p>{subitem.title}</p>}
                        </NavLink>
                      </div>
                    ))}
                  </>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="header-container">
            <div className="logo-container">
              <img src={iconLogoContentoh} alt="Logo" className="logo" />
            </div>
            <div className="menu-container">
              {!isUserTech && <MenuBar onClick={toggleMenu} />}
              {showMenu && (
                <ul className="menu-options">
                  {showMenuOption.map((item, index) => (
                    <li key={"item-mobile-" + index}>
                      <NavLink to={item.path} className="option-link">
                        {item.title}
                      </NavLink>
                      {item.suboption !== undefined && (
                        <>
                          {item?.suboption?.map((subitem, subIndex) => (
                            <li key={"subitem-mobile-" + subIndex} className="sub-option">
                              <NavLink to={subitem.path}>
                                {trueBar && <p>{subitem.title}</p>}
                              </NavLink>
                            </li>
                          ))}
                        </>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        )}
        </div>
    </Container>
  </>
);
}
