import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  border: 1px solid #f0f0f0;
  background-color: transparent;
  border-radius: 50px;
  padding: 10px 15px;
  flex-grow: 2;
  max-width: 300px;

  input {
    border: none;
    color: #262626;
    font: normal normal normal 13px/13px sans-serif;
    letter-spacing: 0.24px;
    &::placeholder {
      color: #b3b3b3;
    }
    &:focus {
      outline: none;
    }
  }
  img {
    & + * {
      margin-left: 10px;
    }
  }
`;
