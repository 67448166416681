import { Container } from "./styles";
import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { LoaderPopup } from "../LoaderPopup";
import { decodeJSON } from "../../utils/validations";
import { getImageURL } from "../../utils/files";
import { Image, CustomIcon } from "contentoh-components-library";
import { faUser as IconUser } from "@fortawesome/free-solid-svg-icons";

export const Profile = () => {
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = decodeJSON(sessionStorage.getItem("user"));

  const reload = () => {
    sessionStorage.clear();
    history.push({ pathname: "/" });
    setLoading(false);
    window.location.reload();
  };

  const closeMenu = (e) => {
    if (!e.target.closest("#select-option") && showMenu) {
      setShowMenu(false);
      document.removeEventListener("click", closeMenu);
    }
  };
  useEffect(() => {
    if (showMenu) {
      document.addEventListener("click", closeMenu);
    }
  }, [showMenu]);

  return (
    <>
      <Container
        onClick={(e) => {
          e.stopPropagation();
          setShowMenu(!showMenu);
          if (showMenu) {
            document.removeEventListener("click", closeMenu, false);
            setShowMenu(false);
          }
        }}
      >
        <Image
          className="container-imgProfile"
          width={"25px"}
          sizeLoading={12}
          borderType={"circle"}
          src={getImageURL(
            process.env.REACT_APP_IMAGES_PROFILE_BUCKET,
            `id-${user?.userId}/${user?.userId}.png`,
            25,
            25
          )}
          componentDefault={
            <CustomIcon
              className="icon-imgProfile"
              type={"whiteS2"}
              transparent
              size={13}
              borderType={"circle"}
              icon={IconUser}
            />
          }
        />

        <p>{user?.name}</p>

        {showMenu && (
          <div id="select-option" className="upload-image-menu">
            {/* <p>Mi cuenta</p>
          <p>Ajustes</p> */}
            <p
              onClick={async (event) => {
                setLoading(true);
                // event.preventDefault();
                // try {  
                await Auth.signOut({ global: true });
                reload();
                // } catch (error) {
                //   reload();
                //   console.log("error signing out: ", error);
                // }
              }}
            >
              Cerrar sesión
            </p>
          </div>
        )}
      </Container>
      {loading && <LoaderPopup titleLoader="Cerrar Sesión" />}
    </>
  );
};
