import { Container } from "./styles";
import { useEffect, useState } from "react";
import { Loading } from "../Loading";
import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";
import { EmptyPage } from "../EmptyPage";
import { ButtonPlay } from "../ButtonPlay";
import { fetchDELETE, fetchPUT } from "../../utils/handle_http";
import {
  encodeUriJson,
  isArrayEmpty,
  isObject,
  isStringEmpty,
  isValidNaturalNumber,
} from "../../utils/validations";
import { getCustomDate } from "../../utils/dates";
import OptionMenu from "../OptionMenu";
import {
  faPen as IconEdit,
  faTrash as IconDelete,
  faImage as IconImageFill,
} from "@fortawesome/free-solid-svg-icons";
import { faImage as IconImage } from "@fortawesome/free-regular-svg-icons";
import {
  Modal,
  ButtonV2,
  Tooltip,
  SelectV2,
  Image as CustomImage,
  CustomIcon,
  Chat,
} from "contentoh-components-library";
import {
  getBase64,
  getDataBase64,
  getDataBuffer,
  getFileExtension,
  getImageSize,
} from "../../utils/files";
import { getSession } from "../../utils/session";
import useScreenSizes from "../../hooks/useScreenSizes";
import { ButtonReturn } from "../ButtonReturn";

const S3_BUCKET = process.env.REACT_APP_IMAGES_BUCKET;
const REGION = "us-east-1";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_KUTS3,
  secretAccessKey: process.env.REACT_APP_AKUTS3,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

// selected ticket
export default function SelectedTicket(props) {
  const {
    id, // number
    ownerUser, // { id: number , name: string }
    createdByUser, // { id: number , name: string }
    assignedUser, // { id: number , name: string }
    company, // { id: number , name: string , isRetailer: boolean }
    title, // string
    description, // string
    status, // (string) => "COMPLETED" | "PENDING" | "IN_PROGRESS"
    urgency, // (string) => "LOW" | "MEDIUM" | "HIGH"
    creationDate, // string
    completedDate, // string
    startDate, // string
    updateDate, // string
    isPlay, // boolean
    loading, // boolean
    subscriptionId, // number
    dataPlay, // { isTicket: boolean , isTask: boolean }
    urgencySelectList, // [{label , value} , ...]
    responsibleSelectList, // [{label , value} , ...]
    onClickSubscribeButton /* ({
      action: "ADD" | "DELETE" | "ERROR"
      typeId: ID del ticket o proyecto (cuando action es "ADD" | "DELETE") 
      id: ID de la subscripcion (cuando action es "ADD" | "DELETE")
      message: (cuando action es "ERROR")
      errorDetail: (cuando action es "ERROR")
    }) => {} */,
    onUpdate, // (ticket, subscription, users, company) => {}
    onDelete, // () => {},
    onUpdateStatusPlay, // (ticket, subscription, users, company, action) => {}
    onNewAlert, // (title, message, icon) => {}  -- evento para mostrar un modal de alerta
    type,
    ticketCompany, // id de la compañía que creo el ticket
  } = props;

  const [editMode, setEditMode] = useState(false);
  const [urgencyList, setUrgencyList] = useState([]);
  const [selectedUrgency, setSelectedUrgency] = useState({});
  const [responsibleList, setResponsibleList] = useState([]);
  const [selectedResponsible, setSelectedResponsible] = useState({});
  const [inputTitle, setInputTitle] = useState("");
  const [inputDescription, setInputDescription] = useState("");
  const [inputCommentDelete, setInputCommentDelete] = useState("");
  const [inputCommentFinish, setInputCommentFinish] = useState("");
  const [loadingPage, setLoadingPage] = useState(false);
  const [refreshChat, setRefreshChat] = useState(false);
  const [disabledSaveButton, setDisabledSaveButton] = useState(false);
  const [disabledFinishButton, setDisabledFinishButton] = useState(true);
  const [action, setAction] = useState();
  const [showTicket, setShowTicket] = useState(false);
  const handleClick = () => {
    setShowTicket(!showTicket);
  };
  const [modalWarning, setModalWarning] = useState({
    show: false,
    title: "",
    message: "",
    labelCancelButton: "",
    showPauseButton: undefined,
    showStartButton: undefined,
    showRestartButton: undefined,
    showSaveButton: undefined,
  });
  const [showModalFinishTicket, setShowModalFinishTicket] = useState(false);
  const [showModalDeleteTicket, setShowModalDeleteTicket] = useState(false);
  const [imgsFinishTicket, setImgsFinishTicket] = useState([]);
  const [jsxImgsFinishTicket, setJsxImgsFinishTicket] = useState([]);
  const {isDesktop} = useScreenSizes();

  const [selectedType, setSelectedType] = useState({});
  const [selectedComplexity, setSelectedComplexity] = useState({});
  const [itemsOriginSelect] = useState([
    { label: "Error de plataforma", value: "Error de plataforma" },
    { label: "Error del usuario", value: "Error del usuario" },
    { label: "Solicitud", value: "Solicitud" },
  ]);
  const [itemsComplexitySelect] = useState([
    { label: "Baja", value: "Baja" },
    { label: "Media", value: "Media" },
    { label: "Alta", value: "Alta" },
  ]);
  const actions = {
    CLICK_SUBSCRIPTION: 1,
    CLICK_SAVE: 2,
    CLICK_DELETE: 3,
    CLICK_START: 4,
    CLICK_RESTART: 5,
    CLICK_PAUSE: 6,
    CLICK_FINISH: 7,
  };
  const idUserSupport = JSON.parse(sessionStorage.getItem("user"))?.userId;

  useEffect(() => {
    if (loading !== undefined) setLoadingPage(loading);
  }, [loading]);

  useEffect(() => {
    setEditMode(false);
    setSelectedUrgency({});
    setSelectedResponsible({});
    setRefreshChat(true);
  }, [id]);

  useEffect(() => {
    if (refreshChat) setRefreshChat(false);
  }, [refreshChat]);

  // establecer el valor inicial del input title
  useEffect(() => {
    if (isStringEmpty(title)) setInputTitle("");
    else setInputTitle(title);
  }, [title]);

  // establecer el valor inicial del input descripcion
  useEffect(() => {
    if (isStringEmpty(description)) setInputDescription("");
    else setInputDescription(description);
  }, [description]);

  // habilitar / desabilitar boton guardar cada que se haga un cambio
  useEffect(() => {
    validateSave();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputTitle, inputDescription, selectedUrgency, selectedResponsible]);

  // limpiar cambios realizados en el modo editar cuando pasamos al modo vista
  useEffect(() => {
    if (!editMode) {
      if (isStringEmpty(title)) setInputTitle("");
      else setInputTitle(title);

      if (isStringEmpty(description)) setInputDescription("");
      else setInputDescription(description);

      changeUrgencyList();
      setSelectedUrgency({});
      changeResponsibleList();
      setSelectedResponsible({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  // establecer la lista de urgencias del select
  useEffect(() => {
    changeUrgencyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urgencySelectList, urgency]);

  // establecer la lista de usuarios asignables del select
  useEffect(() => {
    changeResponsibleList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responsibleSelectList, assignedUser]);

  // cada que cambien las imgs seleccionadas del modal finish ticket
  useEffect(() => {
    renderImgsFinishTicket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgsFinishTicket]);

  // cada que se ejecute una accion al backend
  useEffect(() => {
    switch (action) {
      case actions.CLICK_SAVE:
        onUpdateTicket();
        break;
      case actions.CLICK_DELETE:
        onDeleteTicket();
        break;
      case actions.CLICK_START:
      case actions.CLICK_RESTART:
      case actions.CLICK_PAUSE:
        onUpdateStatusPlayTicket();
        break;
      case actions.CLICK_FINISH:
        onFinishTicket();
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  //validar si existe valor seleccionado en complejidad y tipo de ticket
  useEffect(() => {
    let disabled = false;
    if (idUserSupport !== 1785) {
      if (!Object.keys(selectedType).length) disabled = true;
      if (!Object.keys(selectedComplexity).length) disabled = true;
    }
    setDisabledFinishButton(disabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedType, selectedComplexity]);

  /*------------------------------------
  FUNCIONES
  -------------------------------------- */
  const validateSave = () => {
    // validar si title es diferente
    if (!isStringEmpty(inputTitle)) {
      if (isStringEmpty(title) || title.trim() !== inputTitle.trim()) {
        setDisabledSaveButton(false);
        return;
      }
    }
    // validar si description es diferente
    if (!isStringEmpty(inputDescription)) {
      if (
        isStringEmpty(description) ||
        description.trim() !== inputDescription.trim()
      ) {
        setDisabledSaveButton(false);
        return;
      }
    }
    // validar si urgency es diferente
    if (Object.keys(selectedUrgency || {}).length) {
      setDisabledSaveButton(false);
      return;
    }
    // validar si assigned user es diferente
    if (Object.keys(selectedResponsible || {}).length) {
      setDisabledSaveButton(false);
      return;
    }

    // cuando se tienen los mismos valores
    setDisabledSaveButton(true);
  };

  const changeUrgencyList = () => {
    if (isArrayEmpty(urgencySelectList)) setUrgencyList([]);
    else {
      if (["LOW", "MEDIUM", "HIGH"].includes(urgency)) {
        const listCopy = urgencySelectList.filter((item) => {
          if (item.value === urgency) return false;
          return true;
        });
        setUrgencyList(listCopy);
      } else {
        setUrgencyList(urgencySelectList.slice());
      }
    }
  };

  const changeResponsibleList = () => {
    if (isArrayEmpty(responsibleSelectList)) setResponsibleList([]);
    else {
      if (isObject(assignedUser) && isValidNaturalNumber(assignedUser.id)) {
        const listCopy = responsibleSelectList.filter((item) => {
          if (item.value === assignedUser.id) return false;
          return true;
        });
        setResponsibleList(listCopy);
      } else {
        setResponsibleList(responsibleSelectList.slice());
      }
    }
  };

  const deleteImgFinishTicket = async (index) => {
    let newImgs = imgsFinishTicket.slice();
    newImgs.splice(index, 1);
    setImgsFinishTicket(newImgs);
  };

  const sendImagesAWS = async () => {
    const images = imgsFinishTicket.slice();
    // subir archivos a AWS
    const customImages = [];
    try {
      const imgUploadRequests = [];
      for (const img of images) {
        let errorMessage;
        // obtener base64
        const fullBase64 = await getBase64(img);
        if (!fullBase64) {
          errorMessage = "No se pudo decodificar la imagen";
        }
        // obtener buffer
        let dataBuffer;
        if (!errorMessage)
          dataBuffer = getDataBuffer(getDataBase64(fullBase64));
        if (!dataBuffer) {
          errorMessage = "No se pudo decodificar la imagen";
        }
        // obtener la key
        let imgKey = "chat/ticket/";
        if (isValidNaturalNumber(id)) imgKey += `${id}/`;
        else errorMessage = "El ID del ticket no es valido";
        if (!errorMessage) {
          const today = new Date();
          imgKey += `${uuidv4()}-${today.getTime()}.${getFileExtension(
            img.name
          )}`;
        } else {
          imgKey = undefined;
        }
        // enviar file a AWS
        customImages.push({ key: imgKey, errorOwn: errorMessage });
        const paramsCreate = {
          ACL: "public-read",
          Body: dataBuffer,
          Bucket: S3_BUCKET,
          Key: imgKey,
        };
        imgUploadRequests.push(myBucket.putObject(paramsCreate).promise());
      }
      const responseAWS = await Promise.allSettled(imgUploadRequests);
      responseAWS.forEach((responseImg, index) => {
        if (responseImg.status === "rejected") {
          customImages[index].errorAWS = responseImg.reason.message;
          customImages[index].uploaded = false;
        } else {
          customImages[index].uploaded = true;
        }
      });
    } catch (err) {
      return {
        error: true,
        message: "hubo un problema al guardar las imagenes",
        errorDetail: `${err.message}. Reporta esto a TI`,
      };
    }
    // obtener las images que se van a subir a la BD
    const items = [];
    const failedImages = [];
    let errorDetailImgs = "";
    for (let index = 0; index < customImages.length; index++) {
      const img = customImages[index];
      // imagen subida a AWS
      if (img.uploaded) {
        const imgSize = await getImageSize(images[index]);
        if (imgSize) {
          const item = {
            key: img.key,
            name: images[index].name,
            width: imgSize.width,
            height: imgSize.height,
          };
          items.push(item);
        }
        // cuando fallo la obtencion de dimension image
        else {
          if (!failedImages.length) {
            errorDetailImgs = `${images[index].name}:
            Error: No se pudieron obtener las dimensiones`;
          } else {
            errorDetailImgs += `${"\n\n"}${images[index].name}:
            Error: No se pudieron obtener las dimensiones`;
          }
          failedImages.push(images[index]);
        }
      }
      // imagen NO subida a AWS
      else {
        if (!failedImages.length) {
          errorDetailImgs = `${images[index].name}:
          Error: ${img.errorOwn ? img.errorOwn : img.errorAWS}`;
        } else {
          errorDetailImgs += `${"\n\n"}${images[index].name}:
          Error: ${img.errorOwn ? img.errorOwn : img.errorAWS}`;
        }
        failedImages.push(images[index]);
      }
    }
    return {
      error: false,
      items,
      failedImages,
      errorDetailImgs,
    };
  };

  /*------------------------------------
  HANDLERS
  -------------------------------------- */
  const onDeleteTicket = async () => {
    const paramsBody = {
      id: JSON.stringify(id),
      comment: inputCommentDelete.trim(),
    };
    const paramsHeaders = {
      Authorization: sessionStorage.getItem("jwt"),
    };
    const response = await fetchDELETE(
      process.env.REACT_APP_TICKET_ENDPOINT,
      paramsBody,
      paramsHeaders
    );
    setAction();
    setInputCommentDelete("");
    // cuando hay error
    if (!response.body) {
      onNewAlert(response.message, response.errorDetail, "info");
    }
    // success
    else {
      onNewAlert("El ticket ha sido eliminado", "", "success");
      onDelete();
    }
  };

  const onFinishTicket = async () => {
    // obtener imagenes
    let imgs = [];
    let failedImages = {
      images: [],
      errorDetail: "",
    };
    if (imgsFinishTicket.length) {
      const responseAWS = await sendImagesAWS();
      if (responseAWS.error) {
        onNewAlert(responseAWS.message, responseAWS.errorDetail, "info");
        setAction();
        return;
      }
      imgs = responseAWS.items;
      failedImages = {
        images: responseAWS.failedImages,
        errorDetail: responseAWS.errorDetailImgs,
      };
      if (failedImages.images.length) {
        onNewAlert(
          "No fue posible cerrar el ticket. Algunas imagenes no " +
            "pudieron guardarse",
          failedImages.errorDetail,
          "info"
        );
        setAction();
        return;
      }
    }
    const arrayType = Object.keys(selectedType || {});
    const type = arrayType.length ? arrayType[0] : undefined;
    const arrayComplexity = Object.keys(selectedComplexity || {});
    const complexity = arrayType.length ? arrayComplexity[0] : undefined;

    // hacer peticion HTTP
    const paramsBody = {
      id: JSON.stringify(id),
      action: "FINISH",
      comment: inputCommentFinish.trim(),
      option_type: type,
      complexity,
      imgs: encodeUriJson(imgs),
    };
    const paramsHeaders = {
      Authorization: sessionStorage.getItem("jwt"),
    };
    const response = await fetchPUT(
      process.env.REACT_APP_UPDATE_STATUS_TICKET_ENDPOINT,
      paramsBody,
      paramsHeaders
    );
    // cuando hay error
    if (!response.body) {
      setAction();
      onNewAlert(response.message, response.errorDetail, "info");
      return;
    }
    // success
    onNewAlert(
      "Ticket actualizado",
      "Se ha finalizado el trabajo de este ticket",
      "success"
    );
    setInputCommentFinish("");
    setImgsFinishTicket([]);
    onUpdateStatusPlay(
      response.body.ticket,
      response.body.subscription,
      response.body.users,
      response.body.company,
      "FINISH"
    );
    setRefreshChat(true);
    setAction();
  };

  const onUpdateTicket = async () => {
    // datos a enviar
    let newTitle;
    let newDescription;
    let newAssignedUser;
    let newUrgency;

    // change title?
    if (
      !isStringEmpty(inputTitle) &&
      (isStringEmpty(title) || title.trim() !== inputTitle.trim())
    ) {
      newTitle = inputTitle.trim();
    }
    // change description?
    if (
      !isStringEmpty(inputDescription) &&
      (isStringEmpty(description) ||
        description.trim() !== inputDescription.trim())
    ) {
      newDescription = inputDescription.trim();
    }
    // change urgency ?
    const urg = Object.keys(selectedUrgency || {});
    if (urg.length) newUrgency = urg[0];

    // change assigned user ?
    const resp = Object.keys(selectedResponsible || {});
    if (resp.length) newAssignedUser = Number(resp[0]);

    // hacer peticion HTTP
    const paramsBody = {
      id: JSON.stringify(id),
      newTitle,
      newDescription,
      newAssignedUser: JSON.stringify(newAssignedUser),
      newUrgency,
    };
    const paramsHeaders = {
      Authorization: sessionStorage.getItem("jwt"),
    };
    const response = await fetchPUT(
      process.env.REACT_APP_TICKET_ENDPOINT,
      paramsBody,
      paramsHeaders
    );
    // cuando hay error
    if (!response.body) {
      onNewAlert(response.message, response.errorDetail, "info");
    }
    // success
    else {
      onNewAlert("Cambios guardados con exito", "", "success");
      onUpdate(
        response.body.ticket,
        response.body.subscription,
        response.body.users,
        response.body.company
      );
    }
    setRefreshChat(true);
    setAction();
    setEditMode(false);
  };

  const onUpdateStatusPlayTicket = async () => {
    // datos a enviar
    let ACTION;
    let message;
    if (action === actions.CLICK_PAUSE) {
      ACTION = "PAUSE";
      message = "El ticket ha sido pausado";
    } else if (action === actions.CLICK_START) {
      ACTION = "START";
      message = "El ticket ahora se esta trabajando";
    } else if (action === actions.CLICK_RESTART) {
      ACTION = "RESTART";
      message = "Se ha continuado con el trabajo de este ticket";
    }
    const paramsBody = { id: JSON.stringify(id), action: ACTION };
    const paramsHeaders = { Authorization: sessionStorage.getItem("jwt") };
    const response = await fetchPUT(
      process.env.REACT_APP_UPDATE_STATUS_TICKET_ENDPOINT,
      paramsBody,
      paramsHeaders
    );
    // cuando hay error
    if (!response.body) {
      setAction();
      onNewAlert(response.message, response.errorDetail, "info");
      return;
    }
    // success
    onNewAlert("Ticket actualizado", message, "success");
    onUpdateStatusPlay(
      response.body.ticket,
      response.body.subscription,
      response.body.users,
      response.body.company,
      ACTION
    );
    setRefreshChat(true);
    setAction();
  };

  const onClickButtonPlay = (action) => {
    const configModal = {
      show: true,
      title: "",
      message: "",
      showPauseButton: undefined,
      showStartButton: undefined,
      showRestartButton: undefined,
      showSaveButton: undefined,
    };
    switch (action) {
      case "START": //--------------------------------------
        if (dataPlay?.isTicket || dataPlay?.isTask) {
          configModal.title = `Ya existe ${
            dataPlay.isTicket ? "un ticket abierto" : "una tarea abierta"
          }`;
          configModal.message =
            "¿Quieres ponerlo en pausa e iniciar este nuevo ticket?";
        } else {
          configModal.title = `Iniciar ticket`;
          configModal.message = "¿Quieres iniciar este nuevo ticket?";
        }
        configModal.showStartButton = true;
        break;

      case "RESTART": //--------------------------------------
        if (dataPlay?.isTicket || dataPlay?.isTask) {
          configModal.title = `Ya existe ${
            dataPlay.isTicket ? "un ticket abierto" : "una tarea abierta"
          }`;
          configModal.message =
            "¿Quieres ponerlo en pausa y reanudar este ticket?";
        } else {
          configModal.title = `Reanudar ticket`;
          configModal.message = "¿Quieres reanudar este ticket?";
        }
        configModal.showRestartButton = true;
        break;

      case "PAUSE": //--------------------------------------
        configModal.title = "Pausar ticket abierto";
        configModal.message = "¿Quieres ponerlo en pausa?";
        configModal.showPauseButton = true;
        break;

      case "FINISH": //--------------------------------------
        setShowModalFinishTicket(true);
        break;

      default:
        break;
    }
    if (action !== "FINISH") setModalWarning(configModal);
  };

  /*------------------------------------
  FUNCIONES DE RENDERIZADO
  -------------------------------------- */
  const renderTitle = () => {
    return editMode ? (
      <input
        className="inputTitle"
        placeholder="Título del ticket"
        value={inputTitle}
        onChange={(event) => {
          setInputTitle(event.target.value);
        }}
      />
    ) : (
      <label className="label-title">
        {!isStringEmpty(title) ? title : "Título no definido"}
      </label>
    );
  };

  const renderLabelDate = () => {
    // obtener el formato de las fechas
    let start_date = getCustomDate(startDate);
    let update_date = getCustomDate(updateDate);
    let end_date = getCustomDate(completedDate);
    let creation_date = getCustomDate(creationDate);

    // componente fecha tooltip
    const renderDateTooltip = (date, title, isVisibleDate) => {
      return isStringEmpty(date) ? null : (
        <div className="containerDate">
          <label className="label-title">{title}</label>
          <label
            className={"label-date" + (isVisibleDate ? " visibleDate" : "")}
          >
            {date}
          </label>
        </div>
      );
    };

    // generar el JSX
    return (
      <Tooltip
        componentTooltip={
          <>
            {/* creation date */}
            {renderDateTooltip(creation_date, "Fecha de creación", true)}

            {/* start date */}
            {renderDateTooltip(start_date, "Fecha de inicio", false)}

            {/* update date */}
            {renderDateTooltip(update_date, "Ultima actualización", false)}

            {/* end date */}
            {renderDateTooltip(end_date, "Fecha de completado", false)}
          </>
        }
        className="label-date"
        classNameTooltip="tooltip-labelDate"
        position={"rightCenter"}
        addArrow={false}
        transitionType={"zoom"}
        followCursor={false}
      >
        {isStringEmpty(creation_date) ? "--/--/--" : creation_date}
      </Tooltip>
    );
  };

  const renderAssignedUser = () => {
    if (editMode) {
      // select de usuarios asignables
      return (
        <SelectV2
          items={responsibleList}
          triggerType={!isDesktop ? "click" : "hover"}
          selectButton={{
            className: "btnSelectResponsible",
            type: "whiteS3",
            label: "Responsable",
            size: 10,
            disabled: action ? true : false,
          }}
          defaultItem={
            isObject(assignedUser) && isValidNaturalNumber(assignedUser.id)
              ? {
                  label: isStringEmpty(assignedUser.name)
                    ? "Nombre no encontrado"
                    : assignedUser.name,
                  itemType: "labelOnly",
                  showLabelInSelect: true,
                }
              : undefined
          }
          typeSelectItems={"labelOnly"}
          borderType={undefined}
          maxWidthSelect="120px"
          maxHeightDropdown={"50vh"}
          alignmentItemsOverflow={"rows"}
          classNameSelect="selectResponsible"
          classNameDropdown="dropdownResponsible"
          onClick={!isDesktop ? "dropdownResponsible" : undefined}
          onChange={(selectedItems) => {
            setSelectedResponsible(selectedItems);
          }}
        />
      );
    } else {
      let label = "Ninguno";
      let labelTooltip = "El ticket no ha sido asignado a nadie";
      if (isObject(assignedUser) && isValidNaturalNumber(assignedUser.id)) {
        label = assignedUser.name;
        labelTooltip = undefined;
        if (isStringEmpty(label)) {
          labelTooltip =
            "El ticket esta asignado pero no se encontró el " +
            "nombre del usuario";
          label = "Nombre no encontrado";
        }
      }
      return (
        <Tooltip
          componentTooltip={
            <>
              <label className="label-title">Usuario responsable</label>
              {isStringEmpty(labelTooltip) ? null : (
                <label className="label-info">{labelTooltip}</label>
              )}
            </>
          }
          className={"label-assignedUser"}
          classNameTooltip="tooltip-assignedUser"
          position={"bottomCenter"}
          addArrow={false}
          transitionType={"zoom"}
          followCursor={false}
        >
          {label}
        </Tooltip>
      );
    }
  };

  const renderStatusPlay = () => {
    const session = getSession();
    if (
      !editMode &&
      isValidNaturalNumber(session?.id) &&
      isValidNaturalNumber(assignedUser?.id) &&
      session.id === assignedUser.id
    ) {
      return (
        <ButtonPlay
          status={
            status === "PENDING"
              ? "NEW"
              : status === "IN_PROGRESS"
              ? "IN_PROCESS"
              : status === "COMPLETED"
              ? "COMPLETED"
              : undefined
          }
          statusPlay={isPlay}
          allDisabled={
            action !== actions.CLICK_START &&
            action !== actions.CLICK_RESTART &&
            action !== actions.CLICK_PAUSE &&
            action !== actions.CLICK_FINISH &&
            action !== undefined
              ? true
              : false
          }
          loadingButton={
            action === actions.CLICK_START
              ? "START"
              : action === actions.CLICK_RESTART
              ? "RESTART"
              : action === actions.CLICK_PAUSE
              ? "PAUSE"
              : action === actions.CLICK_FINISH
              ? "FINISH"
              : undefined
          }
          onClick={onClickButtonPlay}
        />
      );
    } else return null;
  };

  const renderStatus = () => {
    let labelStatus = "No definido";
    let className = "label-status";
    let labelTooltip = "Estatus del ticket no definido";
    switch (status) {
      case "PENDING":
        labelStatus = "Nuevo";
        className += " statusNew";
        labelTooltip = "Ticket pendiente por resolver";
        break;
      case "COMPLETED":
        labelStatus = "Cerrado";
        className += " statusCompleted";
        labelTooltip = "Ticket terminado";
        break;
      case "IN_PROGRESS":
        labelStatus = "En proceso";
        className += " statusInProcess";
        labelTooltip = "Este ticket se esta trabajando";
        break;
      default:
        break;
    }

    return (
      <Tooltip
        componentTooltip={labelTooltip}
        className={className}
        classNameTooltip="tooltip-status"
        position={"bottomCenter"}
        addArrow={false}
        transitionType={"zoom"}
        followCursor={false}
      >
        {labelStatus}
      </Tooltip>
    );
  };

  const renderUrgency = () => {
    let labelUrgency = "No definido";
    let className = "label-urgency";
    let labelTooltip = "Urgencia del ticket no definido";
    let defaultItem = undefined;
    let classNameBtnSelect = "btnSelectUrgency";
    switch (urgency) {
      case "LOW":
        labelUrgency = "Baja";
        className += " urgencyLow";
        labelTooltip = "Ticket con poca prioridad";
        defaultItem = "Urgencia baja";
        classNameBtnSelect += " urgencyLow";
        break;
      case "MEDIUM":
        labelUrgency = "Media";
        className += " urgencyMedium";
        labelTooltip = "Ticket sin tanta urgencia";
        defaultItem = "Urgencia media";
        classNameBtnSelect += " urgencyMedium";
        break;
      case "HIGH":
        labelUrgency = "Alta";
        className += " urgencyHigh";
        labelTooltip = "Ticket que urge trabajar";
        defaultItem = "Urgencia alta";
        classNameBtnSelect += " urgencyHigh";
        break;
      default:
        break;
    }
    const urg = Object.keys(selectedUrgency || {});
    if (urg.length === 1) {
      classNameBtnSelect = "btnSelectUrgency";
      switch (urg[0]) {
        case "LOW":
          classNameBtnSelect += " urgencyLow";
          break;
        case "MEDIUM":
          classNameBtnSelect += " urgencyMedium";
          break;
        case "HIGH":
          classNameBtnSelect += " urgencyHigh";
          break;
        default:
          break;
      }
    }

    return editMode ? (
      <>
        {/* select de urgencias */}
        <SelectV2
          items={urgencyList}
          triggerType={!isDesktop ? "click" : "hover"}
          selectButton={{
            className: classNameBtnSelect,
            type: "whiteS3",
            label: "Urgencia",
            size: 10,
            disabled: action ? true : false,
          }}
          defaultItem={
            !defaultItem
              ? undefined
              : {
                  label: defaultItem,
                  itemType: "labelOnly",
                  showLabelInSelect: true,
                }
          }
          typeSelectItems={"labelOnly"}
          borderType={undefined}
          maxWidthSelect="120px"
          maxHeightDropdown={"50vh"}
          alignmentItemsOverflow={"rows"}
          classNameSelect="selectUrgency"
          classNameDropdown="dropdownSelectUrgency"
          onClick={!isDesktop ? "dropdownSelectUrgency" : undefined}
          onChange={(selectedItems) => {
            setSelectedUrgency(selectedItems);
          }}
        />
      </>
    ) : (
      <Tooltip
        componentTooltip={labelTooltip}
        className={className}
        classNameTooltip="tooltip-urgency"
        position={"bottomCenter"}
        addArrow={false}
        transitionType={"zoom"}
        followCursor={false}
      >
        {labelUrgency}
      </Tooltip>
    );
  };

  const renderCompanyName = () => {
    let companyType = "Content-oh!";
    if (company?.isRetailer === true) companyType = "Cadena";
    else if (company?.isRetailer === false) companyType = "Proveedor";
  
    let bothUsers = false;
    if (
      isValidNaturalNumber(ownerUser?.id) &&
      isValidNaturalNumber(createdByUser?.id) &&
      ownerUser.id !== createdByUser.id
    ) {
      bothUsers = true;
    }
  
    // renderizado de los users
    let createdByUserJSX = (
      <label className="label-info">
        {isStringEmpty(createdByUser?.name)
          ? "Nombre no definido"
          : createdByUser.name}
      </label>
    );
    let createdByUserEmail = (
      <label className="label-info">
        {isStringEmpty(createdByUser?.email)
          ? "Nombre no definido"
          : createdByUser.email}
      </label>
    );
    let ownerUserJSX = undefined;
    if (bothUsers) {
      ownerUserJSX = (
        <label className="label-info">
          {isStringEmpty(ownerUser?.name)
            ? "Nombre no definido"
            : ownerUser.name}
        </label>
      );
    }
  
    return (
      <>
        {isDesktop ? (
          <Tooltip
            componentTooltip={
              <>
                {/* tipo de compañia */}
                <div className="containerItem">
                  <label className="label-title">Tipo de compañia</label>
                  <label className="label-info">{companyType}</label>
                </div>
                {/* user que lo creó */}
                <div className="containerItem">
                  <label className="label-title">Creado por</label>
                  {createdByUserJSX}
                  {createdByUserEmail}
                </div>
                {/* user que lo solicitó */}
                {ownerUserJSX && (
                  <div className="containerItem">
                    <label className="label-title">Solicitado por</label>
                    {ownerUserJSX}
                  </div>
                )}
              </>
            }
            className={"label-companyName"}
            classNameTooltip="tooltip-companyName"
            position={"bottomCenter"}
            addArrow={false}
            transitionType={"zoom"}
            followCursor={false}
          >
            {isStringEmpty(company?.name)
              ? "compañia no identificada"
              : company.name}
          </Tooltip>
        ) : (
          <label className="label-companyName">
            {isStringEmpty(createdByUser?.name)
              ? "correo no identificado"
              : createdByUser.name}
          </label>
        )}
      </>
    );
  };
  

  const renderTicketType = () => {
    let typeName;
    switch (type) {
      case "INNOVATION":
        typeName = "Sugerencia de innovación";
        break;
      case "IMPROVEMENT":
        typeName = "Mejora";
        break;
      case "ERROR":
        typeName = "Error de plataforma";
        break;
      case "RULE":
        typeName = "Actualizacion de sabana";
        break;
      default:
        typeName = "Soporte/Mantenimiento";
        break;
    }

    return (
      <Tooltip
        componentTooltip={
          <div className="containerItem">
            <label className="label-title">Tipo de Ticket</label>
            {typeName}
          </div>
        }
        className={"label-companyName"}
        classNameTooltip="tooltip-companyName"
        position={"bottomCenter"}
        addArrow={false}
        transitionType={"zoom"}
        followCursor={false}
      >
        {typeName}
      </Tooltip>
    );
  };

  const renderModalButtons = () => {
    const hideModalWarning = () => {
      setModalWarning({
        show: false,
        title: "",
        message: "",
      });
    };
    const buttons = [
      <ButtonV2
        key={"buttonDefault"}
        className="buttonDefault"
        type="white"
        label={
          !isStringEmpty(modalWarning.labelCancelButton)
            ? modalWarning.labelCancelButton
            : "Cancelar"
        }
        borderType="oval"
        onClick={(event) => {
          hideModalWarning();
        }}
      />,
    ];

    if (modalWarning.showPauseButton) {
      buttons.push(
        <ButtonV2
          key={"buttonPause"}
          className="buttonPause"
          type="pink"
          label="Pausar"
          borderType="oval"
          onClick={(event) => {
            hideModalWarning();
            setAction(actions.CLICK_PAUSE);
          }}
        />
      );
    } else if (modalWarning.showStartButton) {
      buttons.push(
        <ButtonV2
          key={"buttonStart"}
          className="buttonStart"
          type="pink"
          label={
            dataPlay?.isTicket || dataPlay?.isTask
              ? "Pausar e iniciar"
              : "Iniciar"
          }
          borderType="oval"
          onClick={(event) => {
            hideModalWarning();
            setAction(actions.CLICK_START);
          }}
        />
      );
    } else if (modalWarning.showRestartButton) {
      buttons.push(
        <ButtonV2
          key={"buttonRestart"}
          className="buttonRestart"
          type="pink"
          label={
            dataPlay?.isTicket || dataPlay?.isTask
              ? "Pausar y reanudar"
              : "Reanudar"
          }
          borderType="oval"
          onClick={(event) => {
            hideModalWarning();
            setAction(actions.CLICK_RESTART);
          }}
        />
      );
    } else if (modalWarning.showSaveButton) {
      buttons.push(
        <ButtonV2
          key={"buttonSave"}
          className="buttonSave"
          type="pink"
          label="Guardar"
          borderType="oval"
          onClick={(event) => {
            hideModalWarning();
            setAction(actions.CLICK_SAVE);
          }}
        />
      );
    }
    return buttons;
  };

  // renderizar las imagenes del preview en el modal finish ticket
  const renderImgsFinishTicket = async () => {
    const jsxImgs = [];
    for (let index = 0; index < imgsFinishTicket.length; index++) {
      const img = imgsFinishTicket[index];
      const base64 = await getBase64(img);
      jsxImgs.push(
        <Tooltip
          key={"imgFinish-" + index}
          className={"containerItem-img"}
          classNameTooltip="tooltip-containerItemImg"
          position={"rightCenter"}
          addArrow={false}
          transitionType={"zoom"}
          followCursor={false}
          componentTooltip={img.name}
        >
          {/* imagen preview */}
          <CustomImage
            className={"containerImg"}
            sizeLoading={30}
            colorLoading={undefined}
            src={base64}
            componentDefault={
              <CustomIcon
                className="icon-img"
                size={50}
                icon={IconImageFill}
                type={"pink"}
                transparent
              />
            }
          />
          {/* container boton delete */}
          <div className="container-delete">
            <ButtonV2
              className="btn-delete"
              type={"white"}
              label={"Eliminar"}
              size={8}
              borderType={"oval"}
              icon={IconDelete}
              isLoading={undefined}
              onClick={async (event) => deleteImgFinishTicket(index)}
            />
          </div>
        </Tooltip>
      );
    }
    setJsxImgsFinishTicket(jsxImgs);
  };

  // selector de imagenes del modal finish ticket
  const openChooserFinishTicket = () => {
    const selectedImgs = [];
    let rejectedFiles = "";
    const chooser = document.createElement("input");
    chooser.type = "file";
    chooser.style.display = "none";
    chooser.multiple = true;
    chooser.accept = "image/*";
    chooser.onchange = (event) => {
      for (const file of chooser.files) {
        if (file.type.split("/")[0] === "image") {
          selectedImgs.push(file);
        } else {
          rejectedFiles += "\n" + file.name;
        }
      }
      // archivos rechazados ??
      if (!isStringEmpty(rejectedFiles)) {
        setModalWarning({
          show: true,
          title: "Archivos incorrectos",
          message:
            "Los siguientes archivos no fueron agregados al preview " +
            "porque no son un tipo de imagen valido:" +
            rejectedFiles,
          labelCancelButton: "Aceptar",
        });
      }
      // archivos por add ??
      if (selectedImgs.length) {
        let newImgs = imgsFinishTicket.slice();
        newImgs = newImgs.concat(selectedImgs);
        setImgsFinishTicket(newImgs);
      }
    };
    document.body.appendChild(chooser);
    chooser.click();
    document.body.removeChild(chooser);
  };

  const session = getSession();
  // permiso para editar y eliminar ?
  let showMenu = false;
  if (session?.isUserAdminTech && ["IN_PROGRESS", "PENDING"].includes(status)) {
    showMenu = true;
  }

  const dataChat = {
    id,
    ticketOwnerUserId: ownerUser?.id,
    statusTicket: status,
    ticketCompany,
    currentUser: {
      token: sessionStorage.getItem("jwt"),
      id: session?.id,
      companyId: session?.companyId,
      isUserTech: session?.isUserTech,
    },
  };

  return (
    <Container showTicket={showTicket}>
     {!isDesktop && loadingPage ? (
        <Loading />
      ) : isValidNaturalNumber(id) ? (
        <>
          {/* Datos del ticket */}
          <div className="containerData">
            {/* container header */}
            <div className="containerHeader">
              {/* container title y date */}
              <div className="container-info">
                {/* title */}
                {renderTitle()}
                {/* label date */}
                {renderLabelDate()}
              </div>

              {/* menu de opciones */}
              <OptionMenu
                positionDropdownMenu={"LEFT"}
                subscribeButton={
                  editMode
                    ? undefined
                    : {
                        id: subscriptionId,
                        type: "TICKET",
                        typeId: id,
                        disabled: action && action !== actions.CLICK_SUBSCRIPTION,
                        onClick: async (response) => {
                          setAction();
                          if (onClickSubscribeButton) {
                            await onClickSubscribeButton(response);
                          }
                        },
                        onClickBefore: () => {
                          setAction(actions.CLICK_SUBSCRIPTION);
                        },
                      }
                }
                saveButton={
                  showMenu && editMode
                    ? {
                        loading: action && action === actions.CLICK_SAVE,
                        disabled:
                          disabledSaveButton ||
                          (action && action !== actions.CLICK_SAVE),
                        onClick: (event) => {
                          setModalWarning({
                            show: true,
                            title: "Actualizar ticket",
                            message: "¿Está seguro de guardar los cambios?",
                            showSaveButton: true,
                          });
                        },
                      }
                    : undefined
                }
                menuItems={
                  !showMenu
                    ? undefined
                    : [
                        {
                          label: editMode
                            ? "Cancelar edición"
                            : "Editar ticket",
                          icon: IconEdit,
                          disabled: action ? true : false,
                          onClick: (event) => {
                            setEditMode(!editMode);
                          },
                        },
                        {
                          label: "Eliminar ticket",
                          icon: IconDelete,
                          disabled: action && action !== actions.CLICK_DELETE,
                          isLoading: action === actions.CLICK_DELETE,
                          onClick: () => setShowModalDeleteTicket(true),
                        },
                      ]
                }
          />
        </div>

        {/* contenedor de botones seccion 1 */}
        <div className="buttonsSection1">
          {/* usuario asignado */}
          {renderAssignedUser()}

          {/* botones para el manejo del status play */}
          {renderStatusPlay()}
        </div>

        <hr />

        <div className="buttonsSection2">
          {/* status legend */}
          {renderStatus()}

          {/* urgency */}
          {renderUrgency()}

          {/* companyName legend */}
          {renderCompanyName()}

          {/* type legend */}
          {renderTicketType()}
        </div>

        <hr />

        <textarea
          className="inputDescription"
          placeholder="Descripción del ticket"
          disabled={editMode ? false : true}
          value={inputDescription}
          onChange={(event) => {
            setInputDescription(event.target.value);
          }}
        />
      </div>

      {/* chat */}
      {refreshChat ? (
        <Loading />
      ) : (
        <Chat
          classNameContainerFixed="chatTicket"
          chatType="ticket"
          chatContainerType="fixed"
          chatData={dataChat}
        />
      )}
        </>
      ) : (
        <EmptyPage
          className="container-emptyPage"
          label="Selecciona un ticket para visualizarlo"
        />
      )}


      {/* modal finish */}
      {showModalFinishTicket && (
        <Modal
          className={"modalFinish"}
          show={showModalFinishTicket}
          title={"Cerrar ticket"}
          customComponent={
            <>
              {idUserSupport !== 1785 && (
                <>
                  <span id="sub-title">Tipo de ticket:</span>
                  <SelectV2
                    items={itemsOriginSelect}
                    triggerType="click"
                    selectButton={{
                      className: "btnSelectType",
                      type: "whiteS3",
                      label: "Selecciona una opcion",
                      size: 10,
                      disabled: action ? true : false,
                    }}
                    typeSelectItems="marginCheckbox"
                    maxHeightDropdown="40vh"
                    alignmentItemsOverflow="rows"
                    classNameSelect="selectType"
                    classNameDropdown="dropdownSelectType"
                    onChange={(selectedItems) => {
                      setSelectedType(selectedItems);
                    }}
                  />
                  <span id="sub-title">Complejidad:</span>
                  <SelectV2
                    items={itemsComplexitySelect}
                    triggerType="click"
                    selectButton={{
                      className: "btnSelectComplexity",
                      type: "whiteS3",
                      label: "Selecciona una opcion",
                      size: 10,
                      disabled: action ? true : false,
                    }}
                    typeSelectItems="marginCheckbox"
                    maxHeightDropdown="40vh"
                    alignmentItemsOverflow="rows"
                    classNameSelect="selectType"
                    classNameDropdown="dropdownSelectType"
                    onChange={(selectedItems) => {
                      setSelectedComplexity(selectedItems);
                    }}
                  />
                </>
              )}

              {/* input comentario */}
              <textarea
                className="inputCommentFinish"
                placeholder="Notas finales y comentarios..."
                rows={3}
                value={inputCommentFinish}
                onChange={(event) => {
                  setInputCommentFinish(event.target.value);
                }}
              />
              {/* contenedor de imagenes */}
              <div className="container-imgPreview">
                {/* boton selector */}
                <ButtonV2
                  className="buttonChooser"
                  type="white"
                  label="Añadir foto"
                  borderType="rectangle"
                  icon={IconImage}
                  iconPosition="top"
                  onClick={(event) => openChooserFinishTicket()}
                />
                {/* lista de imagenes preview */}
                {jsxImgsFinishTicket}
              </div>
            </>
          }
          buttons={[
            <ButtonV2
              key={"buttonCancel"}
              className="buttonCancel"
              type="white"
              label={"Cancelar"}
              borderType="oval"
              onClick={(event) => {
                setShowModalFinishTicket(false);
                setInputCommentFinish("");
                setImgsFinishTicket([]);
              }}
            />,
            <ButtonV2
              key={"buttonFinish"}
              className="buttonFinish"
              type="pink"
              label="Finalizar"
              borderType="oval"
              disabled={disabledFinishButton}
              onClick={(event) => {
                setShowModalFinishTicket(false);
                setAction(actions.CLICK_FINISH);
              }}
            />,
          ]}
        />
      )}

      {/* modal delete */}
      {showModalDeleteTicket && (
        <Modal
          className={"modalDelete"}
          show={showModalDeleteTicket}
          title={"Eliminar ticket"}
          customComponent={
            <textarea
              className="inputCommentDelete"
              placeholder="Cual es la razon para eliminar este ticket?"
              rows={3}
              value={inputCommentDelete}
              onChange={(event) => {
                setInputCommentDelete(event.target.value);
              }}
            />
          }
          buttons={[
            <ButtonV2
              key={"buttonCancel"}
              className="buttonCancel"
              type="white"
              label={"Cancelar"}
              borderType="oval"
              onClick={(event) => {
                setShowModalDeleteTicket(false);
                setInputCommentDelete("");
              }}
            />,
            <ButtonV2
              key={"buttonDelete"}
              className="buttonDelete"
              type="pink"
              label="Eliminar"
              borderType="oval"
              disabled={isStringEmpty(inputCommentDelete)}
              onClick={(event) => {
                setShowModalDeleteTicket(false);
                setAction(actions.CLICK_DELETE);
              }}
            />,
          ]}
        />
      )}

      {/* modal advertencia */}
      {modalWarning.show && (
        <Modal
          className={"modalWarning"}
          show={modalWarning.show}
          title={modalWarning.title}
          message={modalWarning.message}
          buttons={renderModalButtons()}
        />
      )}
    </Container>
  );
}
