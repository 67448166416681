import { useState } from "react";
import { Container } from "./styles";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { Loading } from "../../components/Loading";
import iconPass from "../../assets/images/IconPass.svg";
import iconCorreo from "../../assets/images/IconCorreo.svg";
import { faEye as IconShowPassword } from "@fortawesome/free-solid-svg-icons";
import { fetchGET } from "../../utils/handle_http";
import { isStringEmpty } from "../../utils/validations";
import { ButtonV2 } from "contentoh-components-library";

export default function ModalSignIn(props) {
  const [loading, setLoading] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [emptyPassword, setEmptyPassword] = useState(false);
  const [showErrors, setShowErrors] = useState(true);
  const [signInError, setSignInError] = useState("");
  const history = useHistory();

  const closeEye = (e) => {
    const input = document.getElementById("passwordInput");
    const eye = document.getElementById("Eye");
    if (input.type === "password") {
      input.type = "text";
      eye.style.opacity = 0.8;
    } else {
      input.type = "password";
      eye.style.opacity = 0.2;
    }
  };

  const validate = async (e) => {
    setSignInError("");
    setShowErrors(true);
    e.preventDefault();
    let valid = true;
    const email = document.querySelector("#emailInput").value.trim();
    const password = document.querySelector("#passwordInput").value;
    !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email
    )
      ? setInvalidEmail(true)
      : setInvalidEmail(false);
    if (email === "") {
      valid = false;
      setEmptyEmail(true);
    } else {
      setEmptyEmail(false);
    }
    if (password === "") {
      valid = false;
      setEmptyPassword(true);
    } else {
      setEmptyPassword(false);
    }
    if (valid) {
      try {
        setLoading(true);
        const session = await Auth.signIn(email, password);
        //console.log("response Auth:", session);
        const paramsHeaders = {
          Authorization: session.signInUserSession.idToken.jwtToken,
        };
        const response = await fetchGET(
          process.env.REACT_APP_USER_ENDPOINT,
          {},
          paramsHeaders
        );
        //console.log("response:", response);
        // cuando hay error al obtener la data del user
        if (!response.body) {
          setLoading(false);
          setSignInError("Error");
          return;
        }
        // success
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
        sessionStorage.setItem(
          "jwt",
          session.signInUserSession.idToken.jwtToken
        );
        const user = response.body.data;
        //console.log("user:", user);
        user.src = `https://${process.env.REACT_APP_IMAGES_PROFILE_BUCKET}.s3.amazonaws.com/id-${user.userId}/${user.userId}.png`;
        let userName = isStringEmpty(user?.name) ? "" : user.name;
        userName += isStringEmpty(user?.lastName) ? "" : " " + user.lastName;
        if (isStringEmpty(userName)) user.name = "Nombre no definido";
        else user.name = userName;
        sessionStorage.setItem("user", JSON.stringify(user));
        props.onClickCancel && props.onClickCancel();
        history.replace({
          pathname: "/Tickets",
          state: { Auth: true },
        });
      } catch (error) {
        console.log("error code:", error.code);
        setLoading(false);
        if (error.code === "NotAuthorizedException") {
          setSignInError("NotAuthorizedException");
        } else if (error.code === "UserNotConfirmedException") {
          sessionStorage.setItem("email", JSON.stringify(email));
        } else {
          setSignInError("Error");
        }
      }
    }
  };

  return (
    <Container
      className={"modalSignIn" + (loading ? " modalLoading" : "")}
      show={props.show === true}
      title={loading ? undefined : "Iniciar Sesión"}
      customComponent={
        loading ? (
          <Loading />
        ) : (
          <>
            <div className="email">
              <img src={iconCorreo} alt="email" className="icon"></img>
              <input
                id="emailInput"
                type="text"
                placeholder="Correo electrónico"
                onKeyDown={(e) => {
                  switch (e.key) {
                    case "Enter":
                      document.querySelector("#passwordInput").focus();
                      break;
                    default:
                      break;
                  }
                }}
              />
            </div>

            {showErrors && emptyEmail && <label>Ingrese su correo</label>}
            {invalidEmail && !emptyEmail && (
              <label>Ingrese un correo válido</label>
            )}

            <div className="password">
              <img src={iconPass} alt="pass" className="icon"></img>
              <input
                id="passwordInput"
                type="password"
                placeholder="Contraseña"
                onKeyDown={(e) => {
                  switch (e.key) {
                    case "Enter":
                      validate(e);
                      break;
                    default:
                      break;
                  }
                }}
              />
              <ButtonV2
                id="Eye"
                className="buttonShowPass"
                type="black"
                transparent
                icon={IconShowPassword}
                size={18}
                onClick={(event) => {
                  closeEye(event);
                }}
              />
            </div>

            {showErrors && emptyPassword && (
              <label>Ingrese su contraseña</label>
            )}
            {showErrors && signInError === "NotAuthorizedException" && (
              <label>Correo o contraseña incorrectos</label>
            )}
            {showErrors && signInError === "Error" && (
              <label>Ha habido un problema al iniciar sesión</label>
            )}

            {/* <a>Recuperar contraseña</a> */}
          </>
        )
      }
      buttons={[
        // <ButtonV2
        //   key={"modal-buttonCancelar"}
        //   className="modal-buttonCancelar"
        //   type="white"
        //   label="Cancelar"
        //   borderType="oval"
        //   onClick={(event) => {
        //     props.onClickCancel && props.onClickCancel(event);
        //   }}
        // />,
        <ButtonV2
          key={"modal-buttonSignIn"}
          className="modal-buttonSignIn"
          type="pink"
          label="Iniciar Sesión"
          borderType="oval"
          onClick={(event) => {
            validate(event);
          }}
        />,
      ]}
    />
  );
}
