import { Container } from "./styles";
import IconSearch from "../../assets/images/IconSearch.svg";
import { isStringEmpty } from "../../utils/validations";
import { useState } from "react";

export const Searcher = (props) => {
  const [focus, setFocus] = useState(false);

  return (
    <Container
      className={isStringEmpty(props.className) ? "" : props.className}
    >
      <img src={IconSearch} alt="search icon" />
      <input
        type="text"
        placeholder={props.placeHolder}
        value={
          focus
            ? isStringEmpty(props.value)
              ? ""
              : props.value
            : isStringEmpty(props.lastSearch)
            ? ""
            : props.lastSearch
        }
        onChange={(event) =>
          props.onChange && props.onChange(event.target.value)
        }
        onKeyDown={(e) => {
          switch (e.key) {
            case "Enter":
              props.onClickEnter();
              break;
          }
        }}
        onBlur={(event) => {
          setFocus(false);
          props.onExitFocus && props.onExitFocus();
        }}
        onFocus={(event) => {
          setFocus(true);
        }}
      />
    </Container>
  );
};
