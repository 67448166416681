import {
  Tooltip,
  ButtonV2,
  CustomIcon,
  ImageTooltip,
  Image,
} from "contentoh-components-library";
import { getCustomDate } from "../../../utils/dates";
import {
  isArrayEmpty,
  isObject,
  isStringEmpty,
  isValidNaturalNumber,
} from "../../../utils/validations";
import { Container } from "./styles";
import {
  faBell as IconSubscribed,
  faUser as IconUser,
} from "@fortawesome/free-solid-svg-icons";
import { getImageURL } from "../../../utils/files";
import { useState } from "react";
import OptionMenu from "../../OptionMenu";

export default function SubscriptionCard(props) {
  const {
    isSelected, // (boolean)  => tarjeta seleccionada?
    className,
    id, // ID del contenido de la tarjeta (ticketID | projectID)
    title /* {
			label: string	=> titulo de la tarjeta
			tooltipTitle: string	=> descripcion corta de la referencia del title
		} */,
    subscription /* {
      id: (number) ID de la subscripcion
      type: "TICKET" | "RELEASE"
      typeId: (number) ID del tipo de subscripcion
      onClick: ({
        action: "ADD" | "DELETE" | "ERROR"
        id: ID de la subscripcion (cuando action es "ADD" | "DELETE")
        typeId: ID del ticket o proyecto (cuando action es "ADD" | "DELETE") 
        message: (cuando action es "ERROR")
        errorDetail: (cuando action es "ERROR")
      }) => {}
    } */,
    dates /* {
			creationDate: string,
			startDate: string,
			updateDate: string,
			endDate: string,
			visibleDate: string	=> "creationDate" | "startDate" | "updateDate" | "endDate"
		} */,
    description, // (string) descripcion de la tarjeta
    statusLegend /* {
			type: string,	=> "NEW" | "IN_PROCESS" | "COMPLETED"
			label: string,	=> texto visible
			tooltipLabel: string,	=> texto en tooltip
		} */,
    urgencyLegend /* {
			type: string,	=> "LOW" | "MEDIUM" | "HIGH"
			label: string,	=> texto visible
			tooltipLabel: string,	=> texto en tooltip
		} */,
    companyLegend /* {
			name: string	=> nombre de la compañia
			type:	"PROVIDER" | "RETAILER" | "FACTORY"
			ownerUser: {id , name}	=> user propietario de la tarjeta
			createdByUser: {id , name}	=> user que creo la tarjeta
		} */,
    legends /* [
      {
			  label: string => texto visible en la etiqueta
			  tooltipTitle:	string  => titulo del tooltip
			  tooltipLabel: string  => info del tooltip
		  } ,
      ...
    ] */,
    assignedUser /* {
      id: number , 
      name: string  => user responsable de darle solucion a la tarjeta,
      tooltipTitle: string   => titulo del tooltip 
    } */,
    onClickCard, // (id , redirectURL) => {}
    onClickSubscribeButton, // (id , action) => {}	<-> action => "ADD" | "DELETE"
  } = props;

  const renderLabelDate = () => {
    // ver si la fecha principal existe
    let visibleDate = undefined;
    switch (dates?.visibleDate) {
      case "startDate":
        visibleDate = isStringEmpty(getCustomDate(dates?.startDate))
          ? undefined
          : "start";
        break;
      case "updateDate":
        visibleDate = isStringEmpty(getCustomDate(dates?.updateDate))
          ? undefined
          : "update";
        break;
      case "endDate":
        visibleDate = isStringEmpty(getCustomDate(dates?.endDate))
          ? undefined
          : "end";
        break;
      case "estimatedDeliveryDate":
        visibleDate = isStringEmpty(getCustomDate(dates?.estimatedDeliveryDate))
          ? undefined
          : "estimatedDelivery";
        break;
      default:
        visibleDate = isStringEmpty(getCustomDate(dates?.creationDate))
          ? undefined
          : "creation";
    }
    if (isStringEmpty(visibleDate)) return null;
    // obtener el formato de las fechas
    let startDate = getCustomDate(dates.startDate);
    let updateDate = getCustomDate(dates.updateDate);
    let endDate = getCustomDate(dates.endDate);
    let creationDate = getCustomDate(dates.creationDate);
    let estimatedDeliveryDate = getCustomDate(dates.estimatedDeliveryDate);
    // componente fecha tooltip
    const renderDateTooltip = (date, title, isVisibleDate) => {
      return isStringEmpty(date) ? null : (
        <div className="containerDate">
          <label className="label-title">{title}</label>
          <label
            className={"label-date" + (isVisibleDate ? " visibleDate" : "")}
          >
            {date}
          </label>
        </div>
      );
    };
    // generar el JSX
    return (
      <Tooltip
        componentTooltip={
          <>
            {/* estimated delivery date */}
            {renderDateTooltip(
              estimatedDeliveryDate,
              "Fecha estimada de entrega",
              visibleDate === "estimatedDelivery"
            )}

            {/* creation date */}
            {renderDateTooltip(
              creationDate,
              "Fecha de creación",
              visibleDate === "creation"
            )}

            {/* start date */}
            {renderDateTooltip(
              startDate,
              "Fecha de inicio",
              visibleDate === "start"
            )}

            {/* update date */}
            {renderDateTooltip(
              updateDate,
              "Ultima actualización",
              visibleDate === "update"
            )}

            {/* end date */}
            {renderDateTooltip(
              endDate,
              "Fecha de completado",
              visibleDate === "end"
            )}
          </>
        }
        className="label-date"
        classNameTooltip="tooltip-labelDate"
        position={"rightCenter"}
        addArrow={false}
        transitionType={"zoom"}
        followCursor={false}
      >
        {visibleDate === "start"
          ? startDate
          : visibleDate === "update"
          ? updateDate
          : visibleDate === "end"
          ? endDate
          : visibleDate === "estimatedDelivery"
          ? estimatedDeliveryDate
          : creationDate}
      </Tooltip>
    );
  };

  const renderStatusLegend = () => {
    if (!["NEW", "IN_PROCESS", "COMPLETED"].includes(statusLegend?.type))
      return null;

    const labelStatus = !isStringEmpty(statusLegend?.label)
      ? statusLegend.label
      : statusLegend.type === "NEW"
      ? "Nuevo"
      : statusLegend.type === "COMPLETED"
      ? "Cerrado"
      : "En proceso";
    const className =
      "itemLegend status" +
      (statusLegend.type === "NEW"
        ? "New"
        : statusLegend.type === "COMPLETED"
        ? "Completed"
        : "InProcess");
    return isStringEmpty(statusLegend?.tooltipLabel) ? (
      <label className={className}>{labelStatus}</label>
    ) : (
      <Tooltip
        componentTooltip={statusLegend.tooltipLabel}
        className={className}
        classNameTooltip="tooltip-legendStatus"
        position={"bottomCenter"}
        addArrow={false}
        transitionType={"zoom"}
        followCursor={false}
      >
        {labelStatus}
      </Tooltip>
    );
  };

  const renderUrgencyLegend = () => {
    if (!["LOW", "MEDIUM", "HIGH"].includes(urgencyLegend?.type)) return null;

    const labelUrgency = !isStringEmpty(urgencyLegend?.label)
      ? urgencyLegend.label
      : urgencyLegend.type === "LOW"
      ? "Baja"
      : urgencyLegend.type === "MEDIUM"
      ? "Media"
      : "Alta";
    const className =
      "itemLegend urgency" +
      (urgencyLegend.type === "LOW"
        ? "Low"
        : urgencyLegend.type === "MEDIUM"
        ? "Medium"
        : "High");
    return isStringEmpty(urgencyLegend?.tooltipLabel) ? (
      <label className={className}>{labelUrgency}</label>
    ) : (
      <Tooltip
        componentTooltip={urgencyLegend.tooltipLabel}
        className={className}
        classNameTooltip="tooltip-legendUrgency"
        position={"bottomCenter"}
        addArrow={false}
        transitionType={"zoom"}
        followCursor={false}
      >
        {labelUrgency}
      </Tooltip>
    );
  };

  // funcion para renderizar user en tooltip
  const getUserTooltip = (user) => {
    return (
      <div className="label-user">
        {/* imagen del user */}
        {/*<Image
          className="imgUser"
          sizeLoading={11}
          borderType={"circle"}
          src={getImageURL(
            process.env.REACT_APP_IMAGES_PROFILE_BUCKET,
            `id-${user.id}/${user.id}.png`,
            20,
            20
          )}
          componentDefault={
            <CustomIcon
              className="icon-imgUser"
              type={"whiteS2"}
              transparent
              size={10}
              borderType={"circle"}
              icon={IconUser}
            />
          }
        />
        */}
        {/* nombre del user */}
        <label className="label-info">
          {isStringEmpty(user.name) ? "nombre no definido" : user.name}
        </label>
      </div>
    );
  };

  const renderCompanyNameLegend = () => {
    if (isStringEmpty(companyLegend?.name)) return null;
    let companyType = undefined;
    switch (companyLegend.type) {
      case "PROVIDER":
        companyType = "Proveedor";
        break;
      case "RETAILER":
        companyType = "Cadena";
        break;
      case "FACTORY":
        companyType = "Content-oh!";
        break;
    }
    let ownerUser = false; // solicitado por
    let createdByUser = false; // creado por
    if (isValidNaturalNumber(companyLegend.ownerUser?.id)) {
      createdByUser = true;
      if (isValidNaturalNumber(companyLegend.createdByUser?.id)) {
        if (companyLegend.ownerUser.id !== companyLegend.createdByUser.id) {
          ownerUser = true;
        }
      }
    }

    // renderizado de los users
    let createdByUserJSX = undefined;
    let ownerUserJSX = undefined;
    if (ownerUser && createdByUser) {
      ownerUserJSX = getUserTooltip(companyLegend.ownerUser);
      createdByUserJSX = getUserTooltip(companyLegend.createdByUser);
    } else if (createdByUser) {
      createdByUserJSX = getUserTooltip(companyLegend.ownerUser);
    }

    return (
      <Tooltip
        componentTooltip={
          <>
            {/* tipo de compañia */}
            <div className="containerItem">
              <label className="label-title">Tipo de compañia</label>
              <label className="label-info">
                {companyType ? companyType : "No identificado"}
              </label>
            </div>
            {/* user que lo creó */}
            {createdByUserJSX && (
              <div className="containerItem">
                <label className="label-title">Creado por</label>
                {createdByUserJSX}
              </div>
            )}
            {/* user que lo solicitó */}
            {ownerUserJSX && (
              <div className="containerItem">
                <label className="label-title">Solicitado por</label>
                {ownerUserJSX}
              </div>
            )}
          </>
        }
        className={"itemLegend companyName"}
        classNameTooltip="tooltip-legendCompanyName"
        position={"bottomCenter"}
        addArrow={false}
        transitionType={"zoom"}
        followCursor={false}
      >
        {companyLegend.name}
      </Tooltip>
    );
  };

  const renderLegends = () => {
    if (isArrayEmpty(legends)) return null;
    const legendListJSX = [];
    legends.forEach((legend, index) => {
      if (!isStringEmpty(legend.label)) {
        let labelTitle = null;
        if (!isStringEmpty(legend.tooltipTitle)) {
          labelTitle = (
            <label className="label-title">{legend.tooltipTitle}</label>
          );
        }
        legendListJSX.push(
          isStringEmpty(legend.tooltipLabel) ? (
            <label className="itemLegend genericLegend" key={"legend-" + index}>
              {legend.label}
            </label>
          ) : (
            <Tooltip
              key={"legend-" + index}
              componentTooltip={
                <>
                  {labelTitle}
                  <label className="label-info">{legend.tooltipLabel}</label>
                </>
              }
              className="itemLegend genericLegend"
              classNameTooltip="tooltip-genericLegends"
              position={"bottomCenter"}
              addArrow={false}
              transitionType={"zoom"}
              followCursor={false}
            >
              {legend.label}
            </Tooltip>
          )
        );
      }
    });
    return legendListJSX;
  };

  return (
    <Container
      className={"SubscriptionCard" + (className ? " " + className : "")}
      isSelected={isSelected}
      onClick={(event) => {
        onClickCard && onClickCard(id);
      }}
    >
      <div className="containerHeader">
        {/* container title y date */}
        <div className="container-info">
          {/* label title */}
          <Tooltip
            componentTooltip={
              <>
                <label className="label-title">
                  {!isStringEmpty(title?.tooltipTitle)
                    ? title.tooltipTitle
                    : "Título"}
                </label>
                <label className="label-info">
                  {!isStringEmpty(title?.label)
                    ? title.label
                    : "Título no definido"}
                </label>
              </>
            }
            className="label-title"
            classNameTooltip="tooltip-labelTitle"
            position={"topCenter"}
            addArrow={false}
            transitionType={"zoom"}
            followCursor={false}
          >
            {!isStringEmpty(title?.label) ? title.label : "Título no definido"}
          </Tooltip>

          {/* label date */}
          {renderLabelDate()}
        </div>
        {/* subscribe button */}
        {isObject(subscription) && (
          <>
            <OptionMenu subscribeButton={subscription} />
          </>
        )}
      </div>

      {/* description */}
      {!isStringEmpty(description) && (
        <label className="label-descripcion">{description}</label>
      )}

      {/* container de leyendas */}
      <div className="containerLegends">
        {/* status legend */}
        {renderStatusLegend()}

        {/* urgency legend */}
        {renderUrgencyLegend()}

        {/* companyName legend */}
        {renderCompanyNameLegend()}

        {/* legends extras */}
        {renderLegends()}

        {/* user asignado legend */}
        {isValidNaturalNumber(assignedUser?.id) && (
          <ImageTooltip
            className={"imgAssignedUser"}
            sizeLoading={11}
            colorLoading={undefined}
            position={"bottomCenter"}
            src={getImageURL(
              process.env.REACT_APP_IMAGES_PROFILE_BUCKET,
              `id-${assignedUser.id}/${assignedUser.id}.png`,
              20,
              20
            )}
            componentDefault={
              <CustomIcon
                className="icon-imgAssignedUser"
                type={"whiteS2"}
                size={10}
                borderType={"circle"}
                icon={IconUser}
              />
            }
            classNameTooltip={"tooltip-imgAssignedUser"}
            componentTooltip={
              <>
                <label className="label-title">
                  {!isStringEmpty(assignedUser?.tooltipTitle)
                    ? assignedUser.tooltipTitle
                    : "Responsable"}
                </label>
                <label className="label-info">
                  {isStringEmpty(assignedUser.name)
                    ? "Nombre no definido"
                    : assignedUser.name}
                </label>
              </>
            }
          />
        )}
      </div>
    </Container>
  );
}
