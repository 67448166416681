import { Container } from "./styles";
import { NotificationItem } from "../NotificationItem";
import { faArrowRotateRight as IconLoadMore } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { useRouteMatch } from "react-router-dom";

export const NotificationList = ({
  arrayNotifications = [],
  setArrayNotifications,
  onClickNotification,
}) => {
  const inPageTickets = useRouteMatch({
    path: ["/Tickets/:id", "/Tickets"],
    exact: true,
  });
  const inPagePlanning = useRouteMatch({
    path: ["/Planning/:id/Tasks", "/Planning/:id", "/Planning"],
    exact: true,
  });

  return (
    <Container>
      <h2>Notificaciones</h2>
      {arrayNotifications.length > 0 ? (
        <div className="notifications-container">
          {arrayNotifications?.map((item, index) => (
            <NotificationItem
              key={index}
              notificationId={item.notificationId}
              notificationTypeId={item.notificationTypeId}
              title={item.notificationMessage}
              date={item.creationDate}
              read={item.readDate}
              notificationType={item.notificationType}
              setArrayNotifications={setArrayNotifications}
              arrayNotifications={arrayNotifications}
              onClickNotification={onClickNotification}
              status={item.status}
              page={
                inPageTickets
                  ? "Tickets"
                  : inPagePlanning
                  ? "Planning"
                  : "Projects"
              }
            />
          ))}
          {arrayNotifications.length < 0 && (
            <button className="load-notifications">
              <Icon className="load-icon" icon={IconLoadMore} />
              <p>Ver más notificaciones</p>
            </button>
          )}
        </div>
      ) : (
        <div className="no-notification">
          <p className="title-notification">
            Estás al día! No tienes ninguna notificación perdida
          </p>
        </div>
      )}
    </Container>
  );
};
