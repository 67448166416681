export const GlobalColors = {
  s1: "#FAFAFA",
  s2: "#F0EEF2",
  s3: "#D4D1D7",
  s4: "#817393",
  s5: "#503D66",
  original_magenta: "#B12D84",
  secondary_magenta: "#BA0070",
  rejected_status: "#D74DED",
  magenta_s2: "#E33AA9",
  original_purpura: "#603888",
  in_progress: "#ED9A4D",
  deep_gray: "#281F33",
  reception: "#E57432",
  finished: "#18A0FB",
  exported: "#71DE56",
  white: "#FFFFFF",
};

export const FontFamily = {
  AvenirNext: "Avenir Next",
  Lato: "Lato",
  Raleway: "Raleway",
  Raleway_500: "Raleway-500",
  Raleway_600: "Raleway-600",
  Raleway_700: "Raleway-700",
  Raleway_800: "Raleway-800",
  Raleway_900: "Raleway-900",
};

export const GlobalStatus = [
  "-",
  "As",
  "Pr",
  "Rr",
  "P",
  "AA",
  "AP",
  "Ex",
  "DDI",
  "Dat",
  "Dsc",
  "Imgs",
  "GLD",
  "TAB",
  "Pt",
];
