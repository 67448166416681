/*====================================================================
            UTILERIAS PARA EL MANEJO DE FECHAS
======================================================================*/

import moment from "moment";
import { isStringEmpty } from "./validations";

/*------------------------
nombres de los dias de la semana
--------------------------*/
const weekdayName = {
  1: { short: "Dom", full: "Domingo" },
  2: { short: "Lun", full: "Lunes" },
  3: { short: "Mar", full: "Martes" },
  4: { short: "Mié", full: "Miércoles" },
  5: { short: "Jue", full: "Jueves" },
  6: { short: "Vie", full: "Viernes" },
  7: { short: "Sab", full: "Sabado" },
};
/*------------------------
nombres de los meses
--------------------------*/
const monthName = {
  1: { short: "Ene", full: "Enero" },
  2: { short: "Feb", full: "Febrero" },
  3: { short: "Mar", full: "Marzo" },
  4: { short: "Abr", full: "Abril" },
  5: { short: "May", full: "Mayo" },
  6: { short: "Jun", full: "Junio" },
  7: { short: "Jul", full: "Julio" },
  8: { short: "Ago", full: "Agosto" },
  9: { short: "Sep", full: "Septiembre" },
  10: { short: "Oct", full: "Octubre" },
  11: { short: "Nov", full: "Noviembre" },
  12: { short: "Dic", full: "Diciembre" },
};

/*------------------------
Obtener una fecha a partir de 
un objeto Date() en formato: 
'DDD dd MMM aaaa'
ejemplo: 'Ene 12 Jul 2022'
--------------------------*/
export const getFullDate = (date = new Date()) => {
  if (isNaN(date)) return undefined;
  let dateString = `${weekdayName[date.getDay() + 1].short} `;
  dateString += date.getDate() + " ";
  dateString += `${monthName[date.getMonth() + 1].short} `;
  dateString += date.getFullYear();
  return dateString;
};

/*------------------------
Obtener una fecha (CON AJUSTE DE HORA)
a partir de un objeto Date() en formato: 
'dd de MMMM del aaaa'
ejemplo: '12 de octubre del 2022'
=> si es la fecha del dia actual:
devolvera 'Hoy'
=> si es la fecha del dia de ayer:
devolvera 'Ayer'
=> si es fecha del mismo año:
devolvera '12 de octubre'
--------------------------*/
export const getCustomDate = (dateTimeString) => {
  if (isStringEmpty(dateTimeString)) return "";
  const date = new Date(dateTimeString);
  if (isNaN(date)) return "";
  let dateString = date.getDate() + " de ";
  dateString += `${monthName[date.getMonth() + 1].full}`;
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  // fecha de Hoy
  if (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  ) {
    return "Hoy";
  }
  // fecha de Ayer
  if (
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear()
  ) {
    return "Ayer";
  }
  // fechas del mismo año
  if (date.getFullYear() === today.getFullYear()) return dateString;
  // fechas de diferente año
  return dateString + " del " + date.getFullYear();
};

/*------------------------
Obtener una fecha (SIN AJUSTE DE HORA)
a partir de un string timestamp en formato: 
'dd de MMMM del aaaa'
ejemplo: '12 de octubre del 2022'
=> si es la fecha del dia actual:
devolvera 'Hoy'
=> si es la fecha del dia de ayer:
devolvera 'Ayer'
=> si es fecha del mismo año:
devolvera '12 de octubre'
--------------------------*/
export const getUtcCustomDate = (timestampString) => {
  if (isStringEmpty(timestampString)) return "";
  const date = new Date(timestampString);
  if (isNaN(date)) return "";
  let dateString = date.getUTCDate() + " de ";
  dateString += `${monthName[date.getUTCMonth() + 1].full}`;
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getUTCDate() - 1);

  // fecha de Hoy
  if (
    date.getUTCDate() === today.getUTCDate() &&
    date.getUTCMonth() === today.getUTCMonth() &&
    date.getUTCFullYear() === today.getUTCFullYear()
  ) {
    return "Hoy";
  }
  // fecha de Ayer
  if (
    date.getUTCDate() === yesterday.getUTCDate() &&
    date.getUTCMonth() === yesterday.getUTCMonth() &&
    date.getUTCFullYear() === yesterday.getUTCFullYear()
  ) {
    return "Ayer";
  }
  // fechas del mismo año
  if (date.getUTCFullYear() === today.getUTCFullYear()) return dateString;
  // fechas de diferente año
  return dateString + " del " + date.getUTCFullYear();
};

/*------------------------
Obtener una fecha (SIN AJUSTE DE HORA)
a partir de un string timestamp en formato: 
'dd-MMM-aaaa' ejemplo: '12-oct-2022'
=> si es la fecha del dia actual:
devolvera 'Hoy'
=> si es la fecha del dia de ayer:
devolvera 'Ayer'
=> si es fecha del mismo año:
devolvera '12 oct'
--------------------------*/
export const getUtcSmallCustomDate = (timestampString) => {
  if (isStringEmpty(timestampString)) return "";
  const date = new Date(timestampString);
  if (isNaN(date)) return "";
  const day = date.getUTCDate();
  const month = `${monthName[date.getUTCMonth() + 1].short}`;
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getUTCDate() - 1);

  // fecha de Hoy
  if (
    date.getUTCDate() === today.getUTCDate() &&
    date.getUTCMonth() === today.getUTCMonth() &&
    date.getUTCFullYear() === today.getUTCFullYear()
  ) {
    return "Hoy";
  }
  // fecha de Ayer
  if (
    date.getUTCDate() === yesterday.getUTCDate() &&
    date.getUTCMonth() === yesterday.getUTCMonth() &&
    date.getUTCFullYear() === yesterday.getUTCFullYear()
  ) {
    return "Ayer";
  }
  // fechas del mismo año
  if (date.getUTCFullYear() === today.getUTCFullYear()) {
    return `${day} ${month.toLowerCase()}`;
  }
  // fechas de diferente año
  return `${day}/${month.toLowerCase()}/${date.getUTCFullYear()}`;
};

/*------------------------
Obtener una fecha (CON AJUSTE DE HORA)
a partir de un string timestamp en formato: 
'dd-MMM-aaaa' ejemplo: '12-oct-2022'
=> si es la fecha del dia actual:
devolvera 'Hoy'
=> si es la fecha del dia de ayer:
devolvera 'Ayer'
=> si es fecha del mismo año:
devolvera '12 oct'
--------------------------*/
export const getSmallCustomDate = (timestampString) => {
  if (isStringEmpty(timestampString)) return "";
  const date = new Date(timestampString);
  if (isNaN(date)) return "";
  const day = date.getDate();
  const month = `${monthName[date.getMonth() + 1].short}`;
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  // fecha de Hoy
  if (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  ) {
    return "Hoy";
  }
  // fecha de Ayer
  if (
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear()
  ) {
    return "Ayer";
  }
  // fechas del mismo año
  if (date.getFullYear() === today.getFullYear()) {
    return `${day} ${month.toLowerCase()}`;
  }
  // fechas de diferente año
  return `${day}/${month.toLowerCase()}/${date.getFullYear()}`;
};

/*------------------------
Obtener una hora a partir de 
una fecha string (current_timestamp)
en formato: 'hh:mm PM/AM'
ejemplo: '12:34 PM'
--------------------------*/
export const getTime = (time = "") => {
  if ([undefined, null, ""].includes(time))
    return moment(new Date()).format("LT");
  else return moment(time).format("LT");
};

export const formatDate = (date) => {
  const newDate = new Date(date);
  const year = newDate.getFullYear();
  const month = newDate.getMonth() + 1;
  const day = newDate.getDate();
  return `${year}/${month}/${day}`;
};

export const getDatesRange = (range) => {
  //console.log(range, "rangeXD");
  const today = new Date();
  switch (range) {
    case "hoy":
      return formatDate(today);
    case "ayer":
      return `${formatDate(
        new Date(today.setDate(today.getDate() - 1))
      )}|${formatDate(new Date())}`;
    case "esta_semana":
      return `${formatDate(
        new Date(today.setDate(today.getDate() - today.getDay()))
      )}|${formatDate(new Date())}`;
    case "la_semana_pasada":
      return `${formatDate(
        new Date(today.setDate(today.getDate() - today.getDay() - 7))
      )}|${formatDate(new Date())}`;
    case "este_mes":
      return `${formatDate(
        new Date(`${today.getFullYear()}-${today.getMonth() + 1}-01`)
      )}|${formatDate(new Date())}`;
    case "el_mes_pasado":
      return `${formatDate(
        new Date(`${today.getFullYear()}-${today.getMonth()}-01`)
      )}|${formatDate(
        new Date(`${today.getFullYear()}-${today.getMonth() + 1}-01`)
      )}`;

    default:
      return `${formatDate("1900-01-01")}|${formatDate(today)}`;
  }
};
